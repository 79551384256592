import {
  Input,
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  Theme,
  useTheme,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {
  SelectChangeEvent,
  SelectInputProps,
} from '@mui/material/Select/SelectInput';
import React from 'react';
import { MenuItem } from './MenuItem';
import { INPUT_WIDTH } from './utils';

interface Option {
  value: string;
  label: string;
}

export interface MultiSelectProps
  extends Omit<MUISelectProps<string[]>, 'onChange' | 'value'> {
  onChange: (value: string[], event?: SelectChangeEvent<string[]>) => void;
  value: string[];
  options: Option[];
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiSelect: React.FC<MultiSelectProps> = ({
  label,
  onChange,
  options,
  value,
  ...props
}) => {
  const theme = useTheme();
  const labelId = `${props.id}-label`;

  const handleChange: SelectInputProps<string[]>['onChange'] = (event) => {
    onChange(event.target.value as string[], event);
  };

  return (
    <FormControl
      sx={{
        maxWidth: INPUT_WIDTH,
        minWidth: INPUT_WIDTH,
      }}
    >
      <InputLabel id="{labelId}">{label}</InputLabel>
      <MUISelect
        {...props}
        labelId={labelId}
        multiple
        value={value}
        onChange={handleChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={getStyles(option.value, value, theme)}
          >
            {option.label}
          </MenuItem>
        ))}
      </MUISelect>
    </FormControl>
  );
};
