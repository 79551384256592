import { Rental } from '../../../../common/types/rental';

interface NumberOfRentalsPerGenderMap {
  [name: string]: number;
}

const getGenderLabel = (gender?: string) => {
  switch (gender) {
    case 'MALE':
      return 'Man';
    case 'FEMALE':
      return 'Kvinna';
    case 'PREFER_NOT_TO_SAY':
      return 'Föredrar att inte säga';
    case 'OTHER':
      return 'Annat';
    default:
      return 'Inte specificerat';
  }
};

export const generateNumberOfRentalsPerGenderMap = (
  rentals: Rental[]
): NumberOfRentalsPerGenderMap =>
  rentals.reduce<NumberOfRentalsPerGenderMap>((acc, rental) => {
    const gender = getGenderLabel(rental.rentBy.gender);
    if (acc[gender]) {
      acc[gender] = acc[gender] + 1;
    } else {
      acc[gender] = 1;
    }
    return acc;
  }, {});
