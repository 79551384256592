import * as Apollo from '@apollo/client';
import {
  GQL_OrganisationByIdQuery,
  GQL_OrganisationByIdQueryVariables,
  useOrganisationByIdQuery,
} from '../../../generated/graphql';
import { transformOrganisation } from '../../../gql/transformOrganisation';

export const useOrganisationById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_OrganisationByIdQuery,
    GQL_OrganisationByIdQueryVariables
  > &
    (
      | { variables: GQL_OrganisationByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useOrganisationByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.organisation?.organisationById
      ? transformOrganisation(data?.organisation?.organisationById)
      : undefined,
  };
};
