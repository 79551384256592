import * as Apollo from '@apollo/client';
import {
  GQL_AllReviewsQuery,
  GQL_AllReviewsQueryVariables,
  useAllFeedbacksQuery,
} from '../../../generated/graphql';
import { transformFeedback } from '../../../gql/transformFeedback';

export const useAllFeedbacks = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllReviewsQuery,
    GQL_AllReviewsQueryVariables
  >
) => {
  const { data, ...rest } = useAllFeedbacksQuery(baseOptions);
  return {
    ...rest,
    data: data?.feedback?.allFeedbacks.map(transformFeedback) ?? [],
  };
};
