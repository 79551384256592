import * as Apollo from '@apollo/client';
import {
  GQL_BoxByIdQuery,
  GQL_BoxByIdQueryVariables,
  useBoxByIdQuery,
} from '../../../generated/graphql';
import { transformBox } from '../../../gql/transformBox';

export const useBoxById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_BoxByIdQuery,
    GQL_BoxByIdQueryVariables
  > &
    (
      | { variables: GQL_BoxByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useBoxByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.box?.boxById ? transformBox(data?.box?.boxById) : undefined,
  };
};
