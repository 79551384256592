import { Grid } from '@mui/material';
import React from 'react';
import { Product } from '../../common/types/product';
import { Box } from '../../webui/Box';
import { CardContent } from '../../webui/CardContent';
import { Typography } from '../../webui/Typography';
import { DASHBOARD_DAYS_BACK } from './dashboardUtils';
import { ProductCard } from './ProductCard';

const NUMBER_OF_PRODUCTS = 8;

interface Props {
  productUsage: { [key: string]: { product: Product; count: number } };
}

export const MostCommonProducts: React.FC<Props> = ({ productUsage }) => {
  const mostCommonProducts = Object.values(productUsage)
    .sort((a, b) => b.count - a.count)
    .slice(0, NUMBER_OF_PRODUCTS);

  return (
    <Grid item xs={12}>
      <CardContent>
        <Typography variant="h5">{`Top ${NUMBER_OF_PRODUCTS} Most Used Products Last ${DASHBOARD_DAYS_BACK} Days`}</Typography>
        <Box mb={2} />
        <Grid container spacing={2}>
          {mostCommonProducts.map((product, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <ProductCard
                product={product.product}
                numberOfTimesUsed={product.count}
              />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Grid>
  );
};
