import * as L from 'leaflet';
import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { BoxLink } from '../../../common/router_links/BoxLink';
import { slottsskogenPosition } from '../../../common/utils/slottsskogenPosition';
import { GenericMap, ZOOM_MANY_MARKERS } from '../../location/map/GenericMap';
import { BoxMarker } from './types/boxMarker';

interface Props {
  boxMarkers: BoxMarker[];
}

const pinkActiveMarker = L.icon({
  iconUrl: '/markers/box-marker.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const blackGreyActiveMarker = L.icon({
  iconUrl: '/markers/box-marker-blackgrey.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const notActiveMarker = L.icon({
  iconUrl: '/markers/box-marker-grey.svg',
  iconAnchor: [20, 50],
});

const resolveBoxMarkerIcon = (marker: BoxMarker) =>
  marker.active
    ? marker.color === '#B40A7A'
      ? pinkActiveMarker
      : blackGreyActiveMarker
    : notActiveMarker;

export const BoxMap: React.FC<Props> = ({ boxMarkers }) => {
  return (
    <GenericMap
      initialPosition={
        boxMarkers.length > 0
          ? [boxMarkers[0].coordinates.lat, boxMarkers[0].coordinates.long]
          : slottsskogenPosition
      }
      zoom={ZOOM_MANY_MARKERS}
    >
      {boxMarkers.map((marker) => (
        <Marker
          icon={resolveBoxMarkerIcon(marker)}
          key={marker.id}
          position={[marker.coordinates.lat, marker.coordinates.long]}
        >
          <Popup>
            <BoxLink boxId={marker.id} linkText={marker.title} />
          </Popup>
        </Marker>
      ))}
    </GenericMap>
  );
};
