import * as Apollo from '@apollo/client';
import {
  GQL_AllPriceWeightsQuery,
  GQL_AllPriceWeightsQueryVariables,
  useAllPriceWeightsQuery,
} from '../../../generated/graphql';
import { transformPriceWeight } from '../../../gql/transformPriceWeight';

export const useAllPriceWeights = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllPriceWeightsQuery,
    GQL_AllPriceWeightsQueryVariables
  >
) => {
  const { data, ...rest } = useAllPriceWeightsQuery(baseOptions);
  return {
    ...rest,
    data: data?.payment?.allPriceWeights.map(transformPriceWeight) ?? [],
  };
};
