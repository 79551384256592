import { Rental } from '../../../../common/types/rental';

export interface RentalAgeData {
  label: string;
  count: number;
}

const getRenterAge = (
  rental: Rental,
  currentYear: number
): ADataKey | undefined => {
  if (rental.rentBy.birthYear) {
    const age = currentYear - Number(rental.rentBy.birthYear);

    if (age < 11) {
      return '0-10';
    }
    if (age < 21) {
      return '11-20';
    }
    if (age < 31) {
      return '21-30';
    }
    if (age < 41) {
      return '31-40';
    }
    if (age < 51) {
      return '41-50';
    }
    if (age < 61) {
      return '51-60';
    }
    if (age < 71) {
      return '61-70';
    }
    if (age < 81) {
      return '71-80';
    }
    if (age < 91) {
      return '81-90';
    }
    if (age > 90) {
      return '90+';
    }
  }
  return undefined;
};

type ADataKey =
  | '0-10'
  | '11-20'
  | '21-30'
  | '31-40'
  | '41-50'
  | '51-60'
  | '61-70'
  | '71-80'
  | '81-90'
  | '90+';

type AData = Record<ADataKey, number>;

export const generateRentalAgeData = (
  rentals: Rental[],
  currentYear: number = new Date().getFullYear()
): RentalAgeData[] => {
  const data: AData = {
    '0-10': 0,
    '11-20': 0,
    '21-30': 0,
    '31-40': 0,
    '41-50': 0,
    '51-60': 0,
    '61-70': 0,
    '71-80': 0,
    '81-90': 0,
    '90+': 0,
  };

  for (const rental of rentals) {
    const a = getRenterAge(rental, currentYear);
    if (a) {
      data[a] = data[a] + 1;
    }
  }

  return Object.entries(data).map(([label, count]) => ({
    label,
    count,
  }));
};
