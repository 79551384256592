import * as L from 'leaflet';
import { ActionLevelType } from './types/ActionLevelType';

export const resolveActionIcon = (action: ActionLevelType) => {
  switch (action) {
    case 'NONE':
      break;
    case 'HIGH':
      return high;
    case 'MIDDLE':
      return middle;
    case 'LOW':
      return low;
  }
  return none;
};

const high = L.icon({
  iconUrl: '/markers/action-marker-high.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const middle = L.icon({
  iconUrl: '/markers/action-marker-middle.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const low = L.icon({
  iconUrl: '/markers/action-marker-low.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const none = L.icon({
  iconUrl: '/markers/action-marker-none.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});
