import * as React from 'react';
import { Box } from '../../webui/Box';
import { Typography } from '../../webui/Typography';

export interface LocationTranslationProps {
  lng: 'sv' | 'en';
  text: string;
}

export const LocationTranslation: React.FC<LocationTranslationProps> = ({
  lng,
  text,
}) => (
  <Box mt={2} display={'flex'} alignItems={'flex-start'}>
    <img
      src={`/flags/flag-${lng}.svg`}
      alt={`flag-${lng}`}
      width={20}
      height={20}
    />
    <Box ml={1}>
      <Typography>{text}</Typography>
    </Box>
  </Box>
);
