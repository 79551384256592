import React, { useState } from 'react';
import { useAuthentication } from '../../common/auth/hooks/useAuthentication';
import { useNotification } from '../../common/notifications/useNotification';
import {
  GQL_ProductStatus,
  GQL_StopRentalResponseType,
  Maybe,
  useStopRentalMutation,
} from '../../generated/graphql';
import { ButtonWithLoading } from '../../webui/ButtonWithLoading';
import { ChargeAmountDialog } from './ChargeAmountDialog';

interface Props {
  rentalId: string;
}

const resolveErrorMessage = (
  type: Maybe<GQL_StopRentalResponseType> | undefined
) => {
  switch (type) {
    case GQL_StopRentalResponseType.RentalAlreadyStopped:
      return 'Rental is already stopped';
    case GQL_StopRentalResponseType.CompartmentIsNotRented:
      return 'Compartment is not rented';
    case GQL_StopRentalResponseType.NoPermissionToStopRental:
      return 'No permission to stop rental';
  }
  return 'Could not stop rental';
};

export const StopRentalButton: React.FC<Props> = ({ rentalId }) => {
  const { currentUser } = useAuthentication();
  const [open, setOpen] = useState(false);

  const { showNotification } = useNotification();

  const [
    stopRentalMutation,
    { loading: stopRentalLoading },
  ] = useStopRentalMutation();

  const stopRental = async (chargeAmount: number | null) => {
    try {
      const response = await stopRentalMutation({
        variables: {
          rentalInput: {
            chargeAmount:
              chargeAmount !== null
                ? {
                    amount: chargeAmount * 100,
                    currency: 'sek',
                  }
                : null,
            rentalId,
            productStatus: GQL_ProductStatus.Other,
            productStatusText: currentUser?.email
              ? `Stopped by ${currentUser.email}`
              : 'Stopped by admin',
          },
        },
      });
      const stopRentalData = response.data?.stopRental;
      if (stopRentalData?.type === 'Success') {
        showNotification({
          message: 'Rental stopped',
          severity: 'success',
        });
        console.log('Rental stopped', stopRentalData);
      } else {
        showNotification({
          message: resolveErrorMessage(stopRentalData?.type),
          severity: 'error',
        });
      }
    } catch (e) {
      showNotification({
        message: 'Could not stop rental.',
        severity: 'error',
      });
      console.log('---', e, '--- e', 'rental did not stop');
    }
  };

  const onStopRentalClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonWithLoading
        loading={stopRentalLoading}
        onClick={onStopRentalClick}
      >
        Stop rental
      </ButtonWithLoading>
      <ChargeAmountDialog
        open={open}
        onClose={handleClose}
        onChargeRental={stopRental}
      />
    </>
  );
};
