import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { RentalsTable } from '../rentals/RentalsTable';
import { EditProductDrawer } from './EditProductDrawer';
import { useProductById } from './gql/useProductById';
import { Product } from './Product';

interface Props {}

export const ProductPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: { rentals },
    loading: rentalsLoading,
    error: rentalsError,
  } = useAllRentals({
    variables: {
      filter: {
        productIds: [id],
      },
    },
  });
  const { data: product, loading, error } = useProductById({
    variables: { id },
  });
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!product) {
    return <div>Could not find product information.</div>;
  }

  return (
    <>
      <Container>
        <Paper>
          <Box
            p={2}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Box>
              <Product product={product} />
            </Box>
            <Box>
              <Button onClick={onOpen}>Edit</Button>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Box mt={2} />
      <RentalsTable
        rentals={rentals}
        loading={rentalsLoading}
        error={rentalsError}
      />
      <EditProductDrawer product={product} open={open} onClose={onClose} />
    </>
  );
};
