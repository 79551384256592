import { BoxWithoutRentals } from '../types/box';
import { Product } from '../types/product';
import { sortWithName } from './sort/comparators/nameComparator';

export const createProductsInBoxes = (
  products: Product[],
  boxes: BoxWithoutRentals[]
) => {
  const data: Record<string, BoxWithoutRentals[]> = {};

  for (const product of products) {
    data[product.name] = [];
  }

  for (const box of sortWithName(boxes)) {
    for (const compartment of box.compartments) {
      if (compartment.product) {
        if (data[compartment.product.name]) {
          if (!data[compartment.product.name].some((b) => b.id === box.id)) {
            data[compartment.product.name].push(box);
          }
        } else {
          data[compartment.product.name] = [box];
        }
      }
    }
  }

  return data;
};
