import { BoxWithoutRentals } from '../../../../common/types/box';
import { Rental } from '../../../../common/types/rental';
import { resolveBoxDisplayName } from '../../../../common/utils/resolveBoxDisplayName';
import { generateNumberOfRentalsPerBoxMap } from './generateNumberOfRentalsPerBoxMap';

export interface RentalsPerBoxPieData {
  box: string;
  count: number;
  share: number;
}

export const generateRentalsPerBoxPieData = (
  boxes: BoxWithoutRentals[],
  rentals: Rental[]
): RentalsPerBoxPieData[] => {
  return Object.entries(generateNumberOfRentalsPerBoxMap(rentals))
    .map(([key, value]) => {
      const box = boxes.find((b) => b.id === key);

      return {
        box: box ? resolveBoxDisplayName(box) : key,
        count: value,
        share: Math.round((value / rentals.length) * 100),
      };
    })
    .filter((value) => {
      return value.share !== 0;
    });
};
