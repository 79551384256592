export const colors = [
  // '#FFE4E6',
  // '#FAE8FF',
  // '#CFFAFE',
  // '#F1F5F9',
  // '#DBEAFE',
  // '#D1FAE5',
  // '#FEF9C3',
  // '#ECFCCB',
  // '#FFEDD5',
  '#FB7185',
  '#E879F9',
  '#22D3EE',
  '#94A3B8',
  '#60A5FA',
  '#34D399',
  '#FACC15',
  '#A3E635',
  '#FB923C',
  '#E11D48',
  '#C026D3',
  '#0891B2',
  '#475569',
  '#2563EB',
  '#059669',
  '#CA8A04',
  '#65A30D',
  '#EA580C',
  '#9F1239',
  '#86198F',
  '#155E75',
  '#1E293B',
  '#1E40AF',
  '#065F46',
  '#854D0E',
  '#3F6212',
  '#9A3412',
  '#881337',
  '#701A75',
  '#164E63',
  '#0F172A',
  '#1E3A8A',
  '#064E3B',
  '#713F12',
  '#365314',
  '#7C2D12',
];
