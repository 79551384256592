import { ProductStatus } from '../../../common/types/productStatus';
import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { AllRentalData } from '../types';

export interface BoxActionInfo {
  actionName: string;
  boxNumber: number;
  compartmentName: string;
  productStatusText: string | undefined;
  productName: string;
  rentalEnd: string;
  productStatus: ProductStatus;
}

const resolveProductStatusText = (rental: AllRentalData): string => {
  const texts: string[] = [];
  if (rental.productStatusText) {
    texts.push(rental.productStatusText);
  }
  if (rental.reviewFreetext) {
    texts.push(rental.reviewFreetext);
  }
  return texts.join(', ');
};

export const generateBoxActionInfo = (
  rental: AllRentalData,
  productName: string
): BoxActionInfo | undefined => {
  const boxName = rental.box.name;
  const compartmentName = rental.compartment.name;
  if (!compartmentName || !rental.rentStart || !rental.productStatus) {
    return undefined;
  }

  return {
    actionName: `${resolveBoxDisplayName(rental.box)}, ${compartmentName}`,
    boxNumber: parseInt(boxName, 10),
    compartmentName,
    productStatusText: resolveProductStatusText(rental),
    productName,
    rentalEnd: rental.rentEnd ?? rental.rentStart,
    productStatus: rental.productStatus,
  };
};
