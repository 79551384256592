import { ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { useAllUsers } from '../user/gql/useAllUsers';
import { NumberOfTimesUserRents } from './users/NumberOfTimesUserRents';
import { getSharesInPercentage } from './utils/getSharesInPercentage';

interface Props {}

export const StatisticsUsersPage: React.FC<Props> = () => {
  const {
    data: { rentals },
    loading: allRentalsLoading,
    error: allRentalsError,
  } = useAllRentals({
    variables: {
      pagination: {
        offset: 0,
        limit: 1000,
      },
    },
  });
  const {
    data: { totalCount, users },
    loading: allUsersLoading,
    error: allUsersError,
  } = useAllUsers({
    variables: {
      pagination: {
        offset: 0,
        limit: 1000,
      },
    },
  });

  if (allRentalsLoading || allUsersLoading) {
    return <LoadingSpinnerContainer />;
  }
  if (allRentalsError) {
    return <div>{allRentalsError.message}</div>;
  }
  if (allUsersError) {
    return <div>{allUsersError.message}</div>;
  }

  return (
    <Container>
      <Paper>
        <ListItem>
          <ListItemText
            color={'textSecondary'}
            primary={'Number of users total'}
            secondary={totalCount}
          />
        </ListItem>
      </Paper>
      <Box mt={2} />
      <Paper>
        <ListItem>
          <ListItemText
            color={'textSecondary'}
            primary={'Share of users with payment information'}
            secondary={`${getSharesInPercentage(
              users.filter((user) => !!user.paymentInformation).length,
              users.length
            )}%`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            color={'textSecondary'}
            primary={'Logged in using email+password'}
            secondary={`${getSharesInPercentage(
              users.filter(
                (user) =>
                  user.provider === 'auth0' || user.provider === 'firebase'
              ).length,
              users.length
            )}%`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            color={'textSecondary'}
            primary={'Logged in using apple'}
            secondary={`${getSharesInPercentage(
              users.filter((user) => user.provider === 'apple').length,
              users.length
            )}%`}
          />
        </ListItem>
        <ListItem>
          <ListItemText
            color={'textSecondary'}
            primary={'Logged in using google'}
            secondary={`${getSharesInPercentage(
              users.filter((user) => user.provider === 'google').length,
              users.length
            )}%`}
          />
        </ListItem>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography>Number of times user rents</Typography>
          </Box>
          <NumberOfTimesUserRents rentals={rentals} />
        </Box>
      </Paper>
    </Container>
  );
};
