import React, { useState } from 'react';
import { BoxLink } from '../../common/router_links/BoxLink';
import { BatteryReading } from '../../common/types/batteryReading';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { Box } from '../../webui/Box';
import { IconButton } from '../../webui/IconButton';
import { ChartViewIcon } from '../../webui/icons/ChartViewIcon';
import { TableViewIcon } from '../../webui/icons/TableViewIcon';
import { Paper } from '../../webui/Paper';
import { Typography } from '../../webui/Typography';
import { BoxBatteryChart } from './BoxBatteryChart';
import { BoxBatteryReadingsTable } from './BoxBatteryReadingsTable';
import { generateBatteryPerBoxData } from './utils/generateBatteryPerBoxData';

interface Props {
  readings: BatteryReading[];
  showTable?: boolean;
}

const getBatteryColor = (voltage: number) => {
  if (voltage < 7000) {
    return 'red';
  }

  if (voltage < 10000) {
    return '#fcba03';
  }

  return 'green';
};

export const BoxBatteryReadings: React.FC<Props> = ({
  readings,
  showTable = false,
}) => {
  const [showTableView, setShowTableView] = useState(showTable);

  const readingsData = generateBatteryPerBoxData(readings);

  const onToggleTableView = () => {
    setShowTableView(!showTableView);
  };

  return (
    <Paper>
      {readings.length > 0 ? (
        <>
          <Box
            p={4}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Box display={'flex'} flexDirection={'row'}>
              <BoxLink
                boxId={readings[0].box.id}
                linkText={resolveBoxDisplayName(readings[0].box)}
              />
              <Box
                ml={2}
                height={20}
                width={20}
                borderRadius={20}
                bgcolor={getBatteryColor(readings[readings.length - 1].voltage)}
              />
            </Box>
            <IconButton
              onClick={onToggleTableView}
              style={{
                width: 8,
                height: 8,
              }}
            >
              {showTableView ? <ChartViewIcon /> : <TableViewIcon />}
            </IconButton>
          </Box>
          {showTableView ? (
            <BoxBatteryReadingsTable readings={readingsData} />
          ) : (
            <BoxBatteryChart readings={readingsData} />
          )}
          <Box
            m={2}
            width={'100%'}
            display={'flex'}
            alignItems={'center'}
          ></Box>
        </>
      ) : (
        <Box p={2}>
          <Typography>No battery readings</Typography>
        </Box>
      )}
    </Paper>
  );
};
