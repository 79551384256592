import { Rental } from '../../../../common/types/rental';
import { resolveDisplayPrice } from '../../../../common/utils/resolveDisplayPrice';

export const calculatePriceForRentals = (rentals: Rental[]): number => {
  return rentals.reduce((sumPrice, rental) => {
    const paymentChargeInformation = rental.paymentChargeInformation;
    const rentalPrice = paymentChargeInformation
      ? resolveDisplayPrice(
          paymentChargeInformation.amount,
          paymentChargeInformation.currency
        )
      : 0;
    return sumPrice + (rentalPrice >= 800 ? 0 : rentalPrice);
  }, 0);
};
