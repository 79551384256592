import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { Sponsor as SponsorType } from '../../common/types/sponsor';
import {
  AllSponsorsDocument,
  useEditSponsorMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';

interface EditSponsorDrawerProps {
  sponsor: SponsorType;
  open: boolean;
  onClose: () => void;
}

interface EditSponsorForm {
  imageName: string;
  name: string;
  url: string;
}

const createInitialEditSponsorForm = (
  sponsor: SponsorType
): EditSponsorForm => ({
  imageName: sponsor.imageName,
  name: sponsor.name,
  url: sponsor.url ?? '',
});

// TODO: Form handling, validation, disable save etc
export const EditSponsorDrawer: React.FC<EditSponsorDrawerProps> = ({
  sponsor,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditSponsorForm(sponsor), [sponsor])
  );

  const [editSponsor, { loading }] = useEditSponsorMutation();

  const { showNotification } = useNotification();

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };
  const onImageNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('imageName', value);
  };
  const onUrlChange: TextFieldProps['onChange'] = (value) => {
    updateField('url', value);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editSponsor({
        variables: {
          sponsorId: sponsor.id,
          editSponsor: {
            imageName: form.imageName,
            name: form.name,
            url: form.url,
          },
        },
        refetchQueries: [{ query: AllSponsorsDocument }],
      });
      showNotification({
        message: 'Sponsor edited.',
        severity: 'success',
      });
      console.log('Sponsor edited!', result?.data?.editSponsor?.sponsor);
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit sponsor.',
        severity: 'error',
      });
      console.log('Sponsor edit error', e, '--- e');
    }
  };

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit sponsor "{sponsor.name}"</Typography>
        <form noValidate autoComplete="off">
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <TextField
              id="edit-sponsor-name"
              label="Name"
              value={form.name}
              onChange={onNameChange}
            />
            <Box mt={2} />
            <TextField
              id="edit-sponsor-image-name"
              multiline={true}
              label="Image name"
              value={form.imageName}
              onChange={onImageNameChange}
            />
            <Box mt={2} />
            <TextField
              id="edit-sponsor-url"
              multiline={true}
              label="Url"
              value={form.url}
              onChange={onUrlChange}
            />
          </Box>
        </form>
        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={loading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
