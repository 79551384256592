import * as Apollo from '@apollo/client';
import {
  GQL_ActivityAreaByIdQuery,
  GQL_ActivityAreaByIdQueryVariables,
  useActivityAreaByIdQuery,
} from '../../../generated/graphql';
import { transformActivityArea } from '../../../gql/transformActivityArea';

export const useActivityAreaById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_ActivityAreaByIdQuery,
    GQL_ActivityAreaByIdQueryVariables
  > &
    (
      | { variables: GQL_ActivityAreaByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useActivityAreaByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.activityArea?.activityAreaById
      ? transformActivityArea(data?.activityArea?.activityAreaById)
      : undefined,
  };
};
