import {
  BatteryReading,
  BatteryReadingBox,
} from '../common/types/batteryReading';
import {
  GQL_BatteryReadingBoxFragment,
  GQL_BatteryReadingFragment,
} from '../generated/graphql';
import { transformLocation } from './transformLocation';

const transformBatteryReadingBox = (
  box: GQL_BatteryReadingBoxFragment
): BatteryReadingBox => ({
  id: box.id,
  name: box.name,
  location: transformLocation(box.location),
});

export const transformBatteryReading = (
  batteryReading: GQL_BatteryReadingFragment
): BatteryReading => ({
  id: batteryReading.id,
  box: transformBatteryReadingBox(batteryReading.box),
  userId: batteryReading.userId,
  voltage: batteryReading.voltage,
  createdAt: batteryReading.createdAt,
});
