import React from 'react';
import { Pie, PieChart, Tooltip } from 'recharts';
import { Rental } from '../../../common/types/rental';
import {
  generateNumberOfTimesUserRentsData,
  NumberOfTimesUserRentsData,
} from './utils';

interface Props {
  rentals: Rental[];
}

export const NumberOfTimesUserRents: React.FC<Props> = ({ rentals }) => {
  const data = generateNumberOfTimesUserRentsData(rentals);

  return (
    <PieChart width={600} height={600}>
      <Pie
        data={data}
        dataKey="count"
        label={(data: NumberOfTimesUserRentsData) => {
          return data.label;
        }}
        nameKey="label"
        cx="50%"
        cy="50%"
        outerRadius={100}
        fill="#B40A7A"
      />
      <Tooltip />
    </PieChart>
  );
};
