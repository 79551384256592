import React from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { ActivityArea as ActivityAreaType } from '../../common/types/activityArea';
import { filterUndefinedCoordinates } from '../../common/utils/filterUndefinedCoordinates';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { ActivityArea } from './ActivityArea';
import { ActivityAreaMap } from './ActivityAreasMap';
import { ActivityAreasTable } from './ActivityAreasTable';
import { useAllActivityAreas } from './gql/useActivityAreas';

interface AllActivityAreasPageProps {}

const getActiveActivityAreas = (
  activityAreas: ActivityAreaType[]
): ActivityAreaType[] =>
  activityAreas.filter((activityArea) => activityArea.active);
const getInactiveActivityAreas = (
  activityAreas: ActivityAreaType[]
): ActivityAreaType[] =>
  activityAreas.filter((activityArea) => !activityArea.active);

export const AllActivityAreas: React.FC<AllActivityAreasPageProps> = () => {
  const { data: activityAreas, loading, error } = useAllActivityAreas();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Box>
        <Switch>
          <Route path={Routes.ACTIVITY_AREA}>
            <ActivityArea />
          </Route>
        </Switch>
      </Box>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>
            There are now currently{' '}
            {getActiveActivityAreas(activityAreas).length} active and{' '}
            {getInactiveActivityAreas(activityAreas).length} inactive
            activityAreas.
          </Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_ACTIVITY_AREA}>
            Add new activity area
          </Link>
        </Box>
      </Box>
      <Paper>
        <ActivityAreaMap
          activityAreaMarkers={filterUndefinedCoordinates(
            activityAreas.map((activityArea) => ({
              active: activityArea.active,
              coordinates: activityArea.location?.coordinates,
              id: activityArea.id,
              type: activityArea.type,
            }))
          )}
        />
      </Paper>
      <Box mt={2} />
      <ActivityAreasTable
        activityAreas={activityAreas}
        loading={loading}
        error={error}
      />
    </Container>
  );
};
