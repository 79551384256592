import React from 'react';
import { ProductStatus } from '../../common/types/productStatus';
import { resolveProductStatusDisplayName } from '../../common/utils/resolveProductStatusDisplayName';
import { Alert } from '../../webui/Alert';

interface CompartmentAlertProps {
  productStatus: ProductStatus;
  locked: boolean;
}

export const CompartmentAlert: React.FC<CompartmentAlertProps> = ({
  productStatus,
  locked,
}) => {
  if (productStatus === 'Ok') {
    if (locked) {
      return (
        <Alert severity="error">
          The product is ok but the compartment is locked! Unlock the
          compartment?
        </Alert>
      );
    }
  } else {
    if (!locked) {
      return (
        <Alert severity="error">
          {'This product status is "' +
            resolveProductStatusDisplayName(productStatus) +
            '", lock the compartment?'}
        </Alert>
      );
    } else {
      return (
        <Alert severity="warning">
          {'This product status is "' +
            resolveProductStatusDisplayName(productStatus) +
            '" and the compartment is locked.'}
        </Alert>
      );
    }
  }
  return null;
};
