import { Grid } from '@mui/material';
import React from 'react';
import { formatValueWithSpace } from '../../common/utils/FormatValueWithSpace';
import { Card } from '../../webui/Card';
import { CardContent } from '../../webui/CardContent';
import { Typography } from '../../webui/Typography';

interface Props {
  title: string;
  value: number;
  icon: React.ReactNode;
  color?: string;
}

export const DashboardCard: React.FC<Props> = ({
  title,
  value,
  icon,
  color,
}) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ backgroundColor: color || 'white' }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item>{icon}</Grid>
            <Grid item>
              <Typography variant="h5" sx={{ color: color ? 'white' : 'fff' }}>
                {title}
              </Typography>
              <Typography variant="h2" sx={{ color: color ? 'white' : 'fff' }}>
                {formatValueWithSpace(value)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
