import React from 'react';
import {
  ButtonWithLoading,
  ButtonWithLoadingProps,
} from '../ButtonWithLoading';

interface SaveButtonProps extends Pick<ButtonWithLoadingProps, 'onClick'> {
  loading: boolean;
}

export const SaveButton: React.FC<SaveButtonProps> = ({ loading, onClick }) => {
  return (
    <ButtonWithLoading
      color={'primary'}
      loading={loading}
      onClick={onClick}
      minWidth={'70px'}
    >
      Save
    </ButtonWithLoading>
  );
};
