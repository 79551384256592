import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { AllOrganisationsTable } from './AllOrganisationsTable';
import { useAllOrganisations } from './gql/useAllOrganisations';

interface Props {}

export const AllOrganisations: React.FC<Props> = () => {
  const { data: organisations, loading, error } = useAllOrganisations();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>
            There are currently {organisations.length} organisations!
          </Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_ORGANISATION}>
            Add new organisation
          </Link>
        </Box>
      </Box>
      <Box my={2}>
        <Paper>
          <AllOrganisationsTable organisations={organisations} />
        </Paper>
      </Box>
    </Container>
  );
};
