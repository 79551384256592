import { useTheme } from '@mui/material';
import React from 'react';
import { useNotification } from '../../../common/notifications/useNotification';
import {
  AllBoxesDocument,
  useLockCompartmentMutation,
} from '../../../generated/graphql';
import { Button } from '../../../webui/Button';
import { ButtonWithLoading } from '../../../webui/ButtonWithLoading';
import { Dialog } from '../../../webui/Dialog';
import { DialogActions } from '../../../webui/DialogActions';
import { DialogContent } from '../../../webui/DialogContent';
import { DialogContentText } from '../../../webui/DialogContentText';
import { DialogTitle } from '../../../webui/DialogTitle';

interface LockButtonProps {
  compartmentId: string;
}

export const LockButton: React.FC<LockButtonProps> = ({ compartmentId }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [lockCompartment, { loading }] = useLockCompartmentMutation({
    refetchQueries: [{ query: AllBoxesDocument }],
  });

  const { showNotification } = useNotification();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClose = () => {
    handleClose();
  };

  const onConfirm = async () => {
    try {
      const result = await lockCompartment({ variables: { compartmentId } });
      showNotification({
        message: 'Compartment locked.',
        severity: 'success',
      });
      console.log(
        'Compartment locked!',
        result?.data?.lockCompartment?.compartment
      );
      handleClose();
    } catch (e) {
      showNotification({
        message: 'Could not lock compartment.',
        severity: 'error',
      });
      console.log('Compartment lock error', e, '--- e');
    }
  };

  return (
    <>
      <Button
        sx={{
          backgroundColor: theme.palette.error.light,
        }}
        onClick={handleClickOpen}
      >
        Lock
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Lock the compartment?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to lock the compartment? No user will be able
            to use it until you unlock it!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Don't lock
          </Button>
          <ButtonWithLoading
            loading={loading}
            onClick={onConfirm}
            color="primary"
            autoFocus
          >
            Lock
          </ButtonWithLoading>
        </DialogActions>
      </Dialog>
    </>
  );
};
