import { ListItem, ListItemText } from '@mui/material';
import React, { useEffect } from 'react';
import { BoxWithoutRentals } from '../../common/types/box';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { Filter } from './filter/types';
import { transformFilterToAllRentalsGQLFilter } from './filter/utils/transformFilterToAllRentalsGQLFilter';
import { PostalCodeHeatMap } from './PostalCodeHeatMap';
import { ProductsPie } from './products/ProductsPie';
import { PublicRatings } from './public/PublicRatings';
import { PublicUsersPerBoxTable } from './public/PublicUsersPerBoxTable';
import { RentalFeedbacksTable } from './public/RentalFeedbacksTable';
import { RentalsPerBoxPie } from './rentals/RentalsPerBoxPie';
import { RentalsPerDay } from './rentals/RentalsPerDay';
import { RentalsPerGenderPie } from './rentals/RentalsPerGenderPie';
import { RentalTime } from './rentals/RentalTime';
import { RentalsPerAge } from './RentalsPerAge';

interface Props {
  allBoxes: BoxWithoutRentals[];
  filter: Filter;
}

export const PublicStatistics: React.FC<Props> = ({ allBoxes, filter }) => {
  const {
    data: { rentals },
    loading,
    error,
    refetch,
  } = useAllRentals({
    variables: {
      filter: transformFilterToAllRentalsGQLFilter(filter),
    },
  });

  const averageTime = 7;

  const rentalsTimes4 = [...rentals, ...rentals, ...rentals, ...rentals];

  useEffect(() => {
    refetch()
      .then(() => {})
      .catch((error) => {
        console.log('---', error, '--- error');
      });
  }, [filter, refetch]);

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Box>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Typography variant={'h5'}>Pifflarna</Typography>
          <ListItem>
            <ListItemText
              color={'textSecondary'}
              primary={'Antal pifflare (aktiverade personer)'}
              secondary={rentalsTimes4.length}
            />
          </ListItem>
          <PublicRatings rentals={rentals} />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Typography
            variant={'h5'}
          >{`Antal pifflare per dag i snitt över ${averageTime} dagar bakåt.`}</Typography>
          <RentalsPerDay
            endDate={filter?.endDate}
            fromDate={filter?.fromDate}
            rentals={rentalsTimes4}
            rollingAverage={averageTime}
            isPublic
          />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <PublicUsersPerBoxTable boxes={allBoxes} rentals={rentalsTimes4} />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Typography variant={'h5'}>Andel pifflare per box</Typography>
          <RentalsPerBoxPie boxes={allBoxes} rentals={rentalsTimes4} isPublic />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Typography variant={'h5'}>Andel använda produkter</Typography>
          <ProductsPie rentals={rentalsTimes4} />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Typography variant={'h5'}>Tid aktiviteten används</Typography>
          <RentalTime rentals={rentalsTimes4} />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Typography variant={'h5'}>
            Andel könstillhörighet pifflarna uppgett
          </Typography>
          <RentalsPerGenderPie rentals={rentals} isPublic />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Typography variant={'h5'}>Ålder på pifflarna</Typography>
          <RentalsPerAge rentals={rentalsTimes4} />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <RentalFeedbacksTable filter={filter} />
      </Paper>
      <Box mt={2} />
      <Paper>
        <PostalCodeHeatMap rentals={rentals} />
      </Paper>
    </Box>
  );
};
