import {
  AppBar as MUIAppBar,
  AppBarProps as MUIAppBarProps,
} from '@mui/material';
import React from 'react';

interface AppBarProps extends MUIAppBarProps {}

export const AppBar: React.FC<AppBarProps> = (props) => {
  return <MUIAppBar {...props} />;
};
