import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Product } from '../../common/types/product';
import { Rental } from '../../common/types/rental';
import { formatISO, toDisplayDateTime } from '../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { getRentTime } from '../../gql/transformRental';
import { Link } from '../../webui/Link';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import { useAllOngoingRentals } from './gql/useAllOngoingRentals';

interface Props {}

interface AllRentalData {
  id: string;
  box: Rental['box'];
  compartment: Rental['compartment'];
  product: Product;
  numberOfFailedOpenings: number;
  numberOfSuccessfulOpenings: number;
  rentBy: Rental['rentBy'];
  rentTime: Rental['rentTime'];
  rentStart?: string;
}

const createTableHeadCells: TableHeadCell<AllRentalData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.RENTAL, {
          id: row.id,
        })}
      >
        {row.id}
      </Link>
    ),
    maxWidth: 50,
  },
  {
    id: 'box',
    label: 'Box',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.BOX, {
          id: row['box'].id,
        })}
      >
        {resolveBoxDisplayName(row['box'])}
      </Link>
    ),
  },
  {
    id: 'compartment',
    label: 'Compartment',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.COMPARTMENT, {
          boxId: row['box'].id,
          compartmentId: row['compartment'].id,
        })}
      >
        {row['compartment'].name}
      </Link>
    ),
  },
  {
    id: 'product',
    label: 'Product',
    render: (value) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.PRODUCT, {
          id: value['product'].id,
        })}
      >
        {value['product'].name}
      </Link>
    ),
  },
  {
    id: 'rentBy',
    label: 'Rented by',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.USER, {
          id: row['rentBy'].id,
        })}
      >
        {row['rentBy'].email || 'Anonymized user'}
      </Link>
    ),
  },
  {
    id: 'rentStart',
    label: 'Started when?',
    render: (value) => toDisplayDateTime(value['rentStart']),
  },
  {
    id: 'rentTime',
    label: 'Rent time',
    render: (value) => getRentTime(value['rentStart'], formatISO(new Date())),
  },
  {
    id: 'numberOfSuccessfulOpenings',
    label: 'Successful opening',
    render: (value) => value['numberOfSuccessfulOpenings'],
  },
  {
    id: 'numberOfFailedOpenings',
    label: 'failedOpenings',
    render: (value) => value['numberOfFailedOpenings'],
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (rentals: Rental[]): AllRentalData[] =>
  rentals.map((rental) => {
    return {
      id: rental.id,
      box: rental.box,
      compartment: rental.compartment,
      product: rental.product,
      rentBy: rental.rentBy,
      rentStart: rental.rentStart,
      rentTime: rental.rentTime,
      numberOfFailedOpenings: rental.numberOfFailedOpenings,
      numberOfSuccessfulOpenings: rental.numberOfSuccessfulOpenings,
    };
  });

export const OngoingRentalsTable: React.FC<Props> = () => {
  const { data: ongoingRentals, loading, error } = useAllOngoingRentals();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <SortablePaginatedTable
      title={'Ongoing rentals'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'rentStart'}
      initialOrder={'desc'}
      rows={createRows(ongoingRentals)}
      tableHeadCells={createTableHeadCells}
    />
  );
};
