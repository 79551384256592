import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllPriceWeights } from './gql/useAllPriceWeights';
import { PriceWeightsTable } from './PriceWeightsTable';

interface Props {}

export const AllPriceWeights: React.FC<Props> = () => {
  const { data: priceWeights, loading, error } = useAllPriceWeights();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>
            There are currently {priceWeights.length} price weights!
          </Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_PRICE_WEIGHT}>
            Add new price weight
          </Link>
        </Box>
      </Box>
      <Paper>
        <PriceWeightsTable priceWeights={priceWeights} />
      </Paper>
    </Container>
  );
};
