import React from 'react';
import { Permission } from '../../common/types/permission';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';

interface Props {
  allPermissions: Permission[];
}

export interface PermissionData {
  id: string;
  description?: string;
  type: string;
}

const permissionHeadCells: TableHeadCell<PermissionData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (value) => value.id,
  },
  {
    id: 'description',
    label: 'Description',
    render: (value) => value.description,
  },
  {
    id: 'type',
    label: 'Type',
    render: (value) => value.type,
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (permissions: Permission[]): PermissionData[] => {
  return permissions.map((permission) => {
    return {
      id: permission.id,
      type: permission.type,
    };
  });
};

const permissionComparator: Comparator<PermissionData> = (order, orderBy) => {
  return getComparator(order, orderBy);
};

export const AllPermissionsTable: React.FC<Props> = ({ allPermissions }) => {
  return (
    <SortablePaginatedTable
      getComparator={permissionComparator}
      title={'Permission'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'id'}
      rows={createRows(allPermissions)}
      tableHeadCells={permissionHeadCells}
    />
  );
};
