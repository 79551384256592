import React, { useMemo, useState } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { Organisation as OrganisationType } from '../../common/types/organisation';
import {
  AllOrganisationsDocument,
  useEditOrganisationMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllBoxesForSelect } from '../box/gql/useAllBoxesForSelect';
import { useAllUsersForSelect } from '../user/gql/useAllUsersForSelect';
import { EditOrganisationContact } from './EditOrganisationContact';
import { EditOrganisationDetails } from './EditOrganisationDetails';

interface EditOrganisationDrawerProps {
  organisation: OrganisationType;
  open: boolean;
  onClose: () => void;
}

interface EditOrganisationForm {
  boxes: Option[];
  city?: string;
  contactEmail: string;
  contactName: string;
  country?: string;
  name: string;
  phoneNumber?: string;
  users: Option[];
  zipCode?: string;
}

const createInitialEditOrganisationForm = (
  organisation: OrganisationType
): EditOrganisationForm => ({
  boxes: organisation.boxes.map((box) => ({ label: box.name, value: box.id })),
  city: organisation.city,
  contactEmail: organisation.contactEmail,
  contactName: organisation.contactName,
  country: organisation.country,
  name: organisation.name,
  phoneNumber: organisation.phoneNumber,
  zipCode: organisation.zipCode,
  users: organisation.users.map((user) => ({
    label: user.email ?? '',
    value: user.id,
  })),
});

// TODO: Form handling, validation, disable save etc
export const EditOrganisationDrawer: React.FC<EditOrganisationDrawerProps> = ({
  organisation,
}) => {
  const [open, setOpen] = useState(false);
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditOrganisationForm(organisation), [
      organisation,
    ])
  );

  const [editOrganisation, { loading }] = useEditOrganisationMutation();
  const { data: allBoxes, loading: allBoxesLoading } = useAllBoxesForSelect();
  const { data: allUsers, loading: allUsersLoading } = useAllUsersForSelect();

  const { showNotification } = useNotification();

  const onBoxesChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('boxes', value.slice());
  };
  const onUsersChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('users', value.slice());
  };

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editOrganisation({
        variables: {
          organisationId: organisation.id,
          editOrganisation: {
            name: form.name,
            boxIds: form.boxes.map((box) => box.value),
            userIds: form.users.map((user) => user.value),
          },
        },
        refetchQueries: [{ query: AllOrganisationsDocument }],
      });
      showNotification({
        message: 'Organisation edited.',
        severity: 'success',
      });
      console.log(
        'Organisation edited!',
        result?.data?.editOrganisation?.organisation
      );
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit organisation.',
        severity: 'error',
      });
      console.log('Organisation edit error', e, '--- e');
    }
  };

  if (allBoxesLoading || allUsersLoading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <>
      <Box>
        <Button onClick={onOpen}>Edit</Button>
      </Box>
      <Drawer anchor={'right'} onClose={onClose} open={open}>
        <Box m={2}>
          <Typography variant="h4">
            Edit organisation "{organisation.name}"
          </Typography>
          <form noValidate autoComplete="off">
            <Box mt={2} display={'flex'} flexDirection={'column'}>
              <Box>
                <EditOrganisationDetails
                  form={form}
                  updateField={updateField}
                />
              </Box>
              <Box mt={2} />
              <Box>
                <EditOrganisationContact
                  form={form}
                  updateField={updateField}
                />
              </Box>
              <Box mt={2}>
                <MultiSearchSelect
                  closeMenuOnSelect={false}
                  label={'Boxes'}
                  onChange={onBoxesChange}
                  options={allBoxes.map((box) => ({
                    label: box.name,
                    value: box.id,
                  }))}
                  value={form.boxes}
                />
              </Box>
              <Box mt={2}>
                <MultiSearchSelect
                  closeMenuOnSelect={false}
                  label={'Users'}
                  onChange={onUsersChange}
                  options={allUsers.map((user) => ({
                    label: user.email ?? '',
                    value: user.id,
                  }))}
                  value={form.users}
                />
              </Box>
            </Box>
          </form>
          <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
            <Button onClick={onCancel}>Cancel</Button>
            <Box ml={2} />
            <SaveButton loading={loading} onClick={onSave} />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
