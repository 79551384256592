import { PriceWeight } from '../features/price/PriceWeight';
import { GQL_PriceWeightFragment } from '../generated/graphql';

export const transformPriceWeight = (
  priceWeight: GQL_PriceWeightFragment
): PriceWeight => ({
  id: priceWeight.id,
  name: priceWeight.name,
  start: priceWeight.start,
  firstHour: priceWeight.firstHour,
  secondHour: priceWeight.secondHour,
  thirdHour: priceWeight.thirdHour,
  fourthHour: priceWeight.fourthHour,
  fifthHour: priceWeight.fifthHour,
  sixthHour: priceWeight.sixthHour,
  penalty: priceWeight.penalty,
});
