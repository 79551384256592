import React from 'react';
import {
  ProductStatus,
  productStatusItems,
} from '../../common/types/productStatus';
import { MenuItem } from '../../webui/inputs/MenuItem';
import { Select, SelectChangeEvent } from '../../webui/inputs/Select';

export interface ProductStatusSelectProps {
  id: string;
  value?: ProductStatus;
  onChange: (
    value: ProductStatus,
    event: SelectChangeEvent<ProductStatus | undefined>
  ) => void;
}

export const ProductStatusSelect: React.FC<ProductStatusSelectProps> = ({
  id,
  onChange,
  value,
}) => {
  const onProductChange = (
    event: SelectChangeEvent<ProductStatus | undefined>
  ) => {
    onChange(event.target.value as ProductStatus, event);
  };

  return (
    <Select
      label={'Product status'}
      id={id}
      value={value}
      onChange={onProductChange}
    >
      {productStatusItems.map((product) => (
        <MenuItem key={product.value} value={product.value}>
          {product.label}
        </MenuItem>
      ))}
    </Select>
  );
};
