import React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Product } from '../../common/types/product';
import { Box } from '../../webui/Box';
import { Card } from '../../webui/Card';
import { CardActionArea } from '../../webui/CardActionArea';
import { CardMedia } from '../../webui/CardMedia';
import { Typography } from '../../webui/Typography';

interface Props {
  product: Product;
  numberOfTimesUsed: number;
}

export const ProductCard: React.FC<Props> = ({
  product,
  numberOfTimesUsed,
}) => {
  return (
    <Card>
      <CardActionArea
        component={Link}
        to={generatePath(Routes.PRODUCT, {
          id: product.id,
        })}
      >
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CardMedia
            component="img"
            image={`/products/${product.imageName}.svg`} // Update this path to your image directory
            alt={product.name}
            style={{ height: 80, width: 80, marginRight: '16px' }}
          />
          <div>
            <Typography variant="h6">{product.name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {numberOfTimesUsed}
              {` `}
              {numberOfTimesUsed === 1 ? 'usage' : 'usages'}
            </Typography>
          </div>
        </Box>
      </CardActionArea>
    </Card>
  );
};
