import * as Apollo from '@apollo/client';
import {
  GQL_AllBoxesForSelectQuery,
  GQL_AllBoxesForSelectQueryVariables,
  useAllBoxesForSelectQuery,
} from '../../../generated/graphql';

export const useAllBoxesForSelect = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBoxesForSelectQuery,
    GQL_AllBoxesForSelectQueryVariables
  >
) => {
  const { data, ...rest } = useAllBoxesForSelectQuery(baseOptions);
  return {
    ...rest,
    data:
      data?.box?.allBoxes.map((box) => ({
        id: box.id,
        name: box.name,
      })) ?? [],
  };
};
