import * as Apollo from '@apollo/client';
import {
  GQL_PendingUserByIdQuery,
  GQL_PendingUserByIdQueryVariables,
  usePendingUserByIdQuery,
} from '../../../generated/graphql';
import { transformPendingUser } from '../../../gql/transformPendingUser';

export const usePendingUserById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_PendingUserByIdQuery,
    GQL_PendingUserByIdQueryVariables
  > &
    (
      | { variables: GQL_PendingUserByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = usePendingUserByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.pendingUser?.pendingUserById
      ? transformPendingUser(data.pendingUser.pendingUserById)
      : undefined,
  };
};
