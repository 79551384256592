import * as React from 'react';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { BoxBatteryReadings } from '../battery-status/BoxBatteryReadings';
import { useBatteryReadingsByBoxId } from '../battery-status/gql/useBatteryReadingsByBoxId';

interface Props {
  boxId: string;
}

export const BoxPageBatteryReadings: React.FC<Props> = ({ boxId }) => {
  const {
    data: batteryReadings,
    loading: loadingBatteryReadings,
  } = useBatteryReadingsByBoxId({ variables: { id: boxId } });

  if (loadingBatteryReadings) {
    return <LoadingSpinnerContainer />;
  }

  return <BoxBatteryReadings readings={batteryReadings} showTable={true} />;
};
