import * as Apollo from '@apollo/client';
import {
  GQL_AllUsersForSelectQuery,
  GQL_AllUsersForSelectQueryVariables,
  useAllUsersForSelectQuery,
} from '../../../generated/graphql';

export const useAllUsersForSelect = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllUsersForSelectQuery,
    GQL_AllUsersForSelectQueryVariables
  >
) => {
  const { data, ...rest } = useAllUsersForSelectQuery(baseOptions);
  return {
    ...rest,
    data:
      data?.user?.allUsers.nodes.map((user) => ({
        id: user.id,
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
      })) ?? [],
  };
};
