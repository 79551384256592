import React from 'react';
import { Box } from './Box';
import { Button, ButtonProps } from './Button';
import { CircularProgress } from './progress/CircularProgress';

export interface ButtonWithLoadingProps extends ButtonProps {
  loading: boolean;
  minWidth?: string;
}

export const ButtonWithLoading: React.FC<ButtonWithLoadingProps> = ({
  children,
  loading,
  minWidth,
  ...props
}) => {
  return (
    <Box sx={{ minWidth }}>
      <Button {...props} fullWidth={true}>
        {loading ? <CircularProgress size={24} /> : children}
      </Button>
    </Box>
  );
};
