import React from 'react';
import { TextField } from '../../webui/inputs/TextField';
import { Table } from '../../webui/table/Table';
import { TableBody } from '../../webui/table/TableBody';
import { TableCell } from '../../webui/table/TableCell';
import { TableContainer } from '../../webui/table/TableContainer';
import { TableHead } from '../../webui/table/TableHead';
import { TableRow } from '../../webui/table/TableRow';
import { EditPriceWeightsTableForm } from './EditPriceWeightDrawer';

export type OnPriceWeightFieldChange = (
  key: keyof EditPriceWeightsTableForm,
  value: string
) => void;

interface Props {
  priceWeight: EditPriceWeightsTableForm;
  onFieldChange: OnPriceWeightFieldChange;
}

type PriceWeightTableData = {
  hour: string;
  weight: number;
  key: keyof EditPriceWeightsTableForm;
};

const createData = (
  hour: string,
  weight: number,
  key: keyof EditPriceWeightsTableForm
): PriceWeightTableData => ({
  hour,
  weight,
  key,
});

const createRows = (
  priceWeight: EditPriceWeightsTableForm
): PriceWeightTableData[] =>
  priceWeight
    ? [
        createData('Start', parseInt(priceWeight.start, 10), 'start'),
        createData('1', parseInt(priceWeight.firstHour, 10), 'firstHour'),
        createData('2', parseInt(priceWeight.secondHour, 10), 'secondHour'),
        createData('3', parseInt(priceWeight.thirdHour, 10), 'thirdHour'),
        createData('4', parseInt(priceWeight.fourthHour, 10), 'fourthHour'),
        createData('5', parseInt(priceWeight.fifthHour, 10), 'fifthHour'),
        createData('6', parseInt(priceWeight.sixthHour, 10), 'sixthHour'),
        createData('6+', parseInt(priceWeight.penalty, 10), 'penalty'),
      ]
    : [];

export const EditPriceWeightTable: React.FC<Props> = ({
  onFieldChange,
  priceWeight,
}) => {
  const rows = createRows(priceWeight);

  return (
    <TableContainer>
      <Table sx={{ width: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Hour</TableCell>
            <TableCell align="right">Weight</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.hour}>
              <TableCell component="th" scope="row">
                {row.hour}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id={`edit-price-weight${row.hour}`}
                  value={priceWeight[row.key]}
                  onChange={(value) => onFieldChange(row.key, value)}
                  variant={'outlined'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
