import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { useEditPriceWeightMutation } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';
import {
  EditPriceWeightTable,
  OnPriceWeightFieldChange,
} from './EditPriceWeightTable';
import { PriceWeight } from './PriceWeight';

interface EditPriceWeightDrawerProps {
  priceWeight: PriceWeight;
  open: boolean;
  onClose: () => void;
}

export interface EditPriceWeightsTableForm {
  start: string;
  firstHour: string;
  secondHour: string;
  thirdHour: string;
  fourthHour: string;
  fifthHour: string;
  sixthHour: string;
  penalty: string;
}

export type EditPriceWeightForm = EditPriceWeightsTableForm & {
  name: string;
};

const createInitialEditPriceWeightForm = (
  priceWeight: PriceWeight
): EditPriceWeightForm => ({
  name: priceWeight.name,
  start: String(priceWeight.start),
  firstHour: String(priceWeight.firstHour),
  secondHour: String(priceWeight.secondHour),
  thirdHour: String(priceWeight.thirdHour),
  fourthHour: String(priceWeight.fourthHour),
  fifthHour: String(priceWeight.fifthHour),
  sixthHour: String(priceWeight.sixthHour),
  penalty: String(priceWeight.penalty),
});

// TODO: Form handling, validation, disable save etc
export const EditPriceWeightDrawer: React.FC<EditPriceWeightDrawerProps> = ({
  priceWeight,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditPriceWeightForm(priceWeight), [priceWeight])
  );

  const { showNotification } = useNotification();

  const [editPriceWeight, { loading }] = useEditPriceWeightMutation();

  const onCancel = () => {
    reset();
    onClose();
  };

  const onFieldChange: OnPriceWeightFieldChange = (key, value) => {
    updateField(key, value);
  };
  const onNameChange: TextFieldProps['onChange'] = (val) => {
    updateField('name', val);
  };

  const onSave = async () => {
    try {
      await editPriceWeight({
        variables: {
          id: priceWeight.id,
          priceWeight: {
            name: form.name,
            start: Number(form.start),
            firstHour: Number(form.firstHour),
            secondHour: Number(form.secondHour),
            thirdHour: Number(form.thirdHour),
            fourthHour: Number(form.fourthHour),
            fifthHour: Number(form.fifthHour),
            sixthHour: Number(form.sixthHour),
            penalty: Number(form.penalty),
          },
        },
      });
      showNotification({
        message: 'Price weight edited.',
        severity: 'success',
      });
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit price weight.',
        severity: 'error',
      });
      console.log('PriceWeight edit error', e, '--- e');
    }
  };

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit priceWeight</Typography>
        <form noValidate autoComplete="off">
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <TextField
              label={'Name'}
              value={form.name}
              onChange={onNameChange}
            />
          </Box>
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <EditPriceWeightTable
              priceWeight={form}
              onFieldChange={onFieldChange}
            />
          </Box>
        </form>
        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={loading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
