import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';
import { PriceWeight } from './PriceWeight';

interface Props {
  priceWeights: PriceWeight[];
}

export interface PriceWeightData {
  id: string;
  name: string;
  start: string;
  firstHour: string;
  secondHour: string;
  thirdHour: string;
  fourthHour: string;
  fifthHour: string;
  sixthHour: string;
  penalty: string;
}

const priceWeightHeadCells: TableHeadCell<PriceWeightData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (value) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.PRICE_WEIGHT, { id: value.id })}
      >
        {value.id}
      </Link>
    ),
    maxWidth: 20,
  },
  {
    id: 'name',
    label: 'Name',
    render: (row) => row['name'],
  },
  {
    id: 'start',
    label: 'Start',
    render: (row) => row['start'],
  },
  {
    id: 'firstHour',
    label: 'First Hour',
    render: (row) => row['firstHour'],
  },
  {
    id: 'secondHour',
    label: 'Second Hour',
    render: (row) => row['secondHour'],
  },
  { id: 'thirdHour', label: 'Third Hour', render: (row) => row['thirdHour'] },
  {
    id: 'fourthHour',
    label: 'Fourth Hour',
    render: (row) => row['fourthHour'],
  },
  {
    id: 'fifthHour',
    label: 'Fifth Hour',
    render: (row) => row['fifthHour'],
  },
  {
    id: 'sixthHour',
    label: 'Sixth Hour',
    render: (row) => row['sixthHour'],
  },
  {
    id: 'penalty',
    label: 'Penalty',
    render: (row) => row['penalty'],
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (priceWeights: PriceWeight[]): PriceWeightData[] => {
  return priceWeights.map((priceWeight) => {
    return {
      id: priceWeight.id,
      name: priceWeight.name,
      start: `${priceWeight.start}%`,
      firstHour: `${priceWeight.firstHour}%`,
      secondHour: `${priceWeight.secondHour}%`,
      thirdHour: `${priceWeight.thirdHour}%`,
      fourthHour: `${priceWeight.fourthHour}%`,
      fifthHour: `${priceWeight.fifthHour}%`,
      sixthHour: `${priceWeight.sixthHour}%`,
      penalty: `${priceWeight.penalty}%`,
    };
  });
};

const priceWeightComparator: Comparator<PriceWeightData> = (order, orderBy) => {
  return getComparator(order, orderBy);
};

export const PriceWeightsTable: React.FC<Props> = ({ priceWeights }) => {
  return (
    <SortablePaginatedTable
      getComparator={priceWeightComparator}
      title={'Price weights'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'id'}
      rows={createRows(priceWeights)}
      tableHeadCells={priceWeightHeadCells}
    />
  );
};
