import * as L from 'leaflet';
import { BatteryStatus } from './utils/batteryStatus';

export const resolveBatteryStatusIcon = (batteryStatus: BatteryStatus) => {
  switch (batteryStatus) {
    case 'LOW_BATTERY':
      return lowBatteryMarker;
    case 'MEDIUM_BATTERY':
      return mediumBatteryMarker;
    case 'HIGH_BATTERY':
      return highBatteryMarker;
    case 'UNKNOWN':
      return unknownBatteryMarker;
  }
};

const lowBatteryMarker = L.icon({
  iconUrl: '/markers/box-marker-low-battery.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const mediumBatteryMarker = L.icon({
  iconUrl: '/markers/box-marker-medium-battery.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const highBatteryMarker = L.icon({
  iconUrl: '/markers/box-marker-high-battery.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

const unknownBatteryMarker = L.icon({
  iconUrl: '/markers/box-marker-unknown-battery.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});
