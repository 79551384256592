import React from 'react';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { RentalsTable } from '../rentals/RentalsTable';
import {
  DASHBOARD_DAYS_BACK,
  dashboardInitialFromDate,
} from './dashboardUtils';

interface Props {}

export const RentalsThatMayNeedActionsTable: React.FC<Props> = () => {
  const {
    data: { rentals },
    loading,
    error,
  } = useAllRentals({
    variables: {
      filter: {
        startDate: {
          after: dashboardInitialFromDate,
        },
        mayNeedAction: true,
      },
    },
  });

  return (
    <RentalsTable
      error={error}
      loading={loading}
      rentals={rentals}
      searchEnabled={false}
      title={`Rentals that may need action last ${DASHBOARD_DAYS_BACK} days`}
    />
  );
};
