import { Grid } from '@mui/material';
import React from 'react';
import { Product } from '../../common/types/product';
import { Rental } from '../../common/types/rental';
import { isSameDay, parseISO, subDays } from '../../common/utils/dateUtils';
import { KeyIcon } from '../../webui/icons/KeyIcon';
import { PeopleIcon } from '../../webui/icons/PeopleIcon';
import { SportsSoccerIcon } from '../../webui/icons/SportsSoccerIcon';
import { Typography } from '../../webui/Typography';
import { DashboardCard } from './DashboardCard';
import { DASHBOARD_DAYS_BACK } from './dashboardUtils';
import { MostCommonBoxes } from './MostCommonBoxes';
import { MostCommonProducts } from './MostCommonProducts';

interface Props {
  rentals: Rental[];
  totalBoxesCount: number;
  totalProductsCount: number;
  totalRentalsCount: number;
  totalUsersCount: number;
}

export const Dashboard: React.FC<Props> = ({
  rentals,
  totalBoxesCount,
  totalProductsCount,
  totalRentalsCount,
  totalUsersCount,
}) => {
  const totalRentals = rentals.length;

  const yesterday = subDays(new Date(), 1);

  const rentalsYesterday = rentals.filter((rental) => {
    const rentalDate = parseISO(rental.rentStart);
    return isSameDay(rentalDate, yesterday);
  });

  const numberOfRentalsYesterday = rentalsYesterday.length;

  const numberOfUniqueUsersYesterday = new Set(
    rentalsYesterday.map((rental) => rental.rentBy.id)
  ).size;

  const numberOfUniqueProductsYesterday = new Set(
    rentalsYesterday.map((rental) => rental.product.id)
  ).size;

  const numberOfUniqueBoxesYesterday = new Set(
    rentalsYesterday.map((rental) => rental.box.id)
  ).size;

  const totalUsers = new Set(rentals.map((rental) => rental.rentBy.id)).size;

  const productUsage: { [key: string]: { product: Product; count: number } } =
    {};
  rentals.forEach((rental) => {
    const productId = rental.product.id;
    if (!productUsage[productId]) {
      productUsage[productId] = { product: rental.product, count: 0 };
    }
    productUsage[productId].count++;
  });
  const numberOfProducts = Object.keys(productUsage).length;

  const boxUsage: { [key: string]: { box: Rental['box']; count: number } } = {};
  rentals.forEach((rental) => {
    const boxId = rental.box.id;
    if (!boxUsage[boxId]) {
      boxUsage[boxId] = { box: rental.box, count: 0 };
    }
    boxUsage[boxId].count++;
  });
  const numberOfBoxes = Object.keys(boxUsage).length;

  const cardColor = '#044A5A';

  const boxIcon = (
    <img
      src="/box-white.svg"
      alt="Box Icon"
      style={{
        width: '40px',
        height: '40px',
        color: 'white',
      }}
    />
  );
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Overall</Typography>
      </Grid>

      <DashboardCard
        title="Rentals"
        value={totalRentalsCount}
        icon={<KeyIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <DashboardCard
        title="Users"
        value={totalUsersCount}
        icon={<PeopleIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <DashboardCard
        title="Boxes"
        value={totalBoxesCount}
        icon={boxIcon}
        color={cardColor}
      />

      <DashboardCard
        title="Products"
        value={totalProductsCount}
        icon={<SportsSoccerIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <Grid item xs={12}>
        <Typography variant="h4">{`Last ${DASHBOARD_DAYS_BACK} Days`}</Typography>
      </Grid>

      <DashboardCard
        title="Rentals"
        value={totalRentals}
        icon={<KeyIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <DashboardCard
        title="Users with rentals"
        value={totalUsers}
        icon={<PeopleIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <DashboardCard
        title="Used Boxes"
        value={numberOfBoxes}
        icon={boxIcon}
        color={cardColor}
      />

      <DashboardCard
        title="Used Products"
        value={numberOfProducts}
        icon={<SportsSoccerIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          Yesterday
        </Typography>
      </Grid>

      <DashboardCard
        title="Rentals"
        value={numberOfRentalsYesterday}
        icon={<KeyIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <DashboardCard
        title="Users with rentals"
        value={numberOfUniqueUsersYesterday}
        icon={<PeopleIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />

      <DashboardCard
        title="Used Boxes"
        value={numberOfUniqueBoxesYesterday}
        icon={boxIcon}
        color={cardColor}
      />

      <DashboardCard
        title="Used Products"
        value={numberOfUniqueProductsYesterday}
        icon={<SportsSoccerIcon fontSize="large" sx={{ color: 'white' }} />}
        color={cardColor}
      />
      <MostCommonProducts productUsage={productUsage} />
      <MostCommonBoxes boxUsage={boxUsage} />
    </Grid>
  );
};
