import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { OrganisationUser } from '../../common/types/organisation';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';

interface Props {
  users: OrganisationUser[];
}

const INITIAL_ROWS_PER_PAGE = 20;

const userTableHeadCells: TableHeadCell<UserData>[] = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 100,
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.USER, {
          id: row.id,
        })}
      >
        {row.id}
      </Link>
    ),
  },
  {
    id: 'email',
    label: 'Email',
    render: (value) => value.email,
  },
  {
    id: 'name',
    label: 'Name',
    render: (value) => value.name,
  },
  {
    id: 'roles',
    label: 'Roles',
    render: (value) => value.roles,
  },
];

export interface UserData {
  id: string;
  email?: string;
  name: string;
  roles: string;
}

const createUserData = (user: OrganisationUser): UserData => ({
  id: user.id,
  email: user.email,
  name: `${user.firstName} ${user.lastName}`,
  roles: user.roles.map((role) => role.name).join(', '),
});

export const OrganisationUsersTable: React.FC<Props> = ({ users }) => {
  return (
    <SortablePaginatedTable
      initialOrderByKey={'id'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      rows={users.map(createUserData)}
      tableHeadCells={userTableHeadCells}
      title={'Users in organisation'}
    />
  );
};
