import React from 'react';
import { Form } from '../../common/hooks/useForm';
import { Box } from '../../webui/Box';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { OrganisationForm } from './AddOrganisation';

interface Props {
  form: OrganisationForm;
  updateField: Form<OrganisationForm>['updateField'];
}

export const EditOrganisationDetails: React.FC<Props> = ({
  form,
  updateField,
}) => {
  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };
  const onCityChange: TextFieldProps['onChange'] = (value) => {
    updateField('city', value);
  };
  const onCountryChange: TextFieldProps['onChange'] = (value) => {
    updateField('country', value);
  };
  const onZipCodeChange: TextFieldProps['onChange'] = (value) => {
    updateField('zipCode', value);
  };

  return (
    <Box>
      <Box display={'inline-flex'} flexDirection={'row'}>
        <Box>
          <TextField label={'Name'} onChange={onNameChange} value={form.name} />
        </Box>
        <Box ml={2}>
          <TextField label={'City'} onChange={onCityChange} value={form.city} />
        </Box>
        <Box ml={2}>
          <TextField
            label={'Country'}
            onChange={onCountryChange}
            value={form.country}
          />
        </Box>
        <Box ml={2}>
          <TextField
            label={'Zip code'}
            onChange={onZipCodeChange}
            value={form.zipCode}
          />
        </Box>
      </Box>
    </Box>
  );
};
