import { Rental } from '../../../../common/types/rental';
import { formatDate } from '../../../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../../../common/utils/resolveBoxDisplayName';

interface RentalsPerBoxPerDayData {
  [boxName: string]: number | string;

  date: string;
}

export const generateMeanRentalsPerBoxPerDayData = (
  rentals: Rental[],
  fromDate: string,
  toDate: string,
  rollingAverage: number
): RentalsPerBoxPerDayData[] => {
  // Helper function to iterate over date range
  const getDateRange = (start: Date, end: Date): string[] => {
    const dates: string[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dates.push(formatDate(new Date(currentDate)));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  // Convert fromDate and toDate to Date objects
  const from = new Date(fromDate);
  const to = new Date(toDate);

  // Initialize the map to hold box rentals for each date
  const rentalsPerBoxPerDayMap: {
    [date: string]: { [boxName: string]: number };
  } = {};

  // Collect all unique box names
  const allBoxes: Set<string> = new Set();

  // Iterate over rentals, accumulate rental counts per box per date, and collect box names
  rentals.forEach((rental) => {
    const rentalDate = formatDate(new Date(rental.rentStart));
    const boxName = resolveBoxDisplayName(rental.box);
    allBoxes.add(boxName);

    // Only consider rentals within the date range
    if (new Date(rentalDate) >= from && new Date(rentalDate) <= to) {
      if (!rentalsPerBoxPerDayMap[rentalDate]) {
        rentalsPerBoxPerDayMap[rentalDate] = {};
      }

      if (!rentalsPerBoxPerDayMap[rentalDate][boxName]) {
        rentalsPerBoxPerDayMap[rentalDate][boxName] = 0;
      }

      rentalsPerBoxPerDayMap[rentalDate][boxName] += 1;
    }
  });

  // Get all dates in the range from fromDate to toDate
  const allDates = getDateRange(from, to);

  // Helper function to calculate the mean for the past 7 days
  const calculateMeanForPast7Days = (
    currentDate: string,
    boxName: string
  ): number => {
    const currentIndex = allDates.indexOf(currentDate);
    let totalRentals = 0;
    let daysCounted = 0;

    // Loop over the past 7 days (including current date)
    const pastDates = allDates.slice(
      Math.max(0, currentIndex - rollingAverage + 1),
      currentIndex + 1
    );

    pastDates.forEach((date) => {
      if (
        rentalsPerBoxPerDayMap[date] &&
        rentalsPerBoxPerDayMap[date][boxName]
      ) {
        totalRentals += rentalsPerBoxPerDayMap[date][boxName];
        daysCounted++;
      }
    });

    return daysCounted > 0 ? totalRentals / daysCounted : 0;
  };

  // Prepare final data, calculating mean values over the past 7 days
  return allDates.map((date) => {
    const totalRentalsPerBox: RentalsPerBoxPerDayData = { date };

    // Ensure every box is populated for every date
    allBoxes.forEach((boxName) => {
      totalRentalsPerBox[boxName] = calculateMeanForPast7Days(date, boxName);
    });

    return totalRentalsPerBox;
  });
};
