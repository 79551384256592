import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../common/types/product';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllProducts } from '../product/gql/useAllProducts';
import { sortProducts } from '../product/utils/sortProducts';
import { useTranslatedProduct } from '../product/utils/useTranslatedProduct';
import {
  getSelectableProducts,
  productWithSizeList,
} from './utils/productsSelectHelper';

export const CompartmentSelector: React.FC = () => {
  const { data: notSortedProducts, loading, error } = useAllProducts();
  const { tName } = useTranslatedProduct();
  const products = sortProducts(
    notSortedProducts.map((product) => {
      return { ...product, name: tName(product) };
    })
  );
  const { t } = useTranslation();

  // State to keep track of the selected number of compartments
  const [compartments, setCompartments] = useState<number>(3);

  // State to keep track of the selected product for each compartment
  const [selectedProducts, setSelectedProducts] = useState<(Product | null)[]>(
    Array(compartments).fill(null)
  );
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currentCompartment, setCurrentCompartment] = useState<number | null>(
    null
  );

  const [showProductWithSizes, setShowProductWithSizes] =
    useState<boolean>(false);

  // Handle changing the number of compartments
  const handleCompartmentsChange: SelectProps['onChange'] = (event) => {
    const newCompartmentCount = event.target.value as number;
    setCompartments(newCompartmentCount);
    setSelectedProducts(Array(newCompartmentCount).fill(null)); // Reset the selection when compartments change
  };

  // Handle opening and closing of the product selection dialog
  const handleOpenDialog = (compartmentIndex: number) => {
    setCurrentCompartment(compartmentIndex);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setCurrentCompartment(null);
  };

  // Handle selecting a product for a specific compartment
  const handleSelectProduct = (product: Product | null) => {
    if (currentCompartment !== null) {
      const updatedProducts = [...selectedProducts];
      updatedProducts[currentCompartment] = product;
      setSelectedProducts(updatedProducts);
      handleCloseDialog();
    }
  };

  // Calculate the layout for the compartments
  const firstBoxCompartments = Math.min(compartments, 8);
  const secondBoxCompartments = compartments > 8 ? compartments - 8 : 0;

  // Determine height based on index
  const getCompartmentHeight = (index: number) => {
    if (compartments === 14) {
      if ([0, 1, 2, 3, 8, 9].includes(index)) return '60px'; // Smallest height
      if ([4, 5, 10, 11].includes(index)) return '80px'; // Small height
      if ([6, 7].includes(index)) return '100px'; // Middle height
      if ([12, 13].includes(index)) return '235px'; // Big height
    }
    return '100px'; // Default height
  };

  // Summarize selected products
  const productCountMap: {
    [key: string]: { product: Product; count: number };
  } = {};
  selectedProducts.forEach((product) => {
    if (product) {
      if (!productCountMap[product.id]) {
        productCountMap[product.id] = { product, count: 0 };
      }
      productCountMap[product.id].count++;
    }
  });
  const summarizedProducts = Object.values(productCountMap);

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
      {/* Selector for number of compartments */}
      <FormControl
        variant="outlined"
        size="small"
        style={{ marginBottom: '16px' }}
      >
        <InputLabel id="compartment-select-label">
          {t('compartment_selector.boxType', 'Box type')}
        </InputLabel>
        <Select
          labelId="compartment-select-label"
          value={compartments}
          onChange={handleCompartmentsChange}
          label="Compartments"
        >
          <MenuItem value={3}>
            {t(
              'compartment_selector.numberOfCompartments',
              '{{numberOfCompartments}} compartments',
              { numberOfCompartments: 3 }
            )}
          </MenuItem>
          <MenuItem value={6}>
            {t(
              'compartment_selector.numberOfCompartments',
              '{{numberOfCompartments}} compartments',
              { numberOfCompartments: 6 }
            )}
          </MenuItem>
          <MenuItem value={14}>
            {t(
              'compartment_selector.numberOfCompartments',
              '{{numberOfCompartments}} compartments',
              { numberOfCompartments: 14 }
            )}
          </MenuItem>
        </Select>
      </FormControl>

      {/* Compartments */}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="flex-start"
        flexWrap="wrap"
        marginBottom={2}
        gap={1} // Adjust spacing between the boxes
      >
        {/* First Box */}
        <Paper
          elevation={3}
          style={{
            border: '2px solid #B40A7A',
            padding: '8px',
            flex: '1 1 45%', // Allow the box to take up 45% of the available width
            maxWidth: '300px',
            display: 'inline-block',
          }}
        >
          <Grid container spacing={1}>
            {Array.from({ length: firstBoxCompartments }, (_, index) => (
              <Grid
                item
                xs={firstBoxCompartments <= 3 ? 12 : 6} // One column if compartments <= 3, otherwise two columns
                key={index}
              >
                <Card variant="outlined">
                  <CardContent
                    onClick={() => handleOpenDialog(index)}
                    style={{
                      cursor: 'pointer',
                      textAlign: 'center',
                      minHeight: getCompartmentHeight(index), // Set height based on index
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {selectedProducts[index] ? (
                      <>
                        <Avatar
                          alt={selectedProducts[index]?.name}
                          src={`/products/${selectedProducts[index]?.imageName}.svg`} // Updated image path
                          style={{ margin: '0 auto', width: 40, height: 40 }}
                        />
                        <Typography variant="body2">
                          {selectedProducts[index]
                            ? selectedProducts[index].name
                            : ''}
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {t(
                          'compartment_selector.productSelector',
                          'Select product'
                        )}
                      </Typography>
                    )}
                  </CardContent>
                </Card>
                {/* Display the index number next to the compartment */}
                <Box display="flex" justifyContent="center" marginTop={0.5}>
                  <Typography variant="subtitle2" color="textSecondary">
                    {index + 1}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>

        {/* Second Box (if applicable) */}
        {secondBoxCompartments > 0 && (
          <Paper
            elevation={3}
            style={{
              border: '2px solid #B40A7A',
              padding: '8px',
              flex: '1 1 45%', // Allow the box to take up 45% of the available width
              maxWidth: '300px',
              display: 'inline-block',
            }}
          >
            <Grid container spacing={1}>
              {Array.from({ length: secondBoxCompartments }, (_, index) => (
                <Grid
                  item
                  xs={secondBoxCompartments <= 3 ? 12 : 6} // One column if compartments <= 3, otherwise two columns
                  key={index + 8}
                >
                  <Card variant="outlined">
                    <CardContent
                      onClick={() => handleOpenDialog(index + 8)}
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        minHeight: getCompartmentHeight(index + 8), // Set height based on index
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {selectedProducts[index + 8] ? (
                        <>
                          <Avatar
                            alt={selectedProducts[index + 8]?.name}
                            src={`/products/${
                              selectedProducts[index + 8]?.imageName
                            }.svg`} // Updated image path
                            style={{ margin: '0 auto', width: 40, height: 40 }}
                          />
                          <Typography variant="body2">
                            {selectedProducts[index + 8]?.name}
                          </Typography>
                        </>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          {t(
                            'compartment_selector.productSelector',
                            'Select product'
                          )}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                  {/* Display the index number next to the compartment */}
                  <Box display="flex" justifyContent="center" marginTop={0.5}>
                    <Typography variant="subtitle2" color="textSecondary">
                      {index + 9}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        )}
      </Box>

      {/* Summary of selected products */}
      <Box marginTop={2} width="100%" maxWidth="600px">
        <Typography variant="h6" gutterBottom>
          {t('compartment_selector.selectedProducts', 'Selected products')}
        </Typography>
        <List>
          {summarizedProducts.map(({ product, count }) => (
            <ListItem key={product.id}>
              <Avatar
                alt={product.name}
                src={`/products/${product.imageName}.svg`}
                style={{ marginRight: 8 }}
              />
              <ListItemText
                primary={product.name}
                secondary={t('compartment_selector.count', 'Count: {{count}}', {
                  count,
                })}
              />
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <Button
          onClick={() => setShowProductWithSizes(!showProductWithSizes)}
          variant="text"
          endIcon={
            showProductWithSizes ? <ExpandLessIcon /> : <ExpandMoreIcon />
          }
          sx={{ marginBottom: 2 }}
        >
          {showProductWithSizes
            ? t(
                'compartment_selector.hideSizeInformation',
                'Hide size information'
              )
            : t(
                'compartment_selector.showSizeInformation',
                'Show size information'
              )}
        </Button>
      </Box>

      <Collapse in={showProductWithSizes}>
        <Box marginTop={2} width="100%" maxWidth="600px">
          <Typography variant="h6" gutterBottom>
            {t('compartment_selector.productList', 'Product sizes')}
          </Typography>
          <List>
            {products.map((product) => (
              <ListItem key={product.id}>
                <Avatar
                  alt={product.name}
                  src={`/products/${product.imageName}.svg`} // Updated image path
                  style={{ marginRight: 8 }}
                />
                <ListItemText
                  primary={t('products.name.' + product.translationKey)}
                  secondary={(
                    productWithSizeList.find(
                      (p) => p.translationKey === product.translationKey
                    )?.sizes ?? []
                  ).join(', ')}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Collapse>

      {/* Product selection dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          {t('compartment_selector.productSelector', 'Select product')}
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleSelectProduct(null)}>
                <ListItemText
                  primary={t(
                    'compartment_selector.clearSelection',
                    'Clear Selection'
                  )}
                />
              </ListItemButton>
            </ListItem>
            {getSelectableProducts(
              compartments,
              currentCompartment,
              products
            ).map((product) => (
              <ListItem key={product.id} disablePadding>
                <ListItemButton onClick={() => handleSelectProduct(product)}>
                  <Avatar
                    alt={product.name}
                    src={`/products/${product.imageName}.svg`} // Updated image path
                    style={{ marginRight: 8 }}
                  />
                  <ListItemText primary={product.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            {t('compartment_selector.cancel', 'Cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
