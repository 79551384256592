import React from 'react';
import { Box } from '../Box';
import { CircularProgress } from './CircularProgress';

interface LoadingSpinnerContainerProps {
  color?: string;
  size?: number;
}

export const LoadingSpinnerContainer: React.FC<LoadingSpinnerContainerProps> = ({
  color,
  size = 40,
}) => {

  return (
    <Box
      color={color}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      flexGrow={1}
      p={1}
    >
      <CircularProgress color={color ? 'inherit' : undefined} size={size} />
    </Box>
  );
};
