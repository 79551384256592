import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Product as ProductType } from '../../common/types/product';
import { resolveRentalTypeDisplayName } from '../../common/utils/ResolveRentalTypeDisplayName';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { Typography } from '../../webui/Typography';
import { ProductTranslation } from './ProductTranslation';
import { useTranslatedProduct } from './utils/useTranslatedProduct';

interface Props {
  product: ProductType;
}

export const Product: React.FC<Props> = ({ product }) => {
  const { description, id, imageName, name, rentalType, translationKey } =
    product;
  const { tDescription, tName } = useTranslatedProduct();

  return (
    <Box
      textAlign={'start'}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
    >
      <Box>
        <Link
          component={RouterLink}
          to={generatePath(Routes.PRODUCT, { id })}
          underline={'none'}
          color={'primary'}
          fontWeight={'bold'}
        >
          <Typography variant={'h6'} align={'center'}>
            {name}
          </Typography>
        </Link>

        <Box
          mt={2}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img
            src={`/products/${imageName}.svg`}
            alt={imageName}
            width={80}
            height={80}
            style={{ borderRadius: 8, objectFit: 'cover' }}
          />
        </Box>

        <Box mt={2}>
          <Typography
            variant={'body2'}
            color={'textSecondary'}
            align={'center'}
          >
            Rental type: {resolveRentalTypeDisplayName(rentalType)}
          </Typography>
        </Box>

        <Box mt={2}>
          {translationKey === undefined ? (
            <Typography variant={'body1'} align={'center'}>
              {description}
            </Typography>
          ) : (
            <>
              <ProductTranslation
                lng={'en'}
                name={tName(product, 'en')}
                description={tDescription(product, 'en')}
              />
              <ProductTranslation
                lng={'sv'}
                name={tName(product, 'sv')}
                description={tDescription(product, 'sv')}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
