import { Checkbox, FormControlLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import React from 'react';

export interface CheckboxWithLabelProps
  extends Pick<FormControlLabelProps, 'label' | 'labelPlacement'> {
  onChange: (value: boolean, event: React.ChangeEvent<{}>) => void;
  value: boolean;
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  label,
  labelPlacement,
  onChange,
  value,
}) => {
  const onFormControlChange = (event: React.ChangeEvent<{}>) => {
    onChange(!value, event);
  };

  return (
    <FormControl>
      <FormControlLabel
        style={{ marginLeft: 0, justifyContent: 'flex-end' }}
        value={value}
        control={<Checkbox checked={value} color="primary" />}
        label={label}
        labelPlacement={labelPlacement}
        onChange={onFormControlChange}
      />
    </FormControl>
  );
};
