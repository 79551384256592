import * as Apollo from '@apollo/client';
import {
  GQL_BatteryReadingsByBoxIdQuery,
  GQL_BatteryReadingsByBoxIdQueryVariables,
  useBatteryReadingsByBoxIdQuery,
} from '../../../generated/graphql';
import { transformBatteryReading } from '../../../gql/transformBatteryReading';

export const useBatteryReadingsByBoxId = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_BatteryReadingsByBoxIdQuery,
    GQL_BatteryReadingsByBoxIdQueryVariables
  > &
    (
      | { variables: GQL_BatteryReadingsByBoxIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useBatteryReadingsByBoxIdQuery(baseOptions);
  return {
    ...rest,
    data:
      data?.battery?.batteryReadingsByBoxId.map(transformBatteryReading) ?? [],
  };
};
