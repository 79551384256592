import MUIDrawer from '@mui/material/Drawer';
import React from 'react';

interface DrawerProps extends React.ComponentProps<typeof MUIDrawer> {}

export const Drawer: React.FC<DrawerProps> = ({
  anchor = 'right',
  ...props
}) => {
  return <MUIDrawer {...props} anchor={anchor} />;
};
