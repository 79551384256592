import { RentBy } from '../../../common/types/rental';
import { toDisplayDateTime } from '../../../common/utils/dateUtils';
import { AllRentalData } from '../types';

const getName = (rentBy: RentBy) => {
  let name = '';
  if (rentBy.firstName) {
    name += rentBy.firstName;
  }

  if (rentBy.lastName) {
    name += ' ' + rentBy.lastName;
  }

  return name ? `\nNamn: ${name}` : '';
};

const getTele = (rentBy: RentBy) =>
  rentBy.phoneNumber ? `\nTele: ${rentBy.phoneNumber}` : '';

const getMail = (rentBy: RentBy) =>
  rentBy.email ? `\nMail: ${rentBy.email}` : '';

export const getDisplayRental = (rental: AllRentalData) => {
  const boxName = rental.box.name;
  const compartmentName = rental.compartment.name;
  const productName = rental.product.name;
  const rentStart = toDisplayDateTime(rental.rentStart);
  const rentEnd = toDisplayDateTime(rental.rentEnd);
  const reviewText = rental.reviewFreetext;

  const boxInfo =
    `Box ${boxName}, Fack ${compartmentName}, ${productName}, ${rentStart}\t${rentEnd}` +
    (reviewText ? `, "${reviewText}"` : '');

  return `${boxInfo}${getName(rental.rentBy)}${getTele(rental.rentBy)}${getMail(
    rental.rentBy
  )}`;
};
