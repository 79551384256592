import { Rental } from '../../../../common/types/rental';

export const sortObjectByValueDescending = (obj: {
  [name: string]: { box: Rental['box']; count: number };
}): [string, { box: Rental['box']; count: number }][] =>
  Object.entries(obj).sort(
    ([, valueA], [, valueB]) => valueB.count - valueA.count
  );

interface NumberOfRentalsPerBoxMap {
  [id: string]: { box: Rental['box']; count: number };
}

export const generateNumberOfRentalsPerBox = (
  rentals: Rental[]
): NumberOfRentalsPerBoxMap =>
  rentals.reduce<NumberOfRentalsPerBoxMap>((acc, rental) => {
    const box = rental.box;
    const id = box.id;
    if (acc[id]) {
      acc[id].count += 1;
    } else {
      acc[id] = { box, count: 1 };
    }
    return acc;
  }, {});

export const generateMostUsedBoxList = (rentals: Rental[]) => {
  return sortObjectByValueDescending(generateNumberOfRentalsPerBox(rentals));
};
