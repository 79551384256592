import React from 'react';
import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Rental } from '../../common/types/rental';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { Box } from '../../webui/Box';
import { Card } from '../../webui/Card';
import { CardActionArea } from '../../webui/CardActionArea';
import { Typography } from '../../webui/Typography';

interface Props {
  box: Rental['box'];
  count: number;
}

export const BoxCard: React.FC<Props> = ({ box, count }) => {
  return (
    <Card>
      <CardActionArea
        component={Link}
        to={generatePath(Routes.BOX, {
          id: box.id,
        })}
      >
        <Box
          sx={{
            padding: '16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <img
            src="/box.svg"
            alt="Box Icon"
            style={{
              width: '40px',
              height: '40px',
              marginRight: '16px',
            }}
          />
          <div>
            <Typography variant="h6">{resolveBoxDisplayName(box)}</Typography>
            <Typography variant="body2" color="textSecondary">
              {`${count} rentals`}
            </Typography>
          </div>
        </Box>
      </CardActionArea>
    </Card>
  );
};
