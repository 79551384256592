export const compare = <T>(a: T, b: T): number => {
  if (a > b) {
    return 1;
  }
  if (b > a) {
    return -1;
  }
  return 0;
};

export const comparator = <T>(a: T, b: T, key: keyof T) => {
  const aNumber = Number(a[key]);
  const bNumber = Number(b[key]);
  if (!isNaN(aNumber) && !isNaN(bNumber)) {
    return compare(aNumber, bNumber);
  }
  const label = a[key];
  const label1 = b[key];
  return compare(label, label1);
};
