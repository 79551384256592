import { Product, ProductRentalType } from '../common/types/product';
import { exhaustSwitchCaseWithFallback } from '../common/utils/exhaustSwitchCase';
import {
  GQL_ProductFragment,
  GQL_ProductRentalType,
} from '../generated/graphql';

export const transformProductRentalType = (
  rentalType: GQL_ProductRentalType
): ProductRentalType => {
  switch (rentalType) {
    case GQL_ProductRentalType.Rent:
      return 'rent';
    case GQL_ProductRentalType.Buy:
      return 'buy';
    default:
      return exhaustSwitchCaseWithFallback(rentalType, 'rent');
  }
};
export const transformProductRentalTypeToGQL = (
  rentalType: ProductRentalType
): GQL_ProductRentalType => {
  switch (rentalType) {
    case 'rent':
      return GQL_ProductRentalType.Rent;
    case 'buy':
      return GQL_ProductRentalType.Buy;
    default:
      return exhaustSwitchCaseWithFallback(
        rentalType,
        GQL_ProductRentalType.Rent
      );
  }
};

export const transformProduct = (product: GQL_ProductFragment): Product => ({
  id: product.id,
  description: product.description ?? undefined,
  imageName: product.imageName ?? undefined,
  name: product.name,
  priceWeight: product.priceWeights,
  rentalType: transformProductRentalType(product.rentalType),
  translationKey: product.translationKey ?? undefined,
});
