import { filterUndefined } from '../../../../common/utils/filterArrays';
import { Filter } from '../types';
import { transformGender } from './genderFilter';

const transformGendersGQLFilter = (filter: Filter | undefined) => {
  const genders =
    filter?.selectedGenders && filter.selectedGenders.length > 0
      ? filter?.selectedGenders?.map((gender) => transformGender(gender.value))
      : undefined;

  return genders ? filterUndefined(genders) : undefined;
};

export const transformFilterToAllRentalsGQLFilter = (
  filter: Filter | undefined
) => ({
  endDate:
    filter?.endDate || filter?.fromDate
      ? {
          after: filter.fromDate,
          before: filter.endDate,
        }
      : undefined,
  genders: transformGendersGQLFilter(filter),
  productIds:
    filter?.selectedProducts && filter.selectedProducts.length > 0
      ? filter.selectedProducts.map((product) => product.value)
      : undefined,
  boxIds:
    filter?.selectedBoxes && filter.selectedBoxes.length > 0
      ? filter.selectedBoxes.map((box) => box.value)
      : undefined,
});
