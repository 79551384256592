import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { useEditPriceStructureMutation } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import { Typography } from '../../webui/Typography';
import {
  EditPriceStructureTable,
  OnPriceStructureFieldChange,
} from './EditPriceStructureTable';
import { PriceStructure as PriceStructureType } from './PriceStructure';

interface EditPriceStructureDrawerProps {
  priceStructure: PriceStructureType;
  open: boolean;
  onClose: () => void;
}

export interface EditPriceStructureForm {
  start: string;
  firstHour: string;
  secondHour: string;
  thirdHour: string;
  fourthHour: string;
  fifthHour: string;
  sixthHour: string;
  penalty: string;
}

const createInitialEditPriceStructureForm = (
  priceStructure: PriceStructureType
): EditPriceStructureForm => ({
  start: String(priceStructure.start),
  firstHour: String(priceStructure.firstHour),
  secondHour: String(priceStructure.secondHour),
  thirdHour: String(priceStructure.thirdHour),
  fourthHour: String(priceStructure.fourthHour),
  fifthHour: String(priceStructure.fifthHour),
  sixthHour: String(priceStructure.sixthHour),
  penalty: String(priceStructure.penalty),
});

// TODO: Form handling, validation, disable save etc
export const EditPriceStructureDrawer: React.FC<EditPriceStructureDrawerProps> = ({
  priceStructure,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditPriceStructureForm(priceStructure), [
      priceStructure,
    ])
  );

  const { showNotification } = useNotification();

  const [editPriceStructure, { loading }] = useEditPriceStructureMutation();

  const onCancel = () => {
    reset();
    onClose();
  };

  const onFieldChange: OnPriceStructureFieldChange = (key, value) => {
    updateField(key, value);
  };

  const onSave = async () => {
    try {
      const result = await editPriceStructure({
        variables: {
          priceStructureId: priceStructure.id,
          editPriceStructureInput: {
            start: parseInt(form.start, 10),
            firstHour: parseInt(form.firstHour, 10),
            secondHour: parseInt(form.secondHour, 10),
            thirdHour: parseInt(form.thirdHour, 10),
            fourthHour: parseInt(form.fourthHour, 10),
            fifthHour: parseInt(form.fifthHour, 10),
            sixthHour: parseInt(form.sixthHour, 10),
            penalty: parseInt(form.penalty, 10),
          },
        },
      });
      showNotification({
        message: 'Price structure edited.',
        severity: 'success',
      });
      console.log(
        'PriceStructure edited!',
        result?.data?.editPriceStructure?.priceStructure
      );
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit price structure.',
        severity: 'error',
      });
      console.log('PriceStructure edit error', e, '--- e');
    }
  };

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit priceStructure</Typography>
        <form noValidate autoComplete="off">
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <EditPriceStructureTable
              priceStructure={form}
              onFieldChange={onFieldChange}
            />
          </Box>
        </form>
        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={loading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
