import {
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {
  SelectChangeEvent as MUISelectChangeEvent,
  SelectInputProps as MUISelectInputProps,
} from '@mui/material/Select/SelectInput';
import React, { ReactNode } from 'react';
import { INPUT_WIDTH } from './utils';

export type SelectChangeEvent<T> = MUISelectChangeEvent<T>;
export type SelectInputProps<T> = MUISelectInputProps<T>;

export const Select = <T extends {}>({
  label,
  ...props
}: MUISelectProps<T>): ReactNode => {
  const labelId = `${props.id}-label`;
  return (
    <FormControl sx={{ minWidth: INPUT_WIDTH }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MUISelect
        label={label}
        placeholder={'Välj'}
        variant={'standard'}
        {...props}
        labelId={labelId}
      />
    </FormControl>
  );
};
