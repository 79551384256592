import React, { useState } from 'react';
import { SearchFilter } from '../../common/filter/SearchFilter';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { AllRentals } from './AllRentals';
import { OngoingRentalsTable } from './OngoingRentalsTable';

interface Props {}

export const AllRentalsWithSearch: React.FC<Props> = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const onSearchChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  return (
    <Box>
      <Paper>
        <OngoingRentalsTable />
      </Paper>
      <Box mb={2} />
      <Paper>
        <Box pl={2} pt={1}>
          <SearchFilter
            label={'Box, product or rented by contains'}
            onSearchChange={onSearchChange}
          />
        </Box>
        <AllRentals searchTerm={searchTerm} />
      </Paper>
    </Box>
  );
};
