import { Rental } from '../types/rental';
import { formatDate, max, min, parseISO } from './dateUtils';

export const getEarliestRentalDate = (
  rentals: Rental[]
): string | undefined => {
  if (rentals.length === 0) return undefined;

  const dates = rentals.map((rental) => parseISO(rental.rentStart));
  const earliestDate = min(dates);

  return formatDate(earliestDate);
};

export const getLatestRentalDate = (rentals: Rental[]): string | undefined => {
  if (rentals.length === 0) return undefined;

  const dates = rentals.map((rental) => parseISO(rental.rentStart));
  const latestDate = max(dates);

  return formatDate(latestDate);
};
