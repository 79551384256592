import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import {
  AllPriceWeightsDocument,
  useAddPriceWeightMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';
import { OnPriceStructureFieldChange } from './EditPriceStructureTable';
import { EditPriceWeightForm } from './EditPriceWeightDrawer';
import { EditPriceWeightTable } from './EditPriceWeightTable';

interface AddPriceWeightProps {}

export const AddPriceWeight: React.FC<AddPriceWeightProps> = () => {
  const memoizedInitialForm = useMemo<EditPriceWeightForm>(
    () => ({
      name: '',
      start: '100',
      firstHour: '100',
      secondHour: '100',
      thirdHour: '100',
      fourthHour: '100',
      fifthHour: '100',
      sixthHour: '100',
      penalty: '100',
    }),
    []
  );
  const { form, reset, updateField } = useForm<EditPriceWeightForm>(
    memoizedInitialForm
  );

  // TODO: error handling
  const [
    addPriceWeight,
    { loading: addPriceWeightLoading },
  ] = useAddPriceWeightMutation();

  const { showNotification } = useNotification();

  const onFieldChange: OnPriceStructureFieldChange = (key, value) => {
    updateField(key, value);
  };
  const onNameChange: TextFieldProps['onChange'] = (val) => {
    updateField('name', val);
  };

  const onSave = async () => {
    try {
      const result = await addPriceWeight({
        variables: {
          priceWeight: {
            name: form.name,
            start: Number(form.start),
            firstHour: Number(form.firstHour),
            secondHour: Number(form.secondHour),
            thirdHour: Number(form.thirdHour),
            fourthHour: Number(form.fourthHour),
            fifthHour: Number(form.fifthHour),
            sixthHour: Number(form.sixthHour),
            penalty: Number(form.penalty),
          },
        },
        refetchQueries: [{ query: AllPriceWeightsDocument }],
      });
      reset();
      showNotification({
        message: 'Price weight added.',
        severity: 'success',
      });
      console.log(
        'Price weight added!',
        result.data?.addPriceWeight.priceWeight
      );
    } catch (e) {
      showNotification({
        message: 'Could not add price weight.',
        severity: 'error',
      });
      console.log('Price weight add error', e, '--- e');
    }
  };

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add price weight</Typography>
      <Box mt={2} display={'flex'} flexDirection={'column'}>
        <TextField label={'Name'} value={form.name} onChange={onNameChange} />
      </Box>
      <Box mt={2}>
        <EditPriceWeightTable
          priceWeight={form}
          onFieldChange={onFieldChange}
        />
      </Box>
      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={addPriceWeightLoading} onClick={onSave} />
      </Box>
    </Box>
  );
};
