import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import {
  Product as ProductType,
  ProductRentalType,
} from '../../common/types/product';
import {
  AllProductsDocument,
  useEditProductMutation,
} from '../../generated/graphql';
import { transformProductRentalTypeToGQL } from '../../gql/transformProduct';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import { SelectInputProps } from '../../webui/inputs/Select';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';
import { PriceWeightSelect } from '../price/PriceWeightSelect';
import { RentalTypeSelect } from './RentalTypeSelect';

interface EditProductDrawerProps {
  product: ProductType;
  open: boolean;
  onClose: () => void;
}

interface EditProductForm {
  description: string;
  imageName: string;
  name: string;
  priceWeight: string;
  rentalType: ProductRentalType;
  translationKey: string;
}

const createInitialEditProductForm = (
  product: ProductType
): EditProductForm => ({
  description: product.description ?? '',
  imageName: product.imageName ?? '',
  name: product.name,
  priceWeight: product.priceWeight.id,
  rentalType: product.rentalType,
  translationKey: product.translationKey ?? '',
});

// TODO: Form handling, validation, disable save etc
export const EditProductDrawer: React.FC<EditProductDrawerProps> = ({
  product,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditProductForm(product), [product])
  );

  const [editProduct, { loading }] = useEditProductMutation();

  const { showNotification } = useNotification();

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };
  const onImageNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('imageName', value);
  };
  const onDescriptionChange: TextFieldProps['onChange'] = (value) => {
    updateField('description', value);
  };
  const onPriceWeightChange: SelectInputProps<string>['onChange'] = (e) => {
    updateField('priceWeight', e.target.value as string);
  };
  const onRentalTypeChange = (value: ProductRentalType) => {
    updateField('rentalType', value);
  };
  const onTranslationKeyChange: TextFieldProps['onChange'] = (value) => {
    updateField('translationKey', value);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editProduct({
        variables: {
          productId: product.id,
          editProduct: {
            description: form.description,
            name: form.name,
            imageName: form.imageName,
            priceWeight: form.priceWeight || undefined,
            rentalType: transformProductRentalTypeToGQL(form.rentalType),
            translationKey: form.translationKey,
          },
        },
        refetchQueries: [{ query: AllProductsDocument }],
      });
      showNotification({
        message: 'Product edited.',
        severity: 'success',
      });
      console.log('Product edited!', result?.data?.editProduct?.product);
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit product.',
        severity: 'error',
      });
      console.log('Product edit error', e, '--- e');
    }
  };

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit product "{product.name}"</Typography>
        <form noValidate autoComplete="off">
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <TextField
              id="edit-product-name"
              label="Name"
              value={form.name}
              onChange={onNameChange}
            />
            <Box mt={2} />
            <TextField
              id="edit-product-description"
              multiline={true}
              label="Description"
              value={form.description}
              onChange={onDescriptionChange}
            />
            <Box mt={2} />
            <TextField
              id="edit-product-imageName"
              multiline={true}
              label="Image name"
              value={form.imageName}
              onChange={onImageNameChange}
            />
            <Box mt={2} />
            <TextField
              id="edit-product-translationKey"
              multiline={true}
              label="Translation key"
              value={form.translationKey}
              onChange={onTranslationKeyChange}
            />
            <Box mt={2} />
            <PriceWeightSelect
              id={'edit-product-priceWeight'}
              onChange={onPriceWeightChange}
              value={form.priceWeight}
            />
            <Box mt={2} />
            <RentalTypeSelect
              id={'edit-product-rentalType'}
              value={form.rentalType}
              onChange={onRentalTypeChange}
            />
          </Box>
        </form>
        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={loading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
