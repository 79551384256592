import * as Apollo from '@apollo/client';
import {
  GQL_AllRolesQuery,
  GQL_AllRolesQueryVariables,
  useAllRolesQuery,
} from '../../../generated/graphql';
import { transformRole } from '../../../gql/transformRole';

export const useAllRoles = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllRolesQuery,
    GQL_AllRolesQueryVariables
  >
) => {
  const { data, ...rest } = useAllRolesQuery(baseOptions);
  return {
    ...rest,
    data: data?.role?.allRoles.map(transformRole) ?? [],
  };
};
