import { ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { Rental } from '../../../common/types/rental';
import { calculatePriceForRentals } from './utils/calculatePriceForRentals';

interface Props {
  rentals: Rental[];
}

export const RentalsPayments: React.FC<Props> = ({ rentals }) => {
  const totalPrice = calculatePriceForRentals(rentals);
  return (
    <>
      <ListItem>
        <ListItemText
          color={'textSecondary'}
          primary={'Total rentals price'}
          secondary={totalPrice}
        />
      </ListItem>
      <ListItem>
        <ListItemText
          color={'textSecondary'}
          primary={'Average rental price'}
          secondary={String(totalPrice / rentals.length)}
        />
      </ListItem>
    </>
  );
};
