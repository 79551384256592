import { FormControlLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel';
import MUISwitch from '@mui/material/Switch';
import React from 'react';

export interface SwitchProps
  extends Pick<FormControlLabelProps, 'label' | 'labelPlacement'> {
  onChange: (value: boolean, event: React.ChangeEvent<{}>) => void;
  value: boolean;
}

export const Switch: React.FC<SwitchProps> = ({
  label,
  labelPlacement,
  onChange,
  value,
}) => {
  const onFormControlChange = (event: React.ChangeEvent<{}>) => {
    onChange(!value, event);
  };

  return (
    <FormControl>
      <FormControlLabel
        sx={{
          marginLeft: 0,
          justifyContent: 'flex-end',
        }}
        value={value}
        control={<MUISwitch checked={value} color="primary" />}
        label={label}
        labelPlacement={labelPlacement}
        onChange={onFormControlChange}
      />
    </FormControl>
  );
};
