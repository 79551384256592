import React from 'react';
import { PaymentInformation } from '../../common/types/paymentInformation';
import { DataList } from '../../webui/DataList';

interface Props {
  paymentInformation: PaymentInformation;
}

export const UserPaymentInformation: React.FC<Props> = ({
  paymentInformation,
}) => {
  const paymentInformationItems: { value?: string; label: string }[] = [
    {
      label: 'Customer id',
      value: paymentInformation.customerId,
    },
    {
      label: 'Card',
      value: paymentInformation.card
        ? `${paymentInformation.card.brand}, ... ${paymentInformation.card.last4}`
        : undefined,
    },
    {
      label: 'Status',
      value: paymentInformation.card
        ? paymentInformation.card.status
        : undefined,
    },
  ];
  return <DataList items={paymentInformationItems} />;
};
