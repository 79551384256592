import { Grid } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllProducts } from './gql/useAllProducts';
import { Product } from './Product';
import { sortProducts } from './utils/sortProducts';

interface Props {}

export const AllProducts: React.FC<Props> = () => {
  const { data: products, loading, error } = useAllProducts();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return (
      <Box mt={4} textAlign={'center'}>
        <Typography color={'error'} variant={'h6'}>
          Error loading products: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth={'lg'}>
      <Box
        p={3}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={4}
      >
        <Typography variant={'h5'} color={'textPrimary'}>
          There are currently {products.length} products available for
          exploration!
        </Typography>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_PRODUCT}>
            Add new product
          </Link>
        </Box>
      </Box>

      <Grid container spacing={2} rowSpacing={6}>
        {sortProducts(products).map((product) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={product.id}>
            <Box
              border={'1px solid #ddd'}
              borderRadius={2}
              height={'100%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-between'}
              padding={2}
            >
              <Product product={product} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
