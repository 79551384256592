import React from 'react';
import { Form } from '../../../common/hooks/useForm';
import { resolveCoordinates } from '../../../common/utils/resolveCoordinates';
import {
  slottsskogenPosition
} from '../../../common/utils/slottsskogenPosition';
import { Box } from '../../../webui/Box';
import { TextField, TextFieldProps } from '../../../webui/inputs/TextField';
import { Typography } from '../../../webui/Typography';
import { EditableMap } from '../map/EditableMap';

interface EditLocationProps {
  form: EditLocationForm;
  updateField: Form<EditLocationForm>['updateField'];
}

export interface EditLocationForm {
  description?: string;
  lat?: string;
  long?: string;
  name?: string;
}

export const EditLocation: React.FC<EditLocationProps> = ({
  form,
  updateField,
}) => {
  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onDescriptionChange: TextFieldProps['onChange'] = (value) => {
    updateField('description', value);
  };

  const onLatChange: TextFieldProps['onChange'] = (value) => {
    updateField('lat', value);
  };

  const onLongChange: TextFieldProps['onChange'] = (value) => {
    updateField('long', value);
  };

  const onPositionChange = (lat: number, long: number) => {
    updateField('lat', String(lat));
    updateField('long', String(long));
  };

  const coordinates = resolveCoordinates(form.lat, form.long);
  return (
    <Box>
      <Typography variant={'h5'}>Location</Typography>
      <Box
        display={'flex'}
        flexWrap={'wrap'}
      >
        <Box mr={1} mt={1}>
          <TextField label={'Name'} onChange={onNameChange} value={form.name} />
        </Box>
        <Box mr={1} mt={1}>
          <TextField
            label={'Description'}
            onChange={onDescriptionChange}
            value={form.description}
          />
        </Box>
        <Box mr={1} mt={1}>
          <TextField
            label={'Latitude'}
            onChange={onLatChange}
            value={form.lat}
          />
        </Box>
        <Box mr={1} mt={1}>
          <TextField
            label={'Longitude'}
            onChange={onLongChange}
            value={form.long}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <EditableMap
          initialMarkerPosition={
            coordinates
              ? [coordinates.lat, coordinates.long]
              : slottsskogenPosition
          }
          onPositionChange={onPositionChange}
        />
      </Box>
    </Box>
  );
};
