import * as Apollo from '@apollo/client';
import {
  GQL_AllOngoingRentalsQuery,
  GQL_AllOngoingRentalsQueryVariables,
  useAllOngoingRentalsQuery,
} from '../../../generated/graphql';
import { transformRental } from '../../../gql/transformRental';

export const useAllOngoingRentals = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllOngoingRentalsQuery,
    GQL_AllOngoingRentalsQueryVariables
  >
) => {
  const { data, ...rest } = useAllOngoingRentalsQuery(baseOptions);
  return {
    ...rest,
    data: data?.rental?.allOngoingRentals.map(transformRental) ?? [],
  };
};
