import * as React from 'react';
import { BoxColor as BoxColorType } from '../../common/types/box';
import { Box } from '../../webui/Box';
import { Typography } from '../../webui/Typography';

interface Props {
  color: BoxColorType;
}

export const BoxColor: React.FC<Props> = ({ color }) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box bgcolor={color} height={16} mr={1} width={16} borderRadius={4} />
      <Typography variant={'caption'}>{'(' + color + ')'}</Typography>
    </Box>
  );
};
