import React from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { AddActivityArea } from '../activity-area/AddActivityArea';
import { AddBox } from '../box/AddBox';
import { AddOrganisation } from '../organisation/AddOrganisation';
import { AddPendingUser } from '../pending-user/AddPendingUser';
import { AddPriceWeight } from '../price/AddPriceWeight';
import { AddProduct } from '../product/AddProduct';
import { AddRole } from '../role/AddRole';
import { AddSponsor } from '../sponsor/AddSponsor';

interface AddPageProps {}

export const AddPage: React.FC<AddPageProps> = () => {
  return (
    <Container>
      <Box m={2} display={'flex'}>
        <Link component={RouterLink} to={Routes.ADD_BOX}>
          New box
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.ADD_PRODUCT}>
          New product
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.ADD_ACTIVITY_AREA}>
          New activity area
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.ADD_SPONSOR}>
          New sponsor
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.ADD_ROLE}>
          New role
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.ADD_ORGANISATION}>
          New organisation
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.ADD_PENDING_USER}>
          New pending user
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.ADD_PRICE_WEIGHT}>
          New price weight
        </Link>
      </Box>
      <Switch>
        <Route path={Routes.ADD_BOX}>
          <AddBox />
        </Route>
        <Route path={Routes.ADD_ACTIVITY_AREA}>
          <AddActivityArea />
        </Route>
        <Route path={Routes.ADD_PRODUCT}>
          <AddProduct />
        </Route>
        <Route path={Routes.ADD_ROLE}>
          <AddRole />
        </Route>
        <Route path={Routes.ADD_SPONSOR}>
          <AddSponsor />
        </Route>
        <Route path={Routes.ADD_ORGANISATION}>
          <AddOrganisation />
        </Route>
        <Route path={Routes.ADD_PENDING_USER}>
          <AddPendingUser />
        </Route>
        <Route path={Routes.ADD_PRICE_WEIGHT}>
          <AddPriceWeight />
        </Route>
        <Route path="/">
          <Box display={'flex'}>
            <AddBox />
            <Box ml={2} />
            <AddProduct />
          </Box>
        </Route>
      </Switch>
    </Container>
  );
};
