import {
  PaymentChargeFailStatus,
  PaymentChargeInformationFail,
  Rental,
} from '../../../common/types/rental';

export interface UnpaidRentalData {
  id: string;
  amount: PaymentChargeInformationFail['amount'];
  code: PaymentChargeInformationFail['code'];
  currency: PaymentChargeInformationFail['currency'];
  rentEnd: Rental['rentEnd'];
  status: PaymentChargeFailStatus;
  rentalId: string;
}

export const createUnpaidRentalsData = (
  rentals: Rental[]
): UnpaidRentalData[] => {
  const unpaidRentals: UnpaidRentalData[] = [];
  rentals.forEach((rental) => {
    if (rental.paymentChargeInformation?.type === 'fail') {
      unpaidRentals.push({
        id: rental.id,
        amount: rental.paymentChargeInformation.amount,
        code: rental.paymentChargeInformation.code,
        currency: rental.paymentChargeInformation.currency,
        rentEnd: rental.rentEnd,
        status: rental.paymentChargeInformation.status,
        rentalId: rental.id,
      });
    }
  });
  return unpaidRentals;
};
