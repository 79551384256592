import * as React from 'react';
import { useAllRentals } from '../../rentals/gql/useAllRentals';
import { Filter } from '../filter/types';
import { transformFilterToAllRentalsGQLFilter } from '../filter/utils/transformFilterToAllRentalsGQLFilter';
import { RentalsTableLite } from './RentalsTableLite';

interface Props {
  filter: Filter;
}

export const RentalFeedbacksTable: React.FC<Props> = ({ filter }) => {
  const {
    data: { rentals },
    loading,
    error,
  } = useAllRentals({
    variables: {
      pagination: {
        limit: 50,
        offset: 0,
      },
      filter: {
        ...transformFilterToAllRentalsGQLFilter(filter),
        hasTextFeedback: true,
      },
    },
  });
  return (
    <RentalsTableLite
      error={error}
      loading={loading}
      rentals={rentals}
      searchEnabled={false}
      title={'Hyror med feedback'}
    />
  );
};
