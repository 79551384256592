import FormControl from '@mui/material/FormControl';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import MUITextField from '@mui/material/TextField';
import { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField/TextField';
import React from 'react';
import { INPUT_WIDTH } from './utils';

export interface TextFieldProps extends Omit<MUITextFieldProps, 'onChange'> {
  onChange?: (
    value: string,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  width?: number;
}

export const TextField: React.FC<TextFieldProps> = (props) => {
  const onTextFieldChange: StandardInputProps['onChange'] = (e) => {
    if (props.onChange) {
      props.onChange(e.target.value, e);
    }
  };

  return (
    <FormControl
      sx={{
        maxWidth: props.width ?? INPUT_WIDTH,
        minWidth: props.width ?? INPUT_WIDTH,
      }}
    >
      <MUITextField
        variant={'standard'}
        {...props}
        onChange={onTextFieldChange}
      />
    </FormControl>
  );
};
