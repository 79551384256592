import { Sponsor } from '../../../common/types/sponsor';
import { sortWith } from '../../../common/utils/sortWith';

const nameComparator = (a: Sponsor, b: Sponsor): number => {
  if (a.name === b.name) {
    return 0;
  }
  return a.name > b.name ? 1 : -1;
};

export const sortSponsors = (sponsors: Sponsor[]): Sponsor[] => {
  return sortWith(nameComparator)(sponsors);
};
