import CalculateIcon from '@mui/icons-material/Calculate';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { displayAmountWithCurrency } from '../../common/utils/displayAmountWithCurrency';

export const RevenueCalculator: React.FC = () => {
  const { t } = useTranslation();

  const [userFee, setUserFee] = useState<number>(0);
  const [usersPerDay, setUsersPerDay] = useState<number>(0);
  const [sharePerDay, setSharePerDay] = useState<number>(0);
  const [activeDaysPerYear, setActiveDaysPerYear] = useState<number>(0);
  const [pifflShare, setPifflShare] = useState<number>(0);
  const [pifflStartFee, setPifflStartFee] = useState<number>(0);

  const [showSubtle, setShowSubtle] = useState<boolean>(false);

  const pifflUsersPerDay = usersPerDay * (sharePerDay / 100);
  const userRevenuePerDay = userFee * pifflUsersPerDay;
  const userRevenuePerYear = Math.round(userRevenuePerDay * activeDaysPerYear);
  const pifflareOverActiveDays = Math.floor(
    pifflUsersPerDay * activeDaysPerYear
  );
  const pifflRevenuePerDay =
    pifflUsersPerDay * pifflStartFee +
    (userFee - pifflStartFee) * pifflUsersPerDay * (pifflShare / 100);
  const customerRevenuePerDay = userRevenuePerDay - pifflRevenuePerDay;
  const pifflRevenuePerYear = Math.round(
    pifflRevenuePerDay * activeDaysPerYear
  );

  const customerRevenuePerYear = Math.round(
    customerRevenuePerDay * activeDaysPerYear
  );

  const isPifflStartFeeError = pifflStartFee > userFee;

  return (
    <Box sx={{ maxWidth: 600, margin: '2rem auto', padding: 4 }}>
      <Card variant="outlined" sx={{ padding: 3 }}>
        <CardContent>
          <Typography
            variant="h4"
            gutterBottom
            textAlign="center"
            sx={{ marginBottom: 2 }}
          >
            {t('revenue_calculator.title')}
          </Typography>

          <Grid container spacing={3}>
            {/* User Inputs */}
            <Grid item xs={12} md={6}>
              <TextField
                label={t('revenue_calculator.userFeePerHour')}
                type="number"
                value={userFee}
                onChange={(e) => setUserFee(parseFloat(e.target.value))}
                fullWidth
                margin="normal"
              />

              <TextField
                label={t('revenue_calculator.usersPerDay')}
                type="number"
                value={usersPerDay}
                onChange={(e) => setUsersPerDay(parseFloat(e.target.value))}
                fullWidth
                margin="normal"
              />

              <TextField
                label={t('revenue_calculator.sharePerDay')}
                type="number"
                value={sharePerDay}
                onChange={(e) => setSharePerDay(parseFloat(e.target.value))}
                fullWidth
                margin="normal"
              />

              <TextField
                label={t('revenue_calculator.activeDaysPerYear')}
                type="number"
                value={activeDaysPerYear}
                onChange={(e) =>
                  setActiveDaysPerYear(parseFloat(e.target.value))
                }
                fullWidth
                margin="normal"
              />

              <TextField
                label={t('revenue_calculator.pifflShare')}
                type="number"
                value={pifflShare}
                onChange={(e) => setPifflShare(parseFloat(e.target.value))}
                fullWidth
                margin="normal"
              />

              <TextField
                label={t('revenue_calculator.pifflStartFee')}
                type="number"
                value={pifflStartFee}
                onChange={(e) => setPifflStartFee(parseFloat(e.target.value))}
                fullWidth
                margin="normal"
                error={isPifflStartFeeError}
                helperText={
                  isPifflStartFeeError
                    ? t(
                        'revenue_calculator.pifflStartFeeError',
                        'Piffl start fee cannot be greater than user fee'
                      )
                    : ''
                }
              />
            </Grid>

            {/* Calculations and Results */}
            <Grid item xs={12} md={6}>
              {/* Highlighted Pifflare Section */}
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  backgroundColor: '#f0f8ff',
                  marginBottom: 2,
                  marginTop: 2,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <GroupIcon color="primary" sx={{ marginRight: 1 }} />
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t('revenue_calculator.pifflare')}: {pifflareOverActiveDays}
                  </Typography>
                </Box>
              </Paper>

              <Divider sx={{ marginBottom: 2 }} />

              {/* Highlighted Customer Revenue per Year Section */}
              <Paper
                elevation={3}
                sx={{ padding: 2, backgroundColor: '#e6ffe6', marginBottom: 2 }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t('revenue_calculator.customerRevenuePerYear')}:{' '}
                    <strong>
                      {displayAmountWithCurrency(customerRevenuePerYear)}
                    </strong>
                  </Typography>
                </Box>
              </Paper>

              {/* Toggle Button */}
              <Button
                onClick={() => setShowSubtle(!showSubtle)}
                variant="text"
                endIcon={showSubtle ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                sx={{ marginBottom: 2 }}
              >
                {showSubtle
                  ? t('revenue_calculator.showLess')
                  : t('revenue_calculator.showMore')}
              </Button>

              {/* Subtle Section */}
              <Collapse in={showSubtle}>
                {/* Subtle Results Section */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    opacity: 0.7,
                  }}
                >
                  <Typography variant="body1">
                    {t('revenue_calculator.pifflUsersPerDay')}:{' '}
                    <strong>{Math.round(pifflUsersPerDay)}</strong>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    opacity: 0.7,
                  }}
                >
                  <Typography variant="body1">
                    {t('revenue_calculator.userRevenuePerDay')}:{' '}
                    <strong>
                      {displayAmountWithCurrency(userRevenuePerDay)}
                    </strong>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    opacity: 0.7,
                  }}
                >
                  <Typography variant="body1">
                    {t('revenue_calculator.pifflRevenuePerDay')}:{' '}
                    <strong>
                      {displayAmountWithCurrency(pifflRevenuePerDay)}
                    </strong>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    opacity: 0.7,
                  }}
                >
                  <Typography variant="body1">
                    {t('revenue_calculator.customerRevenuePerDay')}:{' '}
                    <strong>
                      {displayAmountWithCurrency(customerRevenuePerDay)}
                    </strong>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    opacity: 0.7,
                  }}
                >
                  <Typography variant="body1">
                    {t('revenue_calculator.userRevenuePerYear')}:{' '}
                    <strong>
                      {displayAmountWithCurrency(userRevenuePerYear)}
                    </strong>
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1,
                    opacity: 0.7,
                  }}
                >
                  <Typography variant="body1">
                    {t('revenue_calculator.pifflRevenuePerYear')}:{' '}
                    <strong>
                      {displayAmountWithCurrency(pifflRevenuePerYear)}
                    </strong>
                  </Typography>
                </Box>
              </Collapse>

              {/* Calculate Button */}
              <Button
                variant="contained"
                color="primary"
                startIcon={<CalculateIcon />}
                fullWidth
                sx={{ marginTop: 3 }}
                onClick={() => alert('Calculations updated!')}
              >
                {t('revenue_calculator.title')}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
