import * as Apollo from '@apollo/client';
import { sortWithName } from '../../../common/utils/sort/comparators/nameComparator';
import {
  GQL_AllOrganisationsQuery,
  GQL_AllOrganisationsQueryVariables,
  useAllOrganisationsQuery,
} from '../../../generated/graphql';
import { transformOrganisation } from '../../../gql/transformOrganisation';

export const useAllOrganisations = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllOrganisationsQuery,
    GQL_AllOrganisationsQueryVariables
  >
) => {
  const { data, ...rest } = useAllOrganisationsQuery(baseOptions);
  const allOrganisations = data?.organisation?.allOrganisations;
  return {
    ...rest,
    data: allOrganisations
      ? sortWithName(allOrganisations.map(transformOrganisation))
      : [],
  };
};
