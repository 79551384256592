import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Rental } from '../../../common/types/rental';
import { colors } from '../../../common/utils/colors';
import { formatDate } from '../../../common/utils/dateUtils';
import {
  getEarliestRentalDate,
  getLatestRentalDate,
} from '../../../common/utils/rentalUtils';
import { generateMeanRentalsPerBoxPerDayData } from './utils/generateMeanRentalsPerBoxPerDayData';
import { getUniqueBoxDisplayNames } from './utils/getUniqueBoxDisplayNames';

interface Props {
  endDate?: string;
  fromDate?: string;
  rentals: Rental[];
  rollingAverage: number;
}

export const MeanRentalsPerBoxPerDay: React.FC<Props> = ({
  fromDate,
  endDate,
  rentals,
  rollingAverage,
}) => {
  const uniqueBoxes = getUniqueBoxDisplayNames(rentals);

  const data = generateMeanRentalsPerBoxPerDayData(
    rentals,
    fromDate || getEarliestRentalDate(rentals) || formatDate(new Date()),
    endDate || getLatestRentalDate(rentals) || formatDate(new Date()),
    rollingAverage
  );

  return (
    <LineChart
      width={1000}
      height={400}
      data={data}
      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
    >
      <XAxis dataKey={(key) => key.date} />
      <YAxis />
      <Tooltip />
      <Legend
        layout={'vertical'}
        align={'right'}
        verticalAlign={'top'}
        wrapperStyle={{
          overflow: 'scroll',
          height: 300,
        }}
      />
      <CartesianGrid stroke="#f5f5f5" />
      {uniqueBoxes.map((boxName, index) => {
        return (
          <Line
            key={boxName}
            connectNulls={true}
            type="monotone"
            dataKey={boxName}
            stroke={colors[index % colors.length]}
          />
        );
      })}
    </LineChart>
  );
};
