import { Rental } from '../../../../common/types/rental';

export interface RatingData {
  averageRating: number;
  numberOfRentals: number;
  numberOfRatings: number;
  values: {
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  };
}

export const generateRatingsData = (rentals: Rental[]): RatingData => {
  const rentalsWithRating = rentals.filter((rental) =>
    Boolean(rental.review?.rating)
  );

  let totalRatingValue = 0;

  const values = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  };

  for (const rental of rentalsWithRating) {
    if (rental.review?.rating === 1) {
      values[1] = values[1] + 1;
    }
    if (rental.review?.rating === 2) {
      values[2] = values[2] + 1;
    }
    if (rental.review?.rating === 3) {
      values[3] = values[3] + 1;
    }
    if (rental.review?.rating === 4) {
      values[4] = values[4] + 1;
    }
    if (rental.review?.rating === 5) {
      values[5] = values[5] + 1;
    }
    if (rental.review?.rating) {
      totalRatingValue = totalRatingValue + rental.review.rating;
    }
  }
  return {
    averageRating:
      totalRatingValue > 0
        ? Math.round((totalRatingValue / rentalsWithRating.length) * 100) / 100
        : 0,
    numberOfRatings: rentalsWithRating.length,
    numberOfRentals: rentals.length,
    values: values,
  };
};
