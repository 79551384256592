import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuth } from 'firebase/auth';
import React from 'react';
import { paginatedField } from './apollo/helper/paginatedField';
import fragmentMatcher from './generated/fragmentMatcher';
import {
  GQL_AllRentalsResponse,
  GQL_AllUsersResponse,
  GQL_Rental,
  GQL_User,
} from './generated/graphql';

interface Props {}

const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies: {
    ActivityAreaSearchRoot: { merge: true },
    BatteryReadingRoot: { merge: true },
    BoxSearchRoot: { merge: true },
    CompartmentSearchRoot: { merge: true },
    FeedbackSearchRoot: { merge: true },
    PaymentSearchRoot: { merge: true },
    PendingUserSearchRoot: { merge: true },
    PermissionSearchRoot: { merge: true },
    ProductSearchRoot: { merge: true },
    OrganisationSearchRoot: { merge: true },
    RentalSearchRoot: {
      merge: true,
      fields: {
        allRentals: paginatedField<GQL_AllRentalsResponse, GQL_Rental>([
          'filter',
          'search',
        ]),
      },
    },
    ReviewSearchRoot: { merge: true },
    RoleSearchRoot: { merge: true },
    SponsorSearchRoot: { merge: true },
    UserSearchRoot: {
      merge: true,
      fields: {
        allUsers: paginatedField<GQL_AllUsersResponse, GQL_User>([
          'filter',
          'search',
        ]),
      },
    },
    ViewerSearchRoot: { merge: true },
    Location: {
      keyFields: false,
    },
    PaymentChargeInformation: {
      keyFields: false,
    },
  },
});

const apolloURI = process.env.REACT_APP_GRAPHQL_ENDPOINT || '/graphql';

if (!process.env.REACT_APP_GRAPHQL_ENDPOINT) {
  console.log('No graphql endpoint was provided');
}

export const AuthorizedApolloProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children }) => {
  const httpLink = createHttpLink({
    uri: apolloURI,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAuth().currentUser?.getIdToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
    // connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
