import { Rental } from '../../../common/types/rental';

export interface NumberOfTimesUserRentsData {
  label: string;
  count: number;
}

export const getRentalsPerUser = (rentals: Rental[]): [string, number][] =>
  Object.entries<number>(
    rentals.reduce<Record<string, number>>((acc, rental) => {
      const rentBy = rental.rentBy.id;
      if (acc[rentBy]) {
        acc[rentBy] = acc[rentBy] + 1;
      } else {
        acc[rentBy] = 1;
      }
      return acc;
    }, {})
  );

export const generateNumberOfTimesUserRentsData = (
  rentals: Rental[]
): NumberOfTimesUserRentsData[] => {
  const usersWithCount = getRentalsPerUser(rentals);
  return Object.entries<number>(
    usersWithCount.reduce<Record<string, number>>((acc, [user, count]) => {
      if (acc[count]) {
        acc[count] = acc[count] + 1;
      } else {
        acc[count] = 1;
      }
      return acc;
    }, {})
  ).map(([key, value]) => {
    return {
      label: key + (parseInt(key, 10) > 1 ? ' rentals' : ' rental'),
      count: Math.round((value / usersWithCount.length) * 100),
    };
  });
};
