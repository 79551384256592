import { BatteryReading } from '../../../common/types/batteryReading';
import { createdAtComparator } from '../../../common/utils/sort/comparators/createdAtComparator';

export type BatteryReadingsPerBox = Record<string, BatteryReading[]>;
export const getBatteryReadingsPerBox = (
  batteryReadings: BatteryReading[]
): BatteryReadingsPerBox => {
  const batteryReadingsPerBox: BatteryReadingsPerBox = {};
  for (const reading of batteryReadings) {
    if (!batteryReadingsPerBox[reading.box.id]) {
      batteryReadingsPerBox[reading.box.id] = [];
    }

    batteryReadingsPerBox[reading.box.id].push(reading);
  }

  for (const readings of Object.values(batteryReadingsPerBox)) {
    readings.sort(createdAtComparator);
  }

  return batteryReadingsPerBox;
};
