import { PendingUser } from '../common/types/PendingUser';
import { GQL_PendingUserFragment } from '../generated/graphql';
import { transformOrganisation } from './transformOrganisation';
import { transformRole } from './transformRole';

export const transformPendingUser = (
  pendingUser: GQL_PendingUserFragment
): PendingUser => ({
  id: pendingUser.id,
  email: pendingUser.email,
  roles: pendingUser.roles.map(transformRole),
  organisations: pendingUser.organisations.map(transformOrganisation),
});
