import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthenticationProvider } from './AuthenticationProvider';
import { AuthorizedApolloProvider } from './AuthorizedApolloProvider';
import { store } from './store';
import { theme, ThemeProvider } from './webui/theme';

interface AppWithThemeProps {}

export const AppWithProviders: React.FC<AppWithThemeProps> = () => (
  <ReduxProvider store={store}>
    <AuthenticationProvider>
      <AuthorizedApolloProvider>
        <Router>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Router>
      </AuthorizedApolloProvider>
    </AuthenticationProvider>
  </ReduxProvider>
);
