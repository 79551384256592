import { RatingQuestionAnswer, Review } from '../common/types/review';
import {
  GQL_RatingQuestionAnswerFragment,
  GQL_ReviewFragment,
} from '../generated/graphql';

const transformRatingQuestion = (
  ratingQuestionAnswer: GQL_RatingQuestionAnswerFragment
): RatingQuestionAnswer => ({
  rating: ratingQuestionAnswer.rating ?? undefined,
  question: {
    id: ratingQuestionAnswer.question.id,
    eng: ratingQuestionAnswer.question.eng,
    swe: ratingQuestionAnswer.question.swe ?? undefined,
  },
});
export const transformReview = (review: GQL_ReviewFragment): Review => ({
  id: review.id,
  freeText: review.freeText ?? undefined,
  rating: review.rating ?? undefined,
  ratingQuestionAnswers: review.ratingQuestionAnswers.map(
    transformRatingQuestion
  ),
});
