import { PriceWeight } from './PriceWeight';

export const defaultPriceWeightId = 'default_price_weight_id';
export const freePriceWeightId = 'free_price_weight_id';

export const defaultPriceWeight: PriceWeight = {
  id: defaultPriceWeightId,
  name: 'Default',
  start: 100,
  firstHour: 100,
  secondHour: 100,
  thirdHour: 100,
  fourthHour: 100,
  fifthHour: 100,
  sixthHour: 100,
  penalty: 100,
};
