import { BoxLite } from '../../common/types/box';
import { formatDate, subDays } from '../../common/utils/dateUtils';

export const DASHBOARD_DAYS_BACK = 14;
export const dashboardInitialFromDate = formatDate(
  subDays(new Date(), DASHBOARD_DAYS_BACK)
);

export const getUniqueProductsFromBoxes = (boxes: BoxLite[]): string[] => {
  const uniqueProducts = new Set<string>();

  boxes.forEach((box) => {
    box.compartments.forEach((compartment) => {
      if (compartment.product) {
        uniqueProducts.add(compartment.product.id);
      }
    });
  });

  return Array.from(uniqueProducts);
};
