import { Rental } from '../../../../common/types/rental';
import { generateNumberOfRentalsPerGenderMap } from './generateNumberOfRentalsPerGenderMap';

export interface RentalsPerGenderPieData {
  gender: string;
  count: number;
  share: number;
}

export const generateRentalsPerGenderPieData = (
  rentals: Rental[]
): RentalsPerGenderPieData[] =>
  Object.entries(generateNumberOfRentalsPerGenderMap(rentals)).map(
    ([key, value]) => ({
      gender: key,
      count: value,
      share: Math.round((value / rentals.length) * 100),
    })
  );
