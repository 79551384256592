import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { PendingUser } from '../../common/types/PendingUser';
import {
  AllRolesDocument,
  useEditPendingUserMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllOrganisations } from '../organisation/gql/useAllOrganisations';
import { useAllRoles } from '../role/gql/useAllRoles';

interface EditPendingUserDrawerProps {
  pendingUser: PendingUser;
  open: boolean;
  onClose: () => void;
}

interface EditPendingUserForm {
  email: string;
  roles: Option[];
  organisations: Option[];
}

const createInitialEditPendingUserForm = (
  pendingUser: PendingUser
): EditPendingUserForm => ({
  email: pendingUser.email,
  roles: pendingUser.roles.map((role) => ({
    label: role.name,
    value: role.id,
  })),
  organisations: pendingUser.organisations.map((organisation) => ({
    label: organisation.name,
    value: organisation.id,
  })),
});

export const EditPendingUserDrawer: React.FC<EditPendingUserDrawerProps> = ({
  pendingUser,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditPendingUserForm(pendingUser), [pendingUser])
  );

  const [
    editPendingUser,
    { loading: editPendingUserLoading },
  ] = useEditPendingUserMutation();

  const {
    data: allOrganisations,
    loading: organisationsLoading,
  } = useAllOrganisations();
  const { data: allRoles, loading: rolesLoading } = useAllRoles();

  const { showNotification } = useNotification();

  const onEmailChange: TextFieldProps['onChange'] = (value) => {
    updateField('email', value);
  };

  const onOrganisationsChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('organisations', value.slice());
  };

  const onRolesChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('roles', value.slice());
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editPendingUser({
        variables: {
          pendingUserId: pendingUser.id,
          pendingUser: {
            email: form.email,
            organisations: form.organisations.map((p) => p.value),
            roles: form.roles.map((p) => p.value),
          },
        },
        refetchQueries: [{ query: AllRolesDocument }],
      });
      showNotification({
        message: 'Pending user edited.',
        severity: 'success',
      });
      console.log(
        'Pending user edited!',
        result?.data?.editPendingUser.pendingUser
      );
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit pending user.',
        severity: 'error',
      });
      console.log('Pending user edit error', e, '--- e');
    }
  };

  if (organisationsLoading || rolesLoading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">
          Edit pending user "{pendingUser.email}"
        </Typography>
        <form noValidate autoComplete="off">
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <TextField
              id="edit-pending-user-email"
              label="Email"
              value={form.email}
              onChange={onEmailChange}
            />
            <Box mt={2} />
            <MultiSearchSelect
              closeMenuOnSelect={false}
              onChange={onRolesChange}
              options={allRoles.map((role) => ({
                value: role.id,
                label: role.name,
              }))}
              value={form.roles}
            />
            <Box mt={2} />
            <MultiSearchSelect
              closeMenuOnSelect={false}
              onChange={onOrganisationsChange}
              options={allOrganisations.map((organisation) => ({
                value: organisation.id,
                label: organisation.name,
              }))}
              value={form.organisations}
            />
          </Box>
        </form>
        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={editPendingUserLoading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
