import { ListItem, ListItemText } from '@mui/material';
import React from 'react';
import { Rental } from '../../../common/types/rental';
import { Box } from '../../../webui/Box';
import { RatingItem } from './RatingItem';
import { generateRatingsData, RatingData } from './utils/generateRatingsData';

interface Props {
  rentals: Rental[];
}

const values: (keyof RatingData['values'])[] = [5, 4, 3, 2, 1];

export const Ratings: React.FC<Props> = ({ rentals }) => {
  const ratingsData = generateRatingsData(rentals);
  return (
    <div>
      <ListItem>
        <ListItemText
          color={'textSecondary'}
          primary={'Ratings and rentals'}
          secondary={
            ratingsData.numberOfRatings +
            ' ratings/' +
            ratingsData.numberOfRentals +
            ' rentals, ' +
            Math.floor(
              (ratingsData.numberOfRatings / ratingsData.numberOfRentals) * 100
            ) +
            '%'
          }
        />
      </ListItem>
      <ListItem>
        <ListItemText
          color={'textSecondary'}
          primary={'Average rating'}
          secondary={ratingsData.averageRating}
        />
      </ListItem>
      <Box pl={2} pb={2}>
        {values.map((value) => (
          <RatingItem
            key={String(value)}
            amount={ratingsData.values[value]}
            numberOfRatings={ratingsData.numberOfRatings}
            value={value}
          />
        ))}
      </Box>
    </div>
  );
};
