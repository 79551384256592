import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Feedback } from '../../common/types/feedback';
import { User } from '../../common/types/user';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';

interface Props {
  allFeedbacks: Feedback[];
}

export interface FeedbackData {
  id: string;
  freeText: string;
  date: string;
  user?: User;
}

const feedbackHeadCells: TableHeadCell<FeedbackData>[] = [
  {
    id: 'date',
    label: 'Date',
    render: (value) => toDisplayDateTime(value['date']),
  },
  {
    id: 'freeText',
    label: 'Free text',
    render: (value) => value.freeText,
  },
  {
    id: 'user',
    label: 'User',
    render: (value) =>
      value['user'] ? (
        <Link
          component={RouterLink}
          to={generatePath(Routes.USER, {
            id: value['user'].id,
          })}
        >
          {value['user'].email || 'Anonymized user'}
        </Link>
      ) : (
        ''
      ),
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (feedbacks: Feedback[]): FeedbackData[] => {
  return feedbacks.map((feedback) => {
    return {
      id: feedback.id,
      freeText: feedback.freeText,
      date: feedback.date,
      user: feedback.user,
    };
  });
};

const feedbackComparator: Comparator<FeedbackData> = (order, orderBy) => {
  return getComparator(order, orderBy);
};

export const AllFeedbacksTable: React.FC<Props> = ({ allFeedbacks }) => {
  return (
    <SortablePaginatedTable
      getComparator={feedbackComparator}
      title={'General feedback'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'date'}
      initialOrder={'desc'}
      rows={createRows(allFeedbacks)}
      tableHeadCells={feedbackHeadCells}
    />
  );
};
