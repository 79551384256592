import { compareDate, isValidDate } from '../../../common/utils/dateUtils';
import { sortWith } from '../../../common/utils/sortWith';

interface RentalWithEnd {
  rentEnd?: string;
}

const dateComparator = <T extends RentalWithEnd>(a: T, b: T): number => {
  return compareDate(a.rentEnd, b.rentEnd, 'desc');
};

export const getLatestRental = <T extends RentalWithEnd>(
  rentals: T[]
): T | undefined => {
  if (rentals.length === 0) {
    return undefined;
  }
  return sortWith(dateComparator)(
    rentals.filter((rental) => isValidDate(rental.rentEnd))
  )[0];
};
