import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React, { useState } from 'react';
import { Box } from './Box';
import { Button } from './Button';
import { Drawer } from './Drawer';
import { TextField, TextFieldProps } from './inputs/TextField';
import { LoadingSpinnerContainer } from './progress/LoadingSpinnerContainer';

interface LoginDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const LoginDrawer: React.FC<LoginDrawerProps> = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeEmail: TextFieldProps['onChange'] = (value) => {
    setEmail(value);
  };

  const onChangePassword: TextFieldProps['onChange'] = (value) => {
    setPassword(value);
  };

  const onLogin = async () => {
    setLoading(true);
    if (email.endsWith('@openplaybox.com') || email.endsWith('@piffl.se')) {
      try {
        await signInWithEmailAndPassword(getAuth(), email, password);
        setEmail('');
        setPassword('');
        setErrorMessage('');
        onClose();
      } catch (e) {
        setErrorMessage(
          'Something went wrong. Try to log in again or use another email.'
        );
        await signOut(getAuth());
      }
    } else {
      setErrorMessage("You're not allowed to login");
    }
    setLoading(false);
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Box m={2} display={'flex'} flexDirection={'column'}>
        <TextField
          id="login-email"
          label="Email"
          value={email}
          onChange={onChangeEmail}
          helperText={errorMessage}
          error={Boolean(errorMessage)}
        />
        <TextField
          id="login-password"
          label="Password"
          value={password}
          onChange={onChangePassword}
          type={'password'}
        />
        {loading ? (
          <LoadingSpinnerContainer />
        ) : (
          <Button sx={{ marginTop: 16 }} onClick={onLogin}>
            Login
          </Button>
        )}
      </Box>
    </Drawer>
  );
};
