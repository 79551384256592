import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import sv from 'date-fns/locale/sv';
import React, { useState } from 'react';
import { useForm } from '../../../common/hooks/useForm';
import { useNotification } from '../../../common/notifications/useNotification';
import { ProductItem } from '../../../common/product/ProductItem';
import { BoxWithoutRentals } from '../../../common/types/box';
import { Organisation } from '../../../common/types/organisation';
import { Product } from '../../../common/types/product';
import {
  formatDate,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from '../../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { Accordion } from '../../../webui/Accordion';
import { AccordionDetails } from '../../../webui/AccordionDetails';
import { AccordionSummary } from '../../../webui/AccordionSummary';
import { Box } from '../../../webui/Box';
import { Button } from '../../../webui/Button';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../../webui/inputs/CheckboxWithLabel';
import { MenuItem } from '../../../webui/inputs/MenuItem';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  SearchSelect,
  SearchSelectProps,
} from '../../../webui/inputs/SearchSelect';
import { Select, SelectChangeEvent } from '../../../webui/inputs/Select';
import { INPUT_WIDTH } from '../../../webui/inputs/utils';
import { Typography } from '../../../webui/Typography';
import { sortBoxes } from '../../box/utils/sortBoxes';
import { useTranslatedProduct } from '../../product/utils/useTranslatedProduct';
import { Filter } from './types';
import { genderOptions } from './utils/genderFilter';
import { getFilteredBoxesWithOrganisationBoxes } from './utils/getFilteredBoxesWithOrganisationBoxes';

interface Props {
  allBoxes: BoxWithoutRentals[];
  allOrganisations: Organisation[];
  allProducts: Product[];
  initialFilter: Filter;
  onFilterChange: (filter: Filter) => void;
}

type ProductOption = {
  label: string;
  value: string;
  product: Product;
};

export const StatisticsRentalFilter: React.FC<Props> = ({
  allBoxes,
  allOrganisations,
  allProducts,
  initialFilter,
  onFilterChange,
}) => {
  const { showNotification } = useNotification();
  const [filterHasChanged, setFilterHasChanged] = useState<boolean>(false);
  const { form, updateField } = useForm<Filter>(initialFilter);
  const [selectedRange, setSelectedRange] = useState<string>('');
  const { tName } = useTranslatedProduct();

  const onApplyFilter = () => {
    onFilterChange(form);
    setFilterHasChanged(false);
    showNotification({
      message: 'Filter applied',
      severity: 'success',
    });
  };

  const onBoxesChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('selectedBoxes', value.slice());
    setFilterHasChanged(true);
  };

  const onOnlyActiveBoxesChange: CheckboxWithLabelProps['onChange'] = (
    value
  ) => {
    updateField('onlyActiveBoxes', value);
    setFilterHasChanged(true);
  };

  const onProductsChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('selectedProducts', value.slice());
    setFilterHasChanged(true);
  };

  const onFromDateChange = (value: Date | null) => {
    updateField('fromDate', value ? formatDate(value) : '');
    setFilterHasChanged(true);
  };

  const onEndDateChange = (value: Date | null) => {
    updateField('endDate', value ? formatDate(value) : '');
    setFilterHasChanged(true);
  };

  const onGenderChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('selectedGenders', value.slice());
    setFilterHasChanged(true);
  };

  const onOrganisationChange: SearchSelectProps['onChange'] = (value) => {
    if (value) {
      const organisation = allOrganisations.find(
        (org) => org.id === value.value
      );
      if (organisation) {
        updateField(
          'selectedBoxes',
          getFilteredBoxesWithOrganisationBoxes(
            form.selectedBoxes,
            organisation
          )
        );
      }
    }
    setFilterHasChanged(true);
  };

  const handleDateRangeChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    setSelectedRange(selectedValue);

    let fromDate = new Date();
    switch (selectedValue) {
      case 'lastWeek':
        fromDate = subDays(new Date(), 7);
        break;
      case 'lastMonth':
        fromDate = subDays(new Date(), 30);
        break;
      case 'lastThreeMonths':
        fromDate = subMonths(new Date(), 3);
        break;
      case 'thisYear':
        fromDate = startOfYear(new Date());
        break;
      case 'oneYearBack':
        fromDate = subYears(new Date(), 1);
        break;
      default:
        break;
    }

    updateField('fromDate', formatDate(fromDate));
    updateField('endDate', formatDate(new Date()));
    setFilterHasChanged(true);
  };

  const productsFormatOptionLabel = (option: ProductOption) => (
    <ProductItem
      id={option.value}
      label={option.label}
      imageName={option.product.imageName ?? ''}
    />
  );
  const productsOptions: ProductOption[] = allProducts
    .map((product) => ({
      label: tName(product),
      value: product.id,
      product: product,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filter-content"
        id="filter-header"
      >
        <Typography variant="h6">Filter</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={sv}>
          <Box
            py={2}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-end'}
          >
            <MultiSearchSelect
              label={'Boxar'}
              onChange={onBoxesChange}
              options={sortBoxes(allBoxes).map((box) => ({
                label: resolveBoxDisplayName(box),
                value: box.id,
              }))}
              value={form.selectedBoxes}
            />
            <Box ml={2} />
            <SearchSelect
              label={'Organisationer'}
              onChange={onOrganisationChange}
              options={allOrganisations.map((organisation) => ({
                label: organisation.name,
                value: organisation.id,
              }))}
              value={null}
            />
            <Box ml={2} />
            <CheckboxWithLabel
              label={'Bara aktiva boxar'}
              labelPlacement={'start'}
              onChange={onOnlyActiveBoxesChange}
              value={form.onlyActiveBoxes}
            />
          </Box>

          <Box
            py={2}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <DatePicker
              label="Från"
              value={form.fromDate ? new Date(form.fromDate) : null}
              onChange={onFromDateChange}
              sx={{ minWidth: INPUT_WIDTH }}
            />
            <Box ml={2} />
            <DatePicker
              label="Till"
              value={form.endDate ? new Date(form.endDate) : null}
              onChange={onEndDateChange}
              sx={{ minWidth: INPUT_WIDTH }}
            />
            <Box sx={{ ml: 2 }}>
              <Select
                id="date-range-select"
                label={'Snabbval datum'}
                value={selectedRange}
                variant={'outlined'}
                onChange={handleDateRangeChange}
              >
                <MenuItem value="lastWeek">Senaste veckan</MenuItem>
                <MenuItem value="lastMonth">Senaste månaden</MenuItem>
                <MenuItem value="lastThreeMonths">Senaste 3 månaderna</MenuItem>
                <MenuItem value="thisYear">Detta året</MenuItem>
                <MenuItem value="oneYearBack">Ett år tillbaka</MenuItem>
              </Select>
            </Box>
          </Box>

          <Box
            py={2}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <MultiSearchSelect
              label={'Kön'}
              onChange={onGenderChange}
              options={genderOptions}
              value={form.selectedGenders}
            />
          </Box>

          <Box
            py={2}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'flex-end'}
            justifyContent={'space-between'}
          >
            <MultiSearchSelect
              label={'Produkter'}
              onChange={onProductsChange}
              options={productsOptions}
              // @ts-ignore
              formatOptionLabel={productsFormatOptionLabel}
              value={form.selectedProducts}
            />
            <Button disabled={!filterHasChanged} onClick={onApplyFilter}>
              Använd filter
            </Button>
          </Box>
        </LocalizationProvider>
      </AccordionDetails>
    </Accordion>
  );
};
