import React from 'react';
import { CircularProgress } from '../../webui/progress/CircularProgress';
import { Typography } from '../../webui/Typography';
import { useImageUrl } from './gql/useImageUrl';

interface Props {
  id: string;
}

export const RentalImage: React.FC<Props> = ({ id }) => {
  const {
    imageUrl,
    loading: imageUrlLoading,
    error: imageUrlError,
  } = useImageUrl(id);

  if (imageUrlLoading) {
    return <CircularProgress />;
  }
  if (imageUrlError) {
    return <Typography>No image found</Typography>;
  }

  return <img src={imageUrl} alt="rental" width={'auto'} height={'100%'} />;
};
