import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Role as RoleType } from '../../common/types/role';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { Typography } from '../../webui/Typography';

interface Props {
  role: RoleType;
}

export const Role: React.FC<Props> = ({ role: { name, id, permissions } }) => {
  return (
    <Box textAlign={'start'}>
      <Link component={RouterLink} to={generatePath(Routes.ROLE, { id })}>
        {name}
      </Link>
      <Box mt={2}>
        {permissions.map((permission) => (
          <Box flexDirection={'row'} key={permission.id}>
            <Typography>{permission.type}</Typography>
            {permission.description && (
              <Typography>{permission.description}</Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
