import React from 'react';
import { Container } from '../../webui/Container';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { AllPermissionsTable } from './AllPermissionsTable';
import { useAllPermissions } from './gql/useAllPermissions';

interface Props {}

export const AllPermissions: React.FC<Props> = () => {
  const { data: allPermissions, loading, error } = useAllPermissions();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Paper>
        <AllPermissionsTable allPermissions={allPermissions} />
      </Paper>
    </Container>
  );
};
