import React, { useState } from 'react';
import { useNotification } from '../../common/notifications/useNotification';
import {
  GQL_ChargeRentalResponseType,
  Maybe,
  useChargeRentalMutation,
} from '../../generated/graphql';
import { ButtonWithLoading } from '../../webui/ButtonWithLoading';
import { ChargeAmountDialog } from './ChargeAmountDialog';

interface Props {
  rentalId: string;
}

const resolveErrorMessage = (
  type: Maybe<GQL_ChargeRentalResponseType> | undefined
): string => {
  switch (type) {
    case GQL_ChargeRentalResponseType.RentalAlreadyCharged:
      return 'Rental already charged';
    case GQL_ChargeRentalResponseType.CanNotChargeOngoingRentals:
      return 'Can not charge ongoing rentals';
    case GQL_ChargeRentalResponseType.RentalDoesNotExist:
      return 'Rental does not exist';
    case GQL_ChargeRentalResponseType.NoPermissionToChargeRental:
      return 'No permission to charge rental';
  }
  return 'Could not charge rental';
};

export const ChargeRentalButton: React.FC<Props> = ({ rentalId }) => {
  const [open, setOpen] = useState(false);

  const [
    chargeRentalMutation,
    { loading: chargeRentalLoading },
  ] = useChargeRentalMutation();

  const { showNotification } = useNotification();

  const chargeRental = async (chargeAmount: number | null) => {
    try {
      const response = await chargeRentalMutation({
        variables: {
          chargeRentalInput: {
            rentalId,
            chargeAmount:
              chargeAmount !== null
                ? {
                    amount: chargeAmount * 100,
                    currency: 'sek',
                  }
                : null,
          },
        },
      });
      const chargeRentalData = response.data?.chargeRental;
      if (chargeRentalData?.type === 'Success') {
        showNotification({
          message: 'Rental charged',
          severity: 'success',
        });
        console.log('Rental charged', response.data?.chargeRental);
      } else {
        showNotification({
          message: resolveErrorMessage(chargeRentalData?.type),
          severity: 'error',
        });
      }
    } catch (e) {
      showNotification({
        message: 'Could not charge rental',
        severity: 'error',
      });
      console.log('---', e, '--- e', 'rental not charged');
    }
  };

  const onChargeRentalClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonWithLoading
        loading={chargeRentalLoading}
        onClick={onChargeRentalClick}
      >
        Charge rental
      </ButtonWithLoading>
      <ChargeAmountDialog
        open={open}
        onClose={handleClose}
        onChargeRental={chargeRental}
      />
    </>
  );
};
