import React from 'react';
import { Box } from '../../webui/Box';
import { Sponsor as SponsorType } from '../../common/types/sponsor';
import { Typography } from '../../webui/Typography';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Link } from '../../webui/Link';

interface Props {
  sponsor: SponsorType;
}

export const Sponsor: React.FC<Props> = ({ sponsor: { name, url, id } }) => {
  return (
    <Box textAlign={'start'}>
      <Link component={RouterLink} to={generatePath(Routes.SPONSOR, { id })}>
        {name}
      </Link>
      <Box mt={2}>
        <Link href={url} target={'_blank'}>
          <Typography>{url}</Typography>
        </Link>
      </Box>
    </Box>
  );
};
