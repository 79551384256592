import * as Apollo from '@apollo/client';
import {
  GQL_AllProductsQuery,
  GQL_AllProductsQueryVariables,
  useAllProductsQuery,
} from '../../../generated/graphql';
import { transformProduct } from '../../../gql/transformProduct';
import { sortProducts } from '../utils/sortProducts';

export const useAllProducts = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllProductsQuery,
    GQL_AllProductsQueryVariables
  >
) => {
  const { data, ...rest } = useAllProductsQuery(baseOptions);
  return {
    ...rest,
    data: sortProducts(data?.product?.allProducts.map(transformProduct) ?? []),
  };
};
