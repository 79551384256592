import React from 'react';
import { formatDate } from '../../common/utils/dateUtils';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllBoxesLite } from '../box/gql/useAllBoxesLite';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { RentalsPerDay } from '../statistic/rentals/RentalsPerDay';
import { useAllUsers } from '../user/gql/useAllUsers';
import { Dashboard } from './Dashboard';
import {
  DASHBOARD_DAYS_BACK,
  dashboardInitialFromDate,
  getUniqueProductsFromBoxes,
} from './dashboardUtils';
import { RentalsThatMayNeedActionsTable } from './RentalsThatMayNeedActionsTable';

interface Props {}

export const StartPage: React.FC<Props> = () => {
  const {
    data: { rentals },
    loading: rentalsLoading,
    error: rentalsError,
  } = useAllRentals({
    variables: {
      filter: {
        startDate: {
          after: dashboardInitialFromDate,
        },
      },
    },
  });

  const {
    data: { totalCount: totalAllRentalsCount },
    loading: allRentalsLoading,
    error: allRentalsError,
  } = useAllRentals({
    variables: {
      pagination: {
        offset: 0,
        limit: 10,
      },
    },
  });

  const {
    data: { totalCount: totalUsersCount },
    loading: usersLoading,
    error: usersError,
  } = useAllUsers({
    variables: {
      pagination: {
        offset: 0,
        limit: 10,
      },
    },
  });

  const {
    data: boxes,
    loading: boxesLoading,
    error: boxesError,
  } = useAllBoxesLite({});

  if (allRentalsLoading || boxesLoading || rentalsLoading || usersLoading) {
    return <LoadingSpinnerContainer />;
  }

  if (allRentalsError) {
    return <div>{allRentalsError.message}</div>;
  }
  if (boxesError) {
    return <div>{boxesError.message}</div>;
  }
  if (rentalsError) {
    return <div>{rentalsError.message}</div>;
  }
  if (usersError) {
    return <div>{usersError.message}</div>;
  }

  return (
    <Container>
      <Dashboard
        rentals={rentals}
        totalBoxesCount={
          boxes.filter((box) => box.active && !box.deprecated).length
        }
        totalProductsCount={getUniqueProductsFromBoxes(boxes).length}
        totalRentalsCount={totalAllRentalsCount ?? 0}
        totalUsersCount={totalUsersCount ?? 0}
      />
      <Box mt={2} />
      <Paper>
        <Box p={2}>
          <Box m={2}>
            <Typography variant="h5">{`Number of rentals last ${DASHBOARD_DAYS_BACK} days`}</Typography>
          </Box>
          <RentalsPerDay
            fromDate={dashboardInitialFromDate}
            endDate={formatDate(new Date())}
            rentals={rentals}
          />
        </Box>
      </Paper>
      <Box mt={2} />
      <Paper>
        <RentalsThatMayNeedActionsTable />
      </Paper>
    </Container>
  );
};
