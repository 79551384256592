import { CompartmentProduct } from '../common/types/compartment';
import { GQL_CompartmentProductFragment } from '../generated/graphql';
import { transformProductRentalType } from './transformProduct';
import { transformProductStatusFromGQL } from './transformProductStatus';

export const transformCompartmentProduct = (
  gqlProduct: GQL_CompartmentProductFragment | null | undefined
): CompartmentProduct | undefined =>
  gqlProduct?.product
    ? {
        id: gqlProduct?.product.id,
        description: gqlProduct.product.description ?? undefined,
        name: gqlProduct.product.name,
        productStatus: gqlProduct.productStatus
          ? transformProductStatusFromGQL(gqlProduct.productStatus)
          : 'Ok',
        priceWeight: gqlProduct.product.priceWeights,
        rentalType: transformProductRentalType(gqlProduct.product.rentalType),
      }
    : undefined;
