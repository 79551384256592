import { Rental } from '../../../common/types/rental';
import {
  formatISO,
  getTimeDifferenceInMinutes,
} from '../../../common/utils/dateUtils';

export interface NotStoppedRental {
  id: string;
  rentStart: Rental['rentStart'];
}

const moreThanSixHoursSinceStart = (rentStart: string): boolean => {
  const timeDifferenceInMinutes = getTimeDifferenceInMinutes(
    rentStart,
    formatISO(new Date())
  );
  return !!timeDifferenceInMinutes && timeDifferenceInMinutes > 360;
};

export const createNotStoppedRentalsData = (
  rentals: Rental[]
): NotStoppedRental[] => {
  const notStoppedRentals: NotStoppedRental[] = [];
  rentals.forEach((rental) => {
    if (
      rental.rentEnd === undefined &&
      moreThanSixHoursSinceStart(rental.rentStart)
    ) {
      notStoppedRentals.push({
        id: rental.id,
        rentStart: rental.rentStart,
      });
    }
  });
  return notStoppedRentals;
};
