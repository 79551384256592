import * as Apollo from '@apollo/client';
import {
  GQL_RentalByIdQuery,
  GQL_RentalByIdQueryVariables,
  useRentalByIdQuery,
} from '../../../generated/graphql';
import { transformRental } from '../../../gql/transformRental';

export const useRentalById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_RentalByIdQuery,
    GQL_RentalByIdQueryVariables
  > &
    (
      | { variables: GQL_RentalByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useRentalByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.rental?.rentalById
      ? transformRental(data?.rental?.rentalById)
      : undefined,
  };
};
