import { Organisation } from '../../../../common/types/organisation';
import { resolveBoxDisplayName } from '../../../../common/utils/resolveBoxDisplayName';
import { Option } from '../../../../webui/inputs/SearchSelect';

export const getFilteredBoxesWithOrganisationBoxes = (
  currentBoxes: Option[],
  organisation: Organisation
): Option[] => {
  const boxesInOrganisation = organisation.boxes
    .map((box) => ({
      label: resolveBoxDisplayName(box),
      value: box.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const boxOptionMap = new Map<string, Option>();
  for (let boxOption of [...currentBoxes, ...boxesInOrganisation]) {
    boxOptionMap.set(boxOption['value'], boxOption);
  }
  return Array.from(boxOptionMap.values());
};
