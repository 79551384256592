import * as Apollo from '@apollo/client';
import {
  GQL_CompartmentByIdQuery,
  GQL_CompartmentByIdQueryVariables,
  useCompartmentByIdQuery,
} from '../../../generated/graphql';
import { transformCompartment } from '../../../gql/transformCompartment';

export const useCompartmentById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_CompartmentByIdQuery,
    GQL_CompartmentByIdQueryVariables
  > &
    (
      | { variables: GQL_CompartmentByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useCompartmentByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.compartment?.compartmentById
      ? transformCompartment(data?.compartment?.compartmentById)
      : null,
  };
};
