import {
  PaymentCard,
  PaymentCardStatus,
  PaymentInformation,
} from '../common/types/paymentInformation';
import { Gender, User, UserLite } from '../common/types/user';
import {
  GQL_Gender,
  GQL_PaymentCard,
  GQL_PaymentCardStatus,
  GQL_PaymentInformation,
  GQL_User_Provider,
  GQL_UserFragment,
  GQL_UserLiteFragment,
} from '../generated/graphql';
import { transformPermission } from './transformPermission';
import { transformPriceWeight } from './transformPriceWeight';
import { transformRental } from './transformRental';
import { transformRole } from './transformRole';

const transformCardStatusType = (
  type: GQL_PaymentCardStatus | null | undefined
): PaymentCardStatus | undefined => {
  switch (type) {
    case GQL_PaymentCardStatus.Canceled:
      return 'canceled';

    case GQL_PaymentCardStatus.Processing:
      return 'processing';

    case GQL_PaymentCardStatus.RequiresAction:
      return 'requires_action';

    case GQL_PaymentCardStatus.RequiresConfirmation:
      return 'requires_confirmation';

    case GQL_PaymentCardStatus.RequiresPaymentMethod:
      return 'requires_payment_method';

    case GQL_PaymentCardStatus.Succeeded:
      return 'succeeded';
  }
  return undefined;
};

function transformCard(paymentCard: GQL_PaymentCard): PaymentCard {
  return {
    brand: paymentCard.brand,
    last4: paymentCard.last4,
    id: paymentCard.id,
    status: transformCardStatusType(paymentCard.status),
  };
}

const transformPaymentInformation = (
  paymentInformation: GQL_PaymentInformation
): PaymentInformation => {
  return {
    id: paymentInformation.id,
    customerId: paymentInformation.customerId,
    card: paymentInformation.cards[0]
      ? transformCard(paymentInformation.cards[0])
      : undefined,
  };
};

const transformProvider = (
  provider: GQL_User_Provider | null | undefined
): User['provider'] => {
  switch (provider) {
    case GQL_User_Provider.Auth0:
      return 'auth0';
    case GQL_User_Provider.GoogleOauth2:
      return 'google';
    case GQL_User_Provider.Apple:
      return 'apple';
    case GQL_User_Provider.Firebase:
      return 'firebase';
  }
  return undefined;
};

const transformGender = (gender: GQL_Gender): Gender | undefined => {
  switch (gender) {
    case GQL_Gender.Male:
      return 'Male';
    case GQL_Gender.Female:
      return 'Female';
    case GQL_Gender.Other:
      return 'Other';
    case GQL_Gender.PreferNotToSay:
      return 'PreferNotToSay';
  }
  return undefined;
};

export const transformUserLite = (
  userLite: GQL_UserLiteFragment
): UserLite => ({
  id: userLite.id,
  accountCreated: userLite.accountCreated ?? undefined,
  birthYear: userLite.birthYear ?? undefined,
  externalId: userLite.externalId ?? undefined,
  email: userLite.email ?? undefined,
  firstName: userLite.firstName ?? undefined,
  gender: userLite.gender ? transformGender(userLite.gender) : undefined,
  lastName: userLite.lastName ?? undefined,
  paymentInformation: userLite.paymentInformation
    ? transformPaymentInformation(userLite.paymentInformation)
    : undefined,
  phoneNumber: userLite.phoneNumber ?? undefined,
  priceWeight: userLite.priceWeights,
  provider: transformProvider(userLite.provider),
  numberOfRentals: userLite.rentals.length,
  roles: userLite.roles.map((role) => ({
    id: role.id,
    name: role.name,
  })),
  zipCode: userLite.zipCode ?? undefined,
});

export const transformUser = (user: GQL_UserFragment): User => ({
  id: user.id,
  accountCreated: user.accountCreated ?? undefined,
  birthYear: user.birthYear ?? undefined,
  externalId: user.externalId ?? undefined,
  email: user.email ?? undefined,
  firstName: user.firstName ?? undefined,
  gender: user.gender ? transformGender(user.gender) : undefined,
  lastName: user.lastName ?? undefined,
  paymentInformation: user.paymentInformation
    ? transformPaymentInformation(user.paymentInformation)
    : undefined,
  phoneNumber: user.phoneNumber ?? undefined,
  priceWeight: transformPriceWeight(user.priceWeights),
  provider: transformProvider(user.provider),
  rentals: user.rentals.map(transformRental),
  roles: user.roles.map(transformRole),
  zipCode: user.zipCode ?? undefined,
  permissions: user.permissions.map(transformPermission),
});
