import React, { useState } from 'react';
import { generatePath, Link as RouterLink, useParams } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { DataList } from '../../webui/DataList';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { EditPendingUserDrawer } from './EditPendingUserDrawer';
import { usePendingUserById } from './gql/usePendingUserById';

interface Props {}
export const PendingUserPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: pendingUser, loading, error } = usePendingUserById({
    variables: { id },
  });
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (!pendingUser) {
    return <div>Could not find pending user.</div>;
  }

  const items: { value?: string; label: string }[] = [
    {
      label: 'Id',
      value: pendingUser.id,
    },
    {
      label: 'Email',
      value: pendingUser?.email,
    },
  ];

  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <DataList
            header={'Pending user'}
            headerVariant={'h3'}
            items={items}
          />
          <Box>
            <Button onClick={onOpen}>Edit</Button>
          </Box>
        </Box>
      </Paper>
      {pendingUser.roles.length > 0 && (
        <Box mt={2}>
          <Paper>
            <Box p={2}>
              <Typography variant={'h3'}>Roles</Typography>
              <Box mt={2} />
              {pendingUser.roles.map((role) => (
                <Box key={role.id}>
                  <Link
                    component={RouterLink}
                    to={generatePath(Routes.ROLE, { id: role.id })}
                  >
                    {role.name}
                  </Link>
                </Box>
              ))}
            </Box>
          </Paper>
        </Box>
      )}
      {pendingUser.organisations.length > 0 && (
        <Box mt={2}>
          <Paper>
            <Box p={2}>
              <Typography variant={'h3'}>Organisations</Typography>
              <Box mt={2} />
              {pendingUser.organisations.map((organisation) => (
                <Box key={organisation.id}>
                  <Link
                    component={RouterLink}
                    to={generatePath(Routes.ORGANISATION, {
                      id: organisation.id,
                    })}
                  >
                    {organisation.name}
                  </Link>
                </Box>
              ))}
            </Box>
          </Paper>
        </Box>
      )}
      <EditPendingUserDrawer
        pendingUser={pendingUser}
        open={open}
        onClose={onClose}
      />
    </Container>
  );
};
