import React from 'react';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { TextField } from '../../webui/inputs/TextField';

interface Props {
  label: string;
  onSearchChange: (search: string) => void;
}

export const SearchFilter: React.FC<Props> = ({ label, onSearchChange }) => {
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [oldSearchTerm, setOldSearchTerm] = React.useState<string>('');

  const onSearchTextChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  const onSearchClicked = () => {
    setOldSearchTerm(searchTerm);
    onSearchChange(searchTerm);
  };

  return (
    <Box display={'flex'}>
      <TextField
        label={label}
        value={searchTerm}
        onChange={onSearchTextChange}
      />
      <Box ml={2} />
      <Button disabled={searchTerm === oldSearchTerm} onClick={onSearchClicked}>
        Search
      </Button>
    </Box>
  );
};
