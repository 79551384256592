import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllPendingUsers } from './gql/useAllPendingUsers';
import { PendingUsersTable } from './PendingUsersTable';

interface Props {}

export const AllPendingUsers: React.FC<Props> = () => {
  const { data: allPendingUsers, loading, error } = useAllPendingUsers();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>
            There are currently {allPendingUsers.length} pending users!
          </Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_PENDING_USER}>
            Add new pending user
          </Link>
        </Box>
      </Box>
      <Paper>
        <PendingUsersTable pendingUsers={allPendingUsers} />
      </Paper>
    </Container>
  );
};
