import { Coordinates } from '../types/coordinates';

export const resolveCoordinates = (
  lat: string | undefined,
  long: string | undefined
): Coordinates | undefined => {
  if (lat && long) {
    const latNumber = parseFloat(lat);
    const longNumber = parseFloat(long);
    if (!isNaN(latNumber) && !isNaN(longNumber)) {
      return {
        lat: latNumber,
        long: longNumber,
      };
    }
  }
  return undefined;
};
