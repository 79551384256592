import { BatteryStatusMarker } from '../types/batteryStatusMarker';

export type BatteryStatus =
  | 'LOW_BATTERY'
  | 'MEDIUM_BATTERY'
  | 'HIGH_BATTERY'
  | 'UNKNOWN';

export const HIGH_VOLTAGE_LIMIT = 11000;
export const MEDIUM_VOLTAGE_LIMIT = 9000;

export const resolveBatteryStatus = (
  marker: BatteryStatusMarker
): BatteryStatus => {
  if (marker.voltage > HIGH_VOLTAGE_LIMIT) {
    return 'HIGH_BATTERY';
  } else if (marker.voltage > MEDIUM_VOLTAGE_LIMIT) {
    return 'MEDIUM_BATTERY';
  }
  return 'LOW_BATTERY';
};
