import { GQL_Gender } from '../../../../generated/graphql';

export const genderOptions = [
  { value: 'MALE', label: 'Man' },
  { value: 'FEMALE', label: 'Kvinna' },
  { value: 'OTHER', label: 'Annat' },
  { value: 'PREFER_NOT_TO_SAY', label: 'Föredrar att inte säga' },
];

export const transformGender = (gender: string): GQL_Gender | undefined => {
  if (gender === 'MALE') {
    return GQL_Gender.Male;
  }
  if (gender === 'FEMALE') {
    return GQL_Gender.Female;
  }
  if (gender === 'OTHER') {
    return GQL_Gender.Other;
  }
  if (gender === 'PREFER_NOT_TO_SAY') {
    return GQL_Gender.PreferNotToSay;
  }
  return undefined;
};
