import React, { useMemo } from 'react';
import { useForm } from '../../../common/hooks/useForm';
import { useNotification } from '../../../common/notifications/useNotification';
import { Compartment } from '../../../common/types/compartment';
import { ProductStatus } from '../../../common/types/productStatus';
import { resolveCircuitBoardPort } from '../../../common/utils/resolveCircuitBoardPort';
import {
  AllBoxesDocument,
  useEditCompartmentMutation,
} from '../../../generated/graphql';
import { transformProductStatusToGQL } from '../../../gql/transformProductStatus';
import { Box } from '../../../webui/Box';
import { Button } from '../../../webui/Button';
import { SaveButton } from '../../../webui/buttons/SaveButton';
import { Drawer } from '../../../webui/Drawer';
import { Switch, SwitchProps } from '../../../webui/inputs/Switch';
import { TextField, TextFieldProps } from '../../../webui/inputs/TextField';
import { Typography } from '../../../webui/Typography';
import { ProductSelect } from '../../product/ProductSelect';
import { ProductStatusSelect } from '../../product/ProductStatusSelect';
import { RentalInformation } from '../RentalInformation';
import { LockButton } from './LockButton';
import { UnlockButton } from './UnlockButton';

interface EditCompartmentDrawerProps {
  compartment: Compartment;
  open: boolean;
  onClose: () => void;
}

export interface EditCompartmentForm {
  circuitBoardPort: string;
  name: string;
  productId: string;
  private: boolean;
  productStatus?: ProductStatus;
}

const createCompartmentInitialForm = (
  compartment: Compartment
): EditCompartmentForm => ({
  circuitBoardPort: String(compartment.circuitBoardPort),
  name: compartment.name ?? '',
  productId: compartment.product?.id ?? '',
  private: compartment.private ?? false,
  productStatus: compartment.product?.productStatus,
});

// TODO: Disable form and all buttons if someone is using the compartment.
// TODO: Form handling, validation, disable save etc
export const EditCompartmentDrawer: React.FC<EditCompartmentDrawerProps> = ({
  compartment,
  open,
  onClose,
}) => {
  const { form, reset, updateField } = useForm<EditCompartmentForm>(
    useMemo(() => createCompartmentInitialForm(compartment), [compartment])
  );
  const { showNotification } = useNotification();

  const [
    editCompartment,
    { loading: editLoading },
  ] = useEditCompartmentMutation({
    refetchQueries: [{ query: AllBoxesDocument }],
  });

  const onProductChange = (value: string) => {
    updateField('productId', value);
  };

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onCircuitBoardPortChange: TextFieldProps['onChange'] = (value) => {
    updateField('circuitBoardPort', value);
  };

  const onPrivateChange: SwitchProps['onChange'] = (value) => {
    updateField('private', value);
  };

  const onProductStatusChange = (value: ProductStatus) => {
    updateField('productStatus', value);
  };

  const onCancel = () => {
    // TODO: Clear form
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editCompartment({
        variables: {
          compartmentId: compartment.id,
          compartment: {
            circuitBoardPort: resolveCircuitBoardPort(form.circuitBoardPort),
            name: form.name,
            private: form.private,
            product: {
              productId: form.productId,
              productStatus: form.productStatus
                ? transformProductStatusToGQL(form.productStatus)
                : undefined,
            },
          },
        },
      });
      showNotification({
        message: 'Compartment edited.',
        severity: 'success',
      });
      console.log(
        'Compartment edited!',
        result?.data?.editCompartment?.compartment
      );
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit compartment.',
        severity: 'error',
      });
      console.log('Compartment edit error', e, '--- e');
    }
  };

  // TODO: Change rental information
  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">
          Edit compartment "{compartment.name}"
        </Typography>
        <Box mt={2}>
          <RentalInformation rentals={compartment.rentals} />
        </Box>
        <Box mt={2}>
          <form noValidate autoComplete="off">
            <Box display={'flex'} flexWrap={'wrap'}>
              <Box mr={1} mt={1}>
                <TextField
                  id="edit-compartment-name"
                  label="Name"
                  value={form.name}
                  onChange={onNameChange}
                />
              </Box>
              <Box mr={1} mt={1}>
                <TextField
                  id="edit-compartment-circuit-board-port"
                  label="Circuit board port"
                  value={form.circuitBoardPort}
                  onChange={onCircuitBoardPortChange}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Switch
                label={'Private'}
                labelPlacement={'start'}
                onChange={onPrivateChange}
                value={form.private}
              />
            </Box>
            <Box mt={2}>
              <ProductStatusSelect
                id={'edit-compartment-product-status'}
                value={form.productStatus}
                onChange={onProductStatusChange}
              />
            </Box>
            <Box mt={2}>
              <ProductSelect
                id={'edit-compartment-product'}
                value={form.productId}
                onChange={onProductChange}
              />
            </Box>
          </form>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          {compartment.locked ? (
            <UnlockButton compartmentId={compartment.id} />
          ) : (
            <LockButton compartmentId={compartment.id} />
          )}
          <Box ml={2} />
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={editLoading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
