import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { AllRolesTable } from './AllRolesTable';
import { useAllRoles } from './gql/useAllRoles';

interface Props {}

export const AllRoles: React.FC<Props> = () => {
  const { data: allRoles, loading, error } = useAllRoles();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>There are currently {allRoles.length} roles!</Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_ROLE}>
            Add new role
          </Link>
        </Box>
      </Box>
      <Paper>
        <AllRolesTable allRoles={allRoles} />
      </Paper>
    </Container>
  );
};
