import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NotificationPosition {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
}

export type Severity = 'error' | 'warning' | 'info' | 'success';

export interface Notification {
  message: string;
  position?: NotificationPosition;
  severity: Severity;
}

interface AuthState {
  notification?: Notification;
}

const initialState: AuthState = { notification: undefined };

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification(state, action: PayloadAction<Notification>) {
      state.notification = action.payload;
    },
    removeNotification(state) {
      state.notification = undefined;
    },
  },
});

export const {
  showNotification: showNotificationAction,
  removeNotification: removeNotificationAction,
} = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
