import React from 'react';
import { BoxWithoutRentals } from '../../../common/types/box';
import { Rental } from '../../../common/types/rental';
import { roundToDigits } from '../../../common/utils/roundToDigits';
import { SortablePaginatedTable } from '../../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../../webui/table/sortable-paginated-table/TableHeadCell';
import { Typography } from '../../../webui/Typography';
import {
  getProductsInRentalsStats,
  ProductsInRentalsStats,
} from './utils/getProductsInRentalsStats';

interface Props {
  boxes: BoxWithoutRentals[];
  rentals: Rental[];
}

const createTableHeadCells = (): TableHeadCell<ProductsInRentalsStats>[] => [
  {
    id: 'productName',
    label: 'Product',
    render: (row) => row.productName,
  },
  {
    id: 'rentalsCount',
    label: '#Rentals',
    render: (row) => row.rentalsCount,
  },
  {
    id: 'boxesCount',
    label: '#In boxes',
    render: (row) => row.boxesCount,
  },
  {
    id: 'rentalsPerBox',
    label: '#Rentals per box',
    render: (row) => roundToDigits(row.rentalsPerBox, 1),
  },
  {
    id: 'rentalShare',
    label: 'Share',
    render: (row) => `${row.rentalShare}%`,
  },
  {
    id: 'meanValueRatings',
    label: 'Mean rating',
    render: (row) =>
      row.meanValueRatings
        ? Math.round(100 * row.meanValueRatings) / 100
        : null,
  },
  {
    id: 'meanValueRentTime',
    label: 'Mean rent time',
    render: (row) =>
      row.meanValueRentTime ? Math.round(row.meanValueRentTime) : null,
  },
];

export const RentalsPerProductTable: React.FC<Props> = ({ boxes, rentals }) => {
  const rows = getProductsInRentalsStats(
    boxes.filter((box) => box.active),
    rentals
  );

  if (rows.length <= 0) {
    return (
      <Typography>{'There were no rentals during this period'}</Typography>
    );
  }

  return (
    <SortablePaginatedTable
      title={'Number of rentals per product'}
      initialRowsPerPage={rows.length}
      initialOrderByKey={'rentalsCount'}
      initialOrder={'desc'}
      tableHeadCells={createTableHeadCells()}
      rows={rows}
    />
  );
};
