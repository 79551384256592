import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { compareDate, toDisplayDate } from '../../common/utils/dateUtils';
import { exhaustSwitchCase } from '../../common/utils/exhaustSwitchCase';
import { resolveDisplayPrice } from '../../common/utils/resolveDisplayPrice';
import { GQL_PaymentChargeInformationType } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';
import { Typography } from '../../webui/Typography';
import { ChargeRentalButton } from '../rentals/ChargeRentalButton';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import {
  createUnpaidRentalsData,
  UnpaidRentalData,
} from './utils/createUnpaidRentalsData';

interface UnpaidRentalsProps {}

const INITIAL_ROWS_PER_PAGE = 20;

export const unpaidRentalComparator: Comparator<UnpaidRentalData> = (
  order,
  orderBy
) => {
  switch (orderBy) {
    case 'id':
      break;
    case 'amount':
      break;
    case 'code':
      break;
    case 'currency':
      break;
    case 'rentEnd':
      return (a, b) => compareDate(a.rentEnd, b.rentEnd, order);
    case 'status':
      break;
    case 'rentalId':
      break;
    default:
      return exhaustSwitchCase(orderBy);
  }
  return getComparator(order, orderBy);
};

const createTableHeadCells: TableHeadCell<UnpaidRentalData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.RENTAL, {
          id: row.id,
        })}
      >
        {row.id}
      </Link>
    ),
    maxWidth: 50,
  },
  {
    id: 'rentEnd',
    label: 'Ended when?',
    render: (value) => toDisplayDate(value['rentEnd']),
  },
  {
    id: 'status',
    label: 'Status',
    render: (value) => value['status'],
  },
  {
    id: 'amount',
    label: 'Amount',
    render: (value) => resolveDisplayPrice(value.amount, value.currency),
  },
  {
    id: 'rentalId',
    label: 'Charge',
    render: (value) => {
      return (
        <Box p={0.5}>
          <ChargeRentalButton rentalId={value['rentalId']} />
        </Box>
      );
    },
  },
];
export const UnpaidRentals: React.FC<UnpaidRentalsProps> = () => {
  const {
    data: { rentals },
    loading,
    error,
  } = useAllRentals({
    variables: {
      filter: {
        paymentChargeInformationTypes: [GQL_PaymentChargeInformationType.Fail],
      },
    },
  });
  const rows = createUnpaidRentalsData(rentals);

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      {rows.length > 0 ? (
        <SortablePaginatedTable
          getComparator={unpaidRentalComparator}
          title={'Unpaid rentals'}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
          initialOrderByKey={'rentEnd'}
          initialOrder={'desc'}
          rows={rows}
          tableHeadCells={createTableHeadCells}
        />
      ) : (
        <Box mt={1}>
          <Typography>No unpaid rentals!</Typography>
        </Box>
      )}
    </>
  );
};
