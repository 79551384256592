import { Rental } from '../../../../common/types/rental';
import { formatDate, parseISO } from '../../../../common/utils/dateUtils';

export interface RentalsPerDayData {
  date: string;
  numberOfRentals: number;
  numberOfRentalsRollingAverage: number;
}

export interface RentalsPerDay {
  date: string;
  numberOfRentals: number;
}

export const generateRentalsPerDayData = (
  rentals: Rental[],
  fromDate: string,
  toDate: string,
  rollingDaysAverage: number
): RentalsPerDayData[] => {
  // Helper function to generate an array of dates from fromDate to toDate
  const getDateRange = (start: Date, end: Date): string[] => {
    const dates: string[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dates.push(formatDate(new Date(currentDate)));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  // Initialize the map to hold rental counts per day
  const rentalsPerDayMap: { [date: string]: number } = {};

  // Populate the map with rental counts
  rentals.forEach((rental) => {
    const rentalDate = formatDate(parseISO(rental.rentStart));
    if (!rentalsPerDayMap[rentalDate]) {
      rentalsPerDayMap[rentalDate] = 0;
    }
    rentalsPerDayMap[rentalDate] += 1;
  });

  // Get all dates in the range from fromDate to toDate
  const allDates = getDateRange(parseISO(fromDate), parseISO(toDate));

  // Helper function to calculate rolling average for past N days
  const calculateRollingAverage = (currentDateIndex: number): number => {
    const pastDates = allDates.slice(
      Math.max(0, currentDateIndex - rollingDaysAverage + 1),
      currentDateIndex + 1
    );

    const totalRentals = pastDates.reduce((sum, date) => {
      return sum + (rentalsPerDayMap[date] || 0);
    }, 0);

    return totalRentals / pastDates.length;
  };

  // Prepare final data, calculating rental counts and rolling averages
  return allDates.map((date, index) => {
    const numberOfRentals = rentalsPerDayMap[date] || 0;
    const numberOfRentalsRollingAverage = calculateRollingAverage(index);

    return {
      date,
      numberOfRentals,
      numberOfRentalsRollingAverage,
    };
  });
};

export const calculateMeanAverage = (
  array: RentalsPerDay[],
  currentPos: number,
  averageTime: number
): number => {
  let number = 0;
  for (let i = 0; i < averageTime; i++) {
    const rentalsPerDay = array[currentPos - i];
    if (rentalsPerDay) {
      number = number + rentalsPerDay.numberOfRentals;
    }
  }
  return number / Math.min(currentPos + 1, averageTime);
};
