interface PostalCodeData {
  latitude: number;
  longitude: number;
  formattedAddress: string;
}

type PostalCodeDataObject = { [key: string]: PostalCodeData };

export const createPostalCodeHeatmap = (
  postalCodes: string[],
  postalCodeCoordinates: PostalCodeDataObject
): [number, number][] => {
  const result: [number, number][] = [];
  for (const postalCode of postalCodes) {
    const postalCodeCoordinate = postalCodeCoordinates[postalCode];
    if (postalCodeCoordinate) {
      for (let i = 0; i < 10; i++) {
        result.push([
          postalCodeCoordinate.latitude,
          postalCodeCoordinate.longitude,
        ]);
      }
    }
  }

  return result;
};
