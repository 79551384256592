import {
  Snackbar as MUISnackbar,
  SnackbarProps as MUISnackbarProps,
} from '@mui/material';
import React from 'react';

interface SnackbarProps extends MUISnackbarProps {}

export const Snackbar: React.FC<SnackbarProps> = (props) => {
  return <MUISnackbar {...props} />;
};

export type SnackbarOnClose = SnackbarProps['onClose'];
