import { getAuth } from 'firebase/auth';
import React, { useState } from 'react';
import { AppLoggedIn } from './AppLoggedIn';
import { AppNotLoggedIn } from './AppNotLoggedIn';
import { useAuthentication } from './common/auth/hooks/useAuthentication';
import { Header } from './common/header/Header';

import { Notification } from './common/notifications/Notification';
import { Box } from './webui/Box';
import { LoginDrawer } from './webui/LoginDrawer';

function App() {
  const [loginOpen, setLoginOpen] = useState(false);
  const { currentUser, userLoggedIn } = useAuthentication();

  const openLogin = () => {
    setLoginOpen(true);
  };

  const closeLogin = async () => {
    await getAuth().signOut();
    setLoginOpen(false);
  };

  return (
    <div className="App">
      <Header
        loggedIn={userLoggedIn}
        user={currentUser}
        onLogIn={openLogin}
        onLogOut={closeLogin}
      />
      <Box m={2}>
        <>
          <Notification />
          {userLoggedIn ? <AppLoggedIn /> : <AppNotLoggedIn />}
        </>
      </Box>
      <LoginDrawer open={loginOpen} onClose={() => setLoginOpen(false)} />
    </div>
  );
}

export default App;
