import { getAuth } from 'firebase/auth';
import React, { useEffect } from 'react';
import {
  removeCurrentUserAction,
  setCurrentUserAction,
} from './common/auth/authenticationSlice';
import { useAppDispatch } from './store';

interface Props {}

export const AuthenticationProvider: React.FC<
  React.PropsWithChildren<Props>
> = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return getAuth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          setCurrentUserAction({ email: user.email || 'unkownEmail@piffl.se' })
        );
      } else {
        dispatch(removeCurrentUserAction());
      }
    });
  }, [dispatch]);

  return <>{children}</>;
};
