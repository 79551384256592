import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Organisation } from '../../common/types/organisation';
import { PendingUser } from '../../common/types/PendingUser';
import { Role } from '../../common/types/role';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';

interface Props {
  pendingUsers: PendingUser[];
}

export interface PendingUserData {
  id: string;
  email: string;
  roles: Role[];
  organisations: Organisation[];
}

const permissionHeadCells: TableHeadCell<PendingUserData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (value) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.PENDING_USER, { id: value.id })}
      >
        {value.id}
      </Link>
    ),
    maxWidth: 20,
  },
  {
    id: 'email',
    label: 'Email',
    render: (value) => value.email,
  },
  {
    id: 'roles',
    label: 'Roles',
    render: (value) => (
      <>
        {value.roles.map((role, index) => (
          <React.Fragment key={role.id}>
            <Link
              component={RouterLink}
              to={generatePath(Routes.ROLE, { id: role.id })}
            >
              {role.name}
            </Link>
            {index !== value.roles.length - 1 ? ', ' : ''}
          </React.Fragment>
        ))}
      </>
    ),
  },
  {
    id: 'organisations',
    label: 'Organisations',
    render: (value) => (
      <>
        {value.organisations.map((organisation, index) => (
          <React.Fragment key={organisation.id}>
            <Link
              component={RouterLink}
              to={generatePath(Routes.ORGANISATION, { id: organisation.id })}
            >
              {organisation.name}
            </Link>
            {index !== value.organisations.length - 1 ? ', ' : ''}
          </React.Fragment>
        ))}
      </>
    ),
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (pendingUsers: PendingUser[]): PendingUserData[] => {
  return pendingUsers.map((pendingUser) => {
    return {
      id: pendingUser.id,
      email: pendingUser.email,
      roles: pendingUser.roles,
      organisations: pendingUser.organisations,
    };
  });
};

const permissionComparator: Comparator<PendingUserData> = (order, orderBy) => {
  return getComparator(order, orderBy);
};

export const PendingUsersTable: React.FC<Props> = ({ pendingUsers }) => {
  return (
    <SortablePaginatedTable
      getComparator={permissionComparator}
      title={'Pending users'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'id'}
      rows={createRows(pendingUsers)}
      tableHeadCells={permissionHeadCells}
    />
  );
};
