import React from 'react';

export interface TableHeadCell<T> {
  id: Extract<keyof T, string>;
  label: string;
  render: (row: T) => React.ReactNode;
  search?: (
    row: T,
    id: Extract<keyof T, string>,
    searchTerm: string
  ) => boolean;
  maxWidth?: number;
}

export const defaultSearch = <T>(
  row: T,
  id: Extract<keyof T, string>,
  searchTerm: string
): boolean => {
  if (typeof row[id] === 'object') {
    return JSON.stringify(row[id])
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  }

  return String(row[id]).toLowerCase().includes(searchTerm.toLowerCase());
};
