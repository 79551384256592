import React from 'react';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { AllFeedbacksTable } from './AllFeedbacksTable';
import { useAllFeedbacks } from './gql/useAllFeedbacks';
import { RentalFeedbacksTable } from './RentalFeedbacksTable';

interface Props {}

export const AllFeedbacks: React.FC<Props> = () => {
  const { data: allFeedbacks, loading, error } = useAllFeedbacks();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Box my={2}>
      <Paper>
        <AllFeedbacksTable allFeedbacks={allFeedbacks} />
      </Paper>
      <Box mt={2}>
        <Paper>
          <RentalFeedbacksTable />
        </Paper>
      </Box>
    </Box>
  );
};
