import React, { ReactElement } from 'react';
import { Box } from '../../Box';
import { TableCell } from '../TableCell';
import { TableHead } from '../TableHead';
import { TableRow } from '../TableRow';
import { TableSortLabel } from '../TableSortLabel';
import { TableHeadCell } from './TableHeadCell';
import { Order } from './types';

interface SortablePaginatedTableHeadProps<T extends {}> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: Order;
  orderBy: keyof T;
  headCells: TableHeadCell<T>[];
}

export const SortablePaginatedTableHead = <T extends {}>({
  headCells,
  order,
  orderBy,
  onRequestSort,
}: SortablePaginatedTableHeadProps<T>): ReactElement<
  SortablePaginatedTableHeadProps<T>
> => {
  const onTableLabelClick =
    (property: keyof T) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={String(headCell.id)}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={onTableLabelClick(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box
                  sx={{
                    border: 0,
                    clip: 'rect(0 0 0 0)',
                    height: 1,
                    margin: -1,
                    overflow: 'hidden',
                    padding: 0,
                    position: 'absolute',
                    top: 20,
                    width: 1,
                  }}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
