import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import {
  ActivityArea as ActivityAreaType,
  AreaInfo,
} from '../../common/types/activityArea';
import { Location } from '../../common/types/location';
import { resolveCoordinates } from '../../common/utils/resolveCoordinates';
import {
  AllActivityAreasDocument,
  GQL_EditActivityAreaResponseType,
  Maybe,
  useEditActivityAreaMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../webui/inputs/CheckboxWithLabel';
import { MultiSelectProps } from '../../webui/inputs/MultiSelect';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { EditLocation, EditLocationForm } from '../location/edit/EditLocation';
import { ActivitiesSelect } from './ActivitiesSelect';
import { AreaInfosForm, EditAreaInfos } from './EditAreaInfos';
import { useActivities } from './gql/useActivities';

interface EditActivityAreaDrawerProps {
  activityArea: ActivityAreaType;
  open: boolean;
  onClose: () => void;
}

interface EditActivityAreaForm {
  active: boolean;
  activities: string[];
  verified: boolean;
  type: string;
}

const createInitialEditActivityAreaForm = (
  activityArea: ActivityAreaType
): EditActivityAreaForm => ({
  active: activityArea.active,
  activities: activityArea.activities,
  verified: activityArea.verified,
  type: activityArea.type,
});

const createInitialEditLocationForm = (
  location: Location | undefined
): EditLocationForm => {
  if (location) {
    return {
      name: location.name,
      description: location.description,
      lat: location.coordinates?.lat
        ? String(location.coordinates.lat)
        : undefined,
      long: location.coordinates?.long
        ? String(location.coordinates.long)
        : undefined,
    };
  }
  return {};
};

const createInitialEditAreaInfosForm = (
  areaInfos: AreaInfo[]
): AreaInfosForm => {
  return {
    areaInfos,
  };
};

const resolveErrorMessage = (
  type: Maybe<GQL_EditActivityAreaResponseType> | undefined
): string => {
  switch (type) {
    case GQL_EditActivityAreaResponseType.NoPermissionToEditActivityArea:
      return 'No permission to edit activityArea';
    case GQL_EditActivityAreaResponseType.ActivityAreaDoesNotExist:
      return 'ActivityArea does not exist';
  }
  return 'Could not edit activityArea';
};

// TODO: Form handling, validation, disable save etc
export const EditActivityAreaDrawer: React.FC<EditActivityAreaDrawerProps> = ({
  activityArea,
  open,
  onClose,
}) => {
  const { data: activities, loading: activitiesLoading } = useActivities();

  const { form, updateField, reset } = useForm(
    useMemo(
      () => createInitialEditActivityAreaForm(activityArea),
      [activityArea]
    )
  );
  const locationForm = useForm(
    useMemo(
      () => createInitialEditLocationForm(activityArea.location),
      [activityArea]
    )
  );
  const areaInfosForm = useForm(
    useMemo(
      () => createInitialEditAreaInfosForm(activityArea.areaInfos),
      [activityArea]
    )
  );

  const { showNotification } = useNotification();

  const [editActivityArea, { loading }] = useEditActivityAreaMutation();

  const onTypeChange: TextFieldProps['onChange'] = (value) => {
    updateField('type', value);
  };

  const onActiveChange: CheckboxWithLabelProps['onChange'] = (value) => {
    updateField('active', value);
  };

  const onActivitiesChange: MultiSelectProps['onChange'] = (value) => {
    updateField('activities', value);
  };

  const onVerifiedChange: CheckboxWithLabelProps['onChange'] = (value) => {
    updateField('verified', value);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editActivityArea({
        variables: {
          activityAreaId: activityArea.id,
          activityAreaInput: {
            areaInfos: areaInfosForm.form.areaInfos,
            location: {
              description: locationForm.form.description,
              name: locationForm.form.name,
              coordinates: resolveCoordinates(
                locationForm.form.lat,
                locationForm.form.long
              ),
            },
            active: form.active,
            activities: form.activities,
            verified: form.verified,
            type: form.type,
          },
        },
        refetchQueries: [{ query: AllActivityAreasDocument }],
      });
      if (result?.data?.editActivityArea?.type === 'Success') {
        showNotification({
          message: 'ActivityArea edited.',
          severity: 'success',
        });
        onClose();
      } else {
        showNotification({
          message: resolveErrorMessage(result?.data?.editActivityArea?.type),
          severity: 'error',
        });
      }
    } catch (e) {
      showNotification({
        message: 'Could not edit activityArea.',
        severity: 'error',
      });
      console.log('ActivityArea edit error', e, '--- e');
    }
  };

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit activityArea</Typography>
        <Box mt={2}>
          <form noValidate autoComplete="off">
            <Box display={'flex'} flexDirection={'row'} mt={2}>
              <Box mr={2}>
                <CheckboxWithLabel
                  label={'Active'}
                  labelPlacement={'start'}
                  onChange={onActiveChange}
                  value={form.active}
                />
              </Box>
              <Box>
                <CheckboxWithLabel
                  label={'Verified'}
                  labelPlacement={'start'}
                  onChange={onVerifiedChange}
                  value={form.verified}
                />
              </Box>
            </Box>
            <Box display={'flex'} flexWrap={'wrap'}>
              <Box mr={1} mt={1}>
                <TextField
                  id="edit-box-name"
                  label="Type"
                  value={form.type}
                  onChange={onTypeChange}
                />
              </Box>
              {!activitiesLoading ? (
                <ActivitiesSelect
                  activities={activities}
                  initialActivities={form.activities}
                  onChange={onActivitiesChange}
                />
              ) : (
                <LoadingSpinnerContainer />
              )}
            </Box>
            <Box mt={2}>
              <EditAreaInfos
                form={areaInfosForm.form}
                updateField={areaInfosForm.updateField}
              />
            </Box>
            <Box mt={2}>
              <EditLocation
                form={locationForm.form}
                updateField={locationForm.updateField}
              />
            </Box>
          </form>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={loading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
