import React from 'react';
import { GQL_SearchType } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { AllUsersTable } from './AllUsersTable';
import { useAllUsers } from './gql/useAllUsers';
import { UserExport } from './UserExport';

interface Props {
  searchTerm?: string;
}

const paginationLimit = 100;

export const AllUsers: React.FC<Props> = ({ searchTerm }) => {
  const {
    data: { totalCount, users },
    loading,
    error,
    fetchMore,
  } = useAllUsers({
    variables: {
      pagination: {
        offset: 0,
        limit: paginationLimit,
      },
      search: searchTerm
        ? {
            email: {
              query: searchTerm,
              type: GQL_SearchType.Contains,
            },
          }
        : undefined,
    },
  });

  const onFetchMoreClicked = async () => {
    await fetchMore({
      variables: {
        pagination: {
          offset: users.length,
          limit: paginationLimit,
        },
      },
    });
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  const hasReachedPenultimatePage = async () => {
    await onFetchMoreClicked();
  };

  return (
    <Box>
      <Box my={2}>
        <Paper>
          <AllUsersTable
            hasReachedPenultimatePage={hasReachedPenultimatePage}
            users={users}
          />
          <Box
            alignItems={'flex-end'}
            display={'flex'}
            justifyContent={'flex-end'}
            pb={1}
            pr={1}
          >
            <Box ml={2} />
            <Typography variant={'h6'}>Total: {totalCount}</Typography>
          </Box>
        </Paper>
      </Box>
      <UserExport users={users} />
    </Box>
  );
};
