import React from 'react';
import { Box } from '../../../webui/Box';
import { Rating } from '../../../webui/Rating';
import { Typography } from '../../../webui/Typography';

interface Props {
  amount: number;
  numberOfRatings: number;
  value: number;
}

export const RatingItem: React.FC<Props> = ({
  amount,
  numberOfRatings,
  value,
}) => {
  return (
    <Box flexDirection={'row'} display={'flex'} alignItems={'center'}>
      <Rating value={value} readOnly={true} />
      <Box ml={1}>
        <Typography>
          {amount > 0
            ? `${Math.floor((amount / numberOfRatings) * 100)}% (${amount})`
            : '(0)'}
        </Typography>
      </Box>
    </Box>
  );
};
