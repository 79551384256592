import { useTranslation } from 'react-i18next';
import { Product } from '../../../common/types/product';

export const useTranslatedProduct = () => {
  const { t } = useTranslation();
  return {
    tDescription: (product: Product, lng?: string) =>
      // @ts-ignore
      t('products.description.' + product.translationKey, product.description, {
        lng,
      }),
    tName: (product: Product, lng?: string) =>
      t('products.name.' + product.translationKey, product.name, { lng }),
  };
};
