import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthUser } from './AuthUser';

interface AuthenticationState {
  currentUser?: AuthUser;
}

const initialState: AuthenticationState = { currentUser: undefined };

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setCurrentUser(state, action: PayloadAction<AuthUser>) {
      state.currentUser = action.payload;
    },
    removeCurrentUser(state) {
      state.currentUser = undefined;
    },
  },
});

export const {
  setCurrentUser: setCurrentUserAction,
  removeCurrentUser: removeCurrentUserAction,
} = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
