import * as L from 'leaflet';
import { LatLngExpression, Marker as MarkerType } from 'leaflet';
import React, { useMemo, useRef, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';

interface Props {
  initialPosition: LatLngExpression;
  onPositionChange: (lat: number, long: number) => void;
}
const boxMarker = L.icon({
  iconUrl: '/markers/box-marker.svg',
  iconAnchor: [20, 50],
  popupAnchor: [0, -55],
});

export const DraggableMarker: React.FC<Props> = ({
  initialPosition,
  onPositionChange,
}) => {
  const [position, setPosition] = useState<LatLngExpression>(initialPosition);
  const markerRef = useRef<MarkerType>(null);
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          setPosition(marker.getLatLng());
          onPositionChange(marker.getLatLng().lat, marker.getLatLng().lng);
        }
      },
    }),
    [onPositionChange]
  );
  return (
    <Marker
      icon={boxMarker}
      draggable={true}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
    >
      <Popup minWidth={90}>Required?</Popup>
    </Marker>
  );
};
