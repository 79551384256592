import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { Alert } from '../../webui/Alert';
import { Snackbar, SnackbarOnClose } from '../../webui/Snackbar';
import {
  NotificationPosition,
  removeNotificationAction,
} from './notificationsSlice';

interface Props {}

const defaultAnchorOrigin: NotificationPosition = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const Notification: React.FC<Props> = () => {
  const notification = useAppSelector(
    (state) => state.notifications.notification
  );

  const dispatch = useAppDispatch();

  const onClose: SnackbarOnClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(removeNotificationAction());
  };

  const onIconClose = () => {
    dispatch(removeNotificationAction());
  };

  if (!notification) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={notification.position ?? defaultAnchorOrigin}
      open={!!notification}
      autoHideDuration={10000}
      onClose={onClose}
      message={notification.message}
    >
      <div>
        <Alert onClose={onIconClose} severity={notification.severity}>
          {notification.message}
        </Alert>
      </div>
    </Snackbar>
  );
};
