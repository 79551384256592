import { Rental } from '../../../common/types/rental';

export interface ProductsPieData {
  product: string;
  count: number;
}

export const generateProductsPieData = (
  rentals: Rental[]
): ProductsPieData[] => {
  return Object.entries<number>(
    rentals.reduce<Record<string, number>>((acc, rental) => {
      const product = rental.product.name;
      if (acc[product]) {
        acc[product] = acc[product] + 1;
      } else {
        acc[product] = 1;
      }
      return acc;
    }, {})
  ).map(([key, value]) => {
    return {
      product: key,
      count: Math.round((value / rentals.length) * 100),
    };
  });
};
