import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { BoxLink } from '../../common/router_links/BoxLink';
import { Routes } from '../../common/Routes';
import { Organisation } from '../../common/types/organisation';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';

interface Props {
  organisations: Organisation[];
}

export interface OrganisationsData {
  boxes: Organisation['boxes'];
  id: string;
  name: string;
}

const organisationHeadCells: TableHeadCell<OrganisationsData>[] = [
  {
    id: 'name',
    label: 'Name',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.ORGANISATION, {
          id: row['id'],
        })}
      >
        {row['name']}
      </Link>
    ),
  },
  {
    id: 'boxes',
    label: 'Boxes',
    render: (row) => (
      <Box display={'flex'}>
        {row.boxes.map((box, index) => (
          <Box display={'flex'} key={box.id} ml={index !== 0 ? 2 : 0}>
            <BoxLink boxId={box.id} linkText={box.name} />
          </Box>
        ))}
      </Box>
    ),
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (organisations: Organisation[]): OrganisationsData[] => {
  return organisations.map((organisation) => ({
    boxes: organisation.boxes,
    id: organisation.id,
    name: organisation.name,
  }));
};

const organisationComparator: Comparator<OrganisationsData> = (
  order,
  orderBy
) => {
  return getComparator(order, orderBy);
};

export const AllOrganisationsTable: React.FC<Props> = ({ organisations }) => {
  return (
    <SortablePaginatedTable
      getComparator={organisationComparator}
      title={'Organisations'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'name'}
      rows={createRows(organisations)}
      tableHeadCells={organisationHeadCells}
    />
  );
};
