import React, { useState } from 'react';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { EditPriceStructureDrawer } from './EditPriceStructureDrawer';
import { usePriceStructure } from './gql/usePriceStructure';
import { PriceStructureTable } from './PriceStructureTable';

interface Props {}

export const PricePage: React.FC<Props> = () => {
  const { data: priceStructure, loading } = usePriceStructure({});
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  if (!priceStructure) {
    return <div>Could not find user information.</div>;
  }

  return (
    <Paper>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            {priceStructure && (
              <PriceStructureTable priceStructure={priceStructure} />
            )}
          </Box>
          <Box>
            <Button onClick={onOpen}>Edit</Button>
          </Box>
        </Box>
      </Paper>
      <EditPriceStructureDrawer
        priceStructure={priceStructure}
        open={open}
        onClose={onClose}
      />
    </Paper>
  );
};
