import {
  Organisation,
  OrganisationBox,
  OrganisationUser,
} from '../common/types/organisation';
import {
  GQL_OrganisationBoxFragment,
  GQL_OrganisationFragment,
  GQL_OrganisationUserFragment,
} from '../generated/graphql';
import { transformLocation } from './transformLocation';
import { transformRole } from './transformRole';

export const transformOrganisationUser = (
  user: GQL_OrganisationUserFragment
): OrganisationUser => ({
  id: user.id,
  email: user.email ?? undefined,
  firstName: user.firstName ?? undefined,
  lastName: user.lastName ?? undefined,
  roles: user.roles.map(transformRole),
});

export const transformOrganisationBox = (
  box: GQL_OrganisationBoxFragment
): OrganisationBox => ({
  id: box.id,
  name: box.name,
  location: transformLocation(box.location),
});

export const transformOrganisation = (
  organisation: GQL_OrganisationFragment
): Organisation => ({
  id: organisation.id,
  name: organisation.name,
  city: organisation.city ?? undefined,
  contactEmail: organisation.contactEmail,
  contactName: organisation.contactName,
  country: organisation.country ?? undefined,
  phoneNumber: organisation.phoneNumber ?? undefined,
  zipCode: organisation.zipCode ?? undefined,
  users: organisation.users.map(transformOrganisationUser),
  boxes: organisation.boxes.map(transformOrganisationBox),
});
