import { Typography } from '@mui/material';
import React, { useState } from 'react';
import Select, { Props, StylesConfig } from 'react-select';
import { Box } from '../Box';
import { INPUT_WIDTH } from './utils';

export interface Option {
  label: string;
  value: string;
}

export interface MultiSearchSelectProps extends Props<Option, true> {
  label?: string;
}

export interface SearchSelectProps extends Props<Option, false> {
  label?: string;
}

const searchSelectStyles: StylesConfig<Option, boolean> = {
  control: (provided) => ({
    minWidth: INPUT_WIDTH,
    ...provided,
  }),
  menu: (provided) => ({
    minWidth: INPUT_WIDTH,
    ...provided,
    width: 'auto',
    whiteSpace: 'nowrap',
    zIndex: 2,
  }),
};

const multiSearchSelectStyles: StylesConfig<Option, boolean> = {
  control: (provided) => ({
    width: INPUT_WIDTH,
    ...provided,
  }),
  menu: (provided) => ({
    width: INPUT_WIDTH,
    ...provided,
    whiteSpace: 'nowrap',
    zIndex: 2,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#d3e3fc' : '#E7EDEE', // Background for selected value
    borderRadius: '4px',
    padding: '2px 4px',
    margin: '2px',
  }),
};

export const SearchSelect: React.FC<SearchSelectProps> = ({
  label,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Box>
      {label && <Typography>{label}</Typography>}
      {/* // @ts-ignore */}
      <Select
        {...props}
        styles={searchSelectStyles}
        onInputChange={(v, action) => {
          if (action.action !== 'set-value') {
            setInputValue(v);
          }
        }}
        inputValue={inputValue}
        placeholder={'Välj'}
      />
    </Box>
  );
};

export const MultiSearchSelect: React.FC<MultiSearchSelectProps> = ({
  label,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <Box>
      {label && <Typography>{label}</Typography>}
      {/* // @ts-ignore */}
      <Select
        isMulti={true}
        {...props}
        styles={multiSearchSelectStyles}
        onInputChange={(v, action) => {
          if (action.action !== 'set-value') {
            setInputValue(v);
          }
        }}
        placeholder={'Välj'}
        inputValue={inputValue}
      />
    </Box>
  );
};
