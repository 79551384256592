import { BoxLite } from '../../../common/types/box';
import {
  CompartmentLite,
  CompartmentProduct,
} from '../../../common/types/compartment';
import { getLatestRental } from '../../rentals/utils/getLatestRental';

export interface CompartmentsThatNeedsActionData {
  id: string;
  box: BoxLite;
  compartment: CompartmentLite;
  product?: CompartmentProduct;
  date?: string;
  productStatus?: CompartmentProduct['productStatus'];
}

export const createCompartmentsThatNeedsActionData = (
  boxes: BoxLite[]
): CompartmentsThatNeedsActionData[] => {
  const lockedCompartments: CompartmentsThatNeedsActionData[] = [];
  boxes
    .filter((box) => box.active)
    .forEach((box) => {
      box.compartments
        .filter((compartment) => !compartment.private)
        .forEach((compartment) => {
          if (compartment.locked || !compartment.product) {
            lockedCompartments.push({
              id: box.id + compartment.id,
              box: box,
              compartment: compartment,
              date: getLatestRental(compartment.rentals)?.rentEnd ?? '',
              product: compartment.product,
              productStatus: compartment.product?.productStatus,
            });
          }
        });
    });
  return lockedCompartments;
};
