import { PaymentChargeInformation } from '../../../common/types/rental';

export const resolvePaymentStatus = (
  paymentChargeInformation: PaymentChargeInformation | undefined
): string => {
  if (paymentChargeInformation) {
    switch (paymentChargeInformation.type) {
      case 'success':
        return `success - ${paymentChargeInformation.status}`;
      case 'fail':
        return `fail - ${paymentChargeInformation.status}${
          paymentChargeInformation.code
            ? ` ${paymentChargeInformation.code}`
            : ''
        }`;
      case 'free_rent':
        return 'free rent';
    }
  }
  return '';
};
