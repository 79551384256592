import * as Apollo from '@apollo/client';
import {
  GQL_AllPendingUsersQuery,
  GQL_AllPendingUsersQueryVariables,
  useAllPendingUsersQuery,
} from '../../../generated/graphql';
import { transformPendingUser } from '../../../gql/transformPendingUser';

export const useAllPendingUsers = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllPendingUsersQuery,
    GQL_AllPendingUsersQueryVariables
  >
) => {
  const { data, ...rest } = useAllPendingUsersQuery(baseOptions);
  return {
    ...rest,
    data: data?.pendingUser?.allPendingUsers.map(transformPendingUser) ?? [],
  };
};
