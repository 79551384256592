import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { Box } from '../../webui/Box';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';
import {
  AllSponsorsDocument,
  useAddSponsorMutation,
} from '../../generated/graphql';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { useNotification } from '../../common/notifications/useNotification';

interface AddSponsorProps {}

interface SponsorForm {
  imageName: string;
  name: string;
  url: string;
}

export const AddSponsor: React.FC<AddSponsorProps> = () => {
  const memoizedInitialForm = useMemo<SponsorForm>(
    () => ({
      imageName: '',
      name: '',
      url: '',
    }),
    []
  );
  const { form, reset, updateField } = useForm<SponsorForm>(
    memoizedInitialForm
  );

  // TODO: error handling
  const [addSponsor, { loading }] = useAddSponsorMutation();

  const { showNotification } = useNotification();

  const onImageNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('imageName', value);
  };

  const onUrlChange: TextFieldProps['onChange'] = (value) => {
    updateField('url', value);
  };

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onSave = async () => {
    try {
      const result = await addSponsor({
        variables: {
          addSponsor: {
            imageName: form.imageName,
            name: form.name,
            url: form.url,
          },
        },
        refetchQueries: [{ query: AllSponsorsDocument }],
      });
      reset();
      showNotification({
        message: 'Sponsor added.',
        severity: 'success',
      });
      console.log('Sponsor added!', result?.data?.addSponsor?.sponsor);
    } catch (e) {
      showNotification({
        message: 'Could not add sponsor.',
        severity: 'error',
      });
      console.log('Sponsor add error', e, '--- e');
    }
  };

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add sponsor</Typography>
      <Box mt={2}>
        <TextField label={'Name'} onChange={onNameChange} value={form.name} />
      </Box>
      <Box mt={2}>
        <TextField
          label={'Image name'}
          onChange={onImageNameChange}
          value={form.imageName}
        />
      </Box>
      <Box mt={2}>
        <TextField label={'Url'} onChange={onUrlChange} value={form.url} />
      </Box>
      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={loading} onClick={onSave} />
      </Box>
    </Box>
  );
};
