import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import {
  AllOrganisationsDocument,
  useAddOrganisationMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { SaveButton } from '../../webui/buttons/SaveButton';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllBoxesForSelect } from '../box/gql/useAllBoxesForSelect';
import { useAllUsersForSelect } from '../user/gql/useAllUsersForSelect';
import { EditOrganisationContact } from './EditOrganisationContact';
import { EditOrganisationDetails } from './EditOrganisationDetails';

interface AddOrganisationProps {}

export interface OrganisationForm {
  boxes: Option[];
  city?: string;
  contactEmail: string;
  contactName: string;
  country?: string;
  name: string;
  phoneNumber?: string;
  users: Option[];
  zipCode?: string;
}

export const AddOrganisation: React.FC<AddOrganisationProps> = () => {
  const memoizedInitialForm = useMemo<OrganisationForm>(
    () => ({
      boxes: [],
      city: '',
      contactEmail: '',
      contactName: '',
      country: '',
      name: '',
      phoneNumber: '',
      users: [],
      zipCode: '',
    }),
    []
  );
  const { form, reset, updateField } = useForm<OrganisationForm>(
    memoizedInitialForm
  );

  // TODO: error handling
  const [
    addOrganisation,
    { loading: addOrganisationLoading },
  ] = useAddOrganisationMutation();
  const { data: allBoxes, loading: allBoxesLoading } = useAllBoxesForSelect();
  const { data: allUsers, loading: allUsersLoading } = useAllUsersForSelect();

  const { showNotification } = useNotification();

    const onBoxesChange: MultiSearchSelectProps['onChange'] = (value) => {
      updateField('boxes', value.slice());
    };
    const onUsersChange: MultiSearchSelectProps['onChange'] = (value) => {
      updateField('users', value.slice());
    };

  const onSave = async () => {
    try {
      const result = await addOrganisation({
        variables: {
          organisation: {
            boxIds: form.boxes.map((box) => box.value),
            city: form.city,
            contactEmail: form.contactEmail,
            contactName: form.contactName,
            country: form.country,
            name: form.name,
            phoneNumber: form.phoneNumber,
            userIds: form.users.map((organisation) => organisation.value),
            zipCode: form.zipCode,
          },
        },
        refetchQueries: [{ query: AllOrganisationsDocument }],
      });
      reset();
      showNotification({
        message: 'Organisation added.',
        severity: 'success',
      });
      console.log(
        'Organisation added!',
        result?.data?.addOrganisation?.organisation
      );
    } catch (e) {
      showNotification({
        message: 'Could not add organisation.',
        severity: 'error',
      });
      console.log('Organisation add error', e, '--- e');
    }
  };

  if (allUsersLoading || allBoxesLoading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add organisation</Typography>
      <Box mt={2}>
        <EditOrganisationDetails form={form} updateField={updateField} />
      </Box>
      <Box mt={2}>
        <EditOrganisationContact form={form} updateField={updateField} />
      </Box>
      <Box mt={2}>
        <MultiSearchSelect
          closeMenuOnSelect={false}
          label={'Boxes'}
          onChange={onBoxesChange}
          options={allBoxes.map((box) => ({
            label: box.name,
            value: box.id,
          }))}
          value={form.boxes}
        />
      </Box>
      <Box mt={2}>
        <MultiSearchSelect
          closeMenuOnSelect={false}
          label={'Users'}
          onChange={onUsersChange}
          options={allUsers.map((user) => ({
            label: user.email ?? '',
            value: user.id,
          }))}
          value={form.users}
        />
      </Box>
      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={addOrganisationLoading} onClick={onSave} />
      </Box>
    </Box>
  );
};
