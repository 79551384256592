import {
  GQL_AllSponsorsQuery,
  GQL_AllSponsorsQueryVariables,
  useAllSponsorsQuery,
} from '../../../generated/graphql';
import * as Apollo from '@apollo/client';
import { transformSponsor } from '../../../gql/transformSponsor';

export const useAllSponsors = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllSponsorsQuery,
    GQL_AllSponsorsQueryVariables
  >
) => {
  const { data, ...rest } = useAllSponsorsQuery(baseOptions);
  return {
    ...rest,
    data: data?.sponsor?.allSponsors.map(transformSponsor) ?? [],
  };
};
