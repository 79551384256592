import {
  GQL_PriceStructureQuery,
  GQL_PriceStructureQueryVariables,
  usePriceStructureQuery,
} from '../../../generated/graphql';
import * as Apollo from '@apollo/client';
import { transformPriceStructure } from '../../../gql/transformPriceStructure';

export const usePriceStructure = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_PriceStructureQuery,
    GQL_PriceStructureQueryVariables
  >
) => {
  const { data, ...rest } = usePriceStructureQuery(baseOptions);
  return {
    ...rest,
    data: data?.payment?.priceStructure
      ? transformPriceStructure(data.payment.priceStructure)
      : undefined,
  };
};
