import * as Apollo from '@apollo/client';
import {
  GQL_ProductByIdQuery,
  GQL_ProductByIdQueryVariables,
  useProductByIdQuery,
} from '../../../generated/graphql';
import { transformProduct } from '../../../gql/transformProduct';

export const useProductById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_ProductByIdQuery,
    GQL_ProductByIdQueryVariables
  > &
    (
      | { variables: GQL_ProductByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useProductByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.product?.productById
      ? transformProduct(data?.product?.productById)
      : undefined,
  };
};
