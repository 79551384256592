import React, { useState } from 'react';
import { addDays, formatDate, subDays } from '../../common/utils/dateUtils';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useAllBoxesWithoutRentals } from '../box/gql/useAllBoxesWithoutRentals';
import { useAllOrganisations } from '../organisation/gql/useAllOrganisations';
import { useAllProducts } from '../product/gql/useAllProducts';
import { StatisticsRentalFilter } from './filter/StatisticsRentalFilter';
import { Filter } from './filter/types';
import { StatisticsRental } from './rentals/StatisticsRental';

interface Props {}

const initialFromDate = formatDate(subDays(new Date(), 14));
const initialEndDate = formatDate(addDays(new Date(), 1));

export const StatisticsRentalsPage: React.FC<Props> = () => {
  const { data: products, loading: productsLoading } = useAllProducts();
  const { data: allBoxes, loading: allBoxesLoading } =
    useAllBoxesWithoutRentals();
  const { data: allOrganisations, loading: allOrganisationsLoading } =
    useAllOrganisations();

  const [filter, setFilter] = useState<Filter>({
    selectedBoxes: [],
    fromDate: initialFromDate,
    selectedProducts: [],
    selectedGenders: [],
    onlyActiveBoxes: true,
    endDate: initialEndDate,
  });

  const onFilterChange = (filter: Filter) => {
    setFilter(filter);
  };

  if (allBoxesLoading || productsLoading || allOrganisationsLoading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Container>
      <StatisticsRentalFilter
        allBoxes={allBoxes}
        allProducts={products}
        allOrganisations={allOrganisations}
        initialFilter={filter}
        onFilterChange={onFilterChange}
      />
      <Box mt={2} />
      <StatisticsRental allBoxes={allBoxes} filter={filter} />
    </Container>
  );
};
