import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { AllRolesDocument, useAddRoleMutation } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { SaveButton } from '../../webui/buttons/SaveButton';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllPermissions } from '../permission/gql/useAllPermissions';

interface AddRoleProps {}

interface RoleForm {
  name: string;
  permissions: Option[];
}

export const AddRole: React.FC<AddRoleProps> = () => {
  const memoizedInitialForm = useMemo<RoleForm>(
    () => ({
      name: '',
      permissions: [],
    }),
    []
  );
  const { form, reset, updateField } = useForm<RoleForm>(memoizedInitialForm);

  // TODO: error handling
  const [addRole, { loading: addRoleLoading }] = useAddRoleMutation();

  const { data, loading: loadingPermissions } = useAllPermissions();

  const { showNotification } = useNotification();

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onPermissionsChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('permissions', value.slice());
  };

  const onSave = async () => {
    try {
      const result = await addRole({
        variables: {
          addRole: {
            name: form.name,
            permissionIds: form.permissions.map((p) => p.value),
          },
        },
        refetchQueries: [{ query: AllRolesDocument }],
      });
      reset();
      showNotification({
        message: 'Role added.',
        severity: 'success',
      });
      console.log('Role added!', result?.data?.addRole?.role);
    } catch (e) {
      showNotification({
        message: 'Could not add role.',
        severity: 'error',
      });
      console.log('Role add error', e, '--- e');
    }
  };

  if (loadingPermissions) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add role</Typography>
      <Box mt={2}>
        <TextField label={'Name'} onChange={onNameChange} value={form.name} />
      </Box>
      <Box mt={2}>
        <MultiSearchSelect
          closeMenuOnSelect={false}
          label={'Permissions'}
          onChange={onPermissionsChange}
          options={data.map((permission) => ({
            value: permission.id,
            label: permission.type,
          }))}
          value={form.permissions}
        />
      </Box>
      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={addRoleLoading} onClick={onSave} />
      </Box>
    </Box>
  );
};
