import { Rental } from '../../../common/types/rental';
import { isValidDate } from '../../../common/utils/dateUtils';

export const getCurrentRental = (rentals: Rental[]): Rental | undefined => {
  if (rentals.length === 0) {
    return undefined;
  }
  return rentals.filter(
    (rental) => rental.rentEnd === undefined && isValidDate(rental.rentStart)
  )[0];
};
