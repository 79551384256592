import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { EditSponsorDrawer } from './EditSponsorDrawer';
import { useSponsorById } from './gql/useSponsorById';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Sponsor } from './Sponsor';
import { Paper } from '../../webui/Paper';
import { Container } from "../../webui/Container";

interface Props {}

export const SponsorPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: sponsor, loading, error } = useSponsorById({
    variables: { id },
  });
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!sponsor) {
    return <div>Could not find sponsor information.</div>;
  }

  return (
    <Container >
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <Sponsor sponsor={sponsor} />
          </Box>
          <Box>
            <Button onClick={onOpen}>Edit</Button>
          </Box>
        </Box>
      </Paper>
      <EditSponsorDrawer sponsor={sponsor} open={open} onClose={onClose} />
    </Container>
  );
};
