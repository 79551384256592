import {
  Box,
  BoxBase,
  BoxColor,
  BoxLite,
  BoxWithoutRentals,
} from '../common/types/box';
import { exhaustSwitchCaseWithFallback } from '../common/utils/exhaustSwitchCase';
import {
  GQL_BoxColor,
  GQL_BoxFragment,
  GQL_BoxLiteFragment,
  GQL_BoxWithoutRentalsFragment,
} from '../generated/graphql';
import {
  transformCompartment,
  transformCompartmentLite,
  transformCompartmentWithoutRentals,
} from './transformCompartment';
import { transformLocation } from './transformLocation';
import { transformSponsor } from './transformSponsor';

export const transformBoxColor = (color: GQL_BoxColor): BoxColor => {
  switch (color) {
    case GQL_BoxColor.Hex903373:
      return '#B40A7A';
    case GQL_BoxColor.Hex2f3234:
      return '#002231';
    default:
      return exhaustSwitchCaseWithFallback(color, '#B40A7A');
  }
};
export const transformBoxColorToGQL = (color: BoxColor): GQL_BoxColor => {
  switch (color) {
    case '#B40A7A':
      return GQL_BoxColor.Hex903373;
    case '#002231':
      return GQL_BoxColor.Hex2f3234;
    default:
      return exhaustSwitchCaseWithFallback(color, GQL_BoxColor.Hex903373);
  }
};

const transformBoxWithoutCompartments = (
  box: Omit<GQL_BoxFragment, 'compartments'>
): BoxBase => ({
  id: box.id,
  active: box.active,
  circuitBoardId: box.circuitBoardId,
  color: transformBoxColor(box.color),
  name: box.name,
  location: transformLocation(box.location),
  openStartTime: box.openStartTime,
  openEndTime: box.openEndTime,
  priceWeight: box.priceWeights,
  sponsor: box.sponsor ? transformSponsor(box.sponsor) : undefined,
  deprecated: box.deprecated,
  initialActivationTime: box.initialActivationTime ?? undefined,
});

export const transformBox = (box: GQL_BoxFragment): Box => {
  return {
    ...transformBoxWithoutCompartments(box),
    compartments: box.compartments.map(transformCompartment),
  };
};

export const transformBoxLite = (box: GQL_BoxLiteFragment): BoxLite => {
  return {
    ...transformBoxWithoutCompartments(box),
    compartments: box.compartments.map(transformCompartmentLite),
  };
};

export const transformBoxWithoutRentals = (
  box: GQL_BoxWithoutRentalsFragment
): BoxWithoutRentals => {
  return {
    ...transformBoxWithoutCompartments(box),
    compartments: box.compartments.map(transformCompartmentWithoutRentals),
  };
};
