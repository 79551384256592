import React from 'react';
import { Rental } from '../../common/types/rental';
import {
  formatISO,
  getTimeDifferenceInMinutes,
  toDisplayDateTime,
} from '../../common/utils/dateUtils';
import { Typography } from '../../webui/Typography';
import { getLatestRental } from '../rentals/utils/getLatestRental';

interface RentalInformationProps {
  rentals: Rental[];
}

export const RentalInformation: React.FC<RentalInformationProps> = ({
  rentals,
}) => {
  const latestRental = getLatestRental(rentals);

  const rentBy = latestRental?.rentBy.email;

  const rentStart = latestRental?.rentStart
    ? toDisplayDateTime(latestRental.rentStart)
    : '';
  const rentTimeInMinutes = getTimeDifferenceInMinutes(
    latestRental?.rentStart,
    latestRental?.rentEnd ?? formatISO(new Date())
  );

  return (
    <>
      {rentBy ? (
        <>
          {rentStart &&
            (latestRental?.rentEnd ? (
              <Typography>
                "{rentBy}" started his renting {rentStart} and rented for{' '}
                {rentTimeInMinutes} min
              </Typography>
            ) : (
              <Typography>
                "{rentBy}" started his renting {rentStart} and has been renting
                for {rentTimeInMinutes} min
              </Typography>
            ))}
        </>
      ) : (
        <Typography>This compartment has never been rented</Typography>
      )}
    </>
  );
};
