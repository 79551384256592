import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Table } from '../../webui/table/Table';
import { TableBody } from '../../webui/table/TableBody';
import { TableCell } from '../../webui/table/TableCell';
import { TableContainer } from '../../webui/table/TableContainer';
import { TableHead } from '../../webui/table/TableHead';
import { TableRow } from '../../webui/table/TableRow';
import { Typography } from '../../webui/Typography';
import { useAllOngoingRentals } from '../rentals/gql/useAllOngoingRentals';
import { createNotStoppedRentalsData } from './utils/createNotStoppedRentalsData';

interface NotStoppedRentalsProps {}

export const NotStoppedRentals: React.FC<NotStoppedRentalsProps> = () => {
  const { data: rentals, loading, error } = useAllOngoingRentals();
  const rows = createNotStoppedRentalsData(rentals);

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <>
      {rows.length > 0 ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Rent start</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <Link
                      component={RouterLink}
                      to={generatePath(Routes.RENTAL, {
                        id: row.id,
                      })}
                    >
                      {row.id}
                    </Link>
                  </TableCell>
                  <TableCell>{toDisplayDateTime(row.rentStart)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box mt={1}>
          <Typography>---</Typography>
        </Box>
      )}
    </>
  );
};
