export type ProductStatus =
  | 'Ok'
  | 'MissingParts'
  | 'Dirty'
  | 'Broken'
  | 'Lost'
  | 'Other';

export const productStatuses: ProductStatus[] = [
  'Ok',
  'MissingParts',
  'Dirty',
  'Broken',
  'Lost',
  'Other',
];

export const productStatusItems: { label: string; value: ProductStatus }[] = [
  { label: 'Ok', value: 'Ok' },
  { label: 'Missing parts', value: 'MissingParts' },
  { label: 'Dirty', value: 'Dirty' },
  { label: 'Broken', value: 'Broken' },
  { label: 'Lost', value: 'Lost' },
  { label: 'Other', value: 'Other' },
];
