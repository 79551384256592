import { Permission } from '../../../common/types/permission';
import { Role } from '../../../common/types/role';
import { User } from '../../../common/types/user';

export interface GroupedPermission {
  permission: Permission;
  roles: Role[];
}

export const getCalculatedPermissions = (user: User) => {
  const groupedPermissions: GroupedPermission[] = user.permissions.map(
    (permission) => ({
      permission,
      roles: [],
    })
  );

  for (const role of user.roles) {
    for (const permission of role.permissions) {
      const gp = groupedPermissions.find(
        (p) => p.permission.type === permission.type
      );
      if (gp) {
        gp.roles.push(role);
      } else {
        groupedPermissions.push({
          permission,
          roles: [role],
        });
      }
    }
  }

  return groupedPermissions;
};
