import { GQL_ProductStatus } from '../generated/graphql';
import { ProductStatus } from '../common/types/productStatus';

export const transformProductStatusFromGQL = (
  productStatusAfter: GQL_ProductStatus
): ProductStatus => {
  switch (productStatusAfter) {
    case GQL_ProductStatus.Ok:
      return 'Ok';
    case GQL_ProductStatus.MissingParts:
      return 'MissingParts';
    case GQL_ProductStatus.Dirty:
      return 'Dirty';
    case GQL_ProductStatus.Broken:
      return 'Broken';
    case GQL_ProductStatus.Lost:
      return 'Lost';
    case GQL_ProductStatus.Other:
      return 'Other';
  }
};

export const transformProductStatusToGQL = (
  productStatus: ProductStatus
): GQL_ProductStatus => {
  switch (productStatus) {
    case 'Ok':
      return GQL_ProductStatus.Ok;
    case 'MissingParts':
      return GQL_ProductStatus.MissingParts;
    case 'Dirty':
      return GQL_ProductStatus.Dirty;
    case 'Broken':
      return GQL_ProductStatus.Broken;
    case 'Lost':
      return GQL_ProductStatus.Lost;
    case 'Other':
      return GQL_ProductStatus.Other;
  }
};
