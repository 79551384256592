import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { EditActivityAreaDrawer } from './EditActivityDrawer';
import { useActivityAreaById } from './gql/useActivityAreaById';

interface Props {}

export const ActivityArea: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: activityArea, loading, error } = useActivityAreaById({
    variables: { id },
  });
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!activityArea) {
    return <div>Could not find activityArea information.</div>;
  }
  return (
    <Container>
      <Paper>
        <Box
          m={2}
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <Typography variant={'h3'}>{activityArea.type}</Typography>
            <Typography>{activityArea.location?.name}</Typography>
            <Box marginTop={1}>
              <Typography>
                {activityArea.active ? 'Active' : 'Not active'}
              </Typography>
            </Box>
            <Box>
              <Typography>
                {activityArea.verified ? 'Verified' : 'Not verified'}
              </Typography>
            </Box>
            <Box marginTop={1}>
              <Typography>{activityArea.activities.join(', ')}</Typography>
            </Box>
            <Box marginTop={1}>
              <Typography>
                {activityArea.areaInfos
                  .map((areaInfo) => areaInfo.count + ' ' + areaInfo.type)
                  .join(', ')}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button onClick={onOpen}>Edit</Button>
          </Box>
        </Box>
      </Paper>
      <EditActivityAreaDrawer
        activityArea={activityArea}
        open={open}
        onClose={onClose}
      />
    </Container>
  );
};
