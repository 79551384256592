import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import {
  AllRolesDocument,
  useAddPendingUserMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { SaveButton } from '../../webui/buttons/SaveButton';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { TextArea } from '../../webui/inputs/TextArea';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllOrganisations } from '../organisation/gql/useAllOrganisations';
import { useAllRoles } from '../role/gql/useAllRoles';

interface Props {}

interface PendingUserForm {
  email: string;
  roles: Option[];
  organisations: Option[];
}

export const AddPendingUser: React.FC<Props> = () => {
  const memoizedInitialForm = useMemo<PendingUserForm>(
    () => ({
      email: '',
      roles: [],
      organisations: [],
    }),
    []
  );
  const { form, reset, updateField } = useForm<PendingUserForm>(
    memoizedInitialForm
  );

  // TODO: error handling
  const [
    addPendingUser,
    { loading: addPendingUserLoading },
  ] = useAddPendingUserMutation();
  const {
    data: allOrganisations,
    loading: loadingOrganisations,
  } = useAllOrganisations();
  const { data: allRoles, loading: loadingRoles } = useAllRoles();

  const { showNotification } = useNotification();

  const onEmailChange: StandardInputProps['onChange'] = (value) => {
    updateField('email', value.target.value);
  };

  const onOrganisationsChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('organisations', value.slice());
  };

  const onRolesChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('roles', value.slice());
  };

  const onSave = async () => {
    try {
      await addPendingUser({
        variables: {
          pendingUser: {
            emails: form.email.split('\n'),
            organisations: form.organisations.map((p) => p.value),
            roles: form.roles.map((p) => p.value),
          },
        },
        refetchQueries: [{ query: AllRolesDocument }],
      });
      reset();
      showNotification({
        message: 'Pending user added.',
        severity: 'success',
      });
    } catch (e) {
      showNotification({
        message: 'Could not add pending user.',
        severity: 'error',
      });
    }
  };

  if (loadingOrganisations || loadingRoles) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add pending user</Typography>
      <Box mt={2}>
        <Typography>Emails</Typography>
        <TextArea
          onChange={onEmailChange}
          value={form.email}
          minRows={5}
          cols={50}
          placeholder={'example@email.com\nexample2@email.com\n...'}
        />
      </Box>
      <Box mt={2}>
        <MultiSearchSelect
          closeMenuOnSelect={false}
          label={'Roles'}
          onChange={onRolesChange}
          options={allRoles.map((role) => ({
            value: role.id,
            label: role.name,
          }))}
          value={form.roles}
        />
      </Box>
      <Box mt={2}>
        <MultiSearchSelect
          closeMenuOnSelect={false}
          label={'Organisations'}
          onChange={onOrganisationsChange}
          options={allOrganisations.map((organisation) => ({
            value: organisation.id,
            label: organisation.name,
          }))}
          value={form.organisations}
        />
      </Box>
      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={addPendingUserLoading} onClick={onSave} />
      </Box>
    </Box>
  );
};
