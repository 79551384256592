import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { RentalsTable } from '../rentals/RentalsTable';
import { CompartmentAlert } from './CompartmentAlert';
import { EditCompartmentDrawer } from './edit/EditCompartmentDrawer';
import { useCompartmentById } from './gql/useCompartmentById';
import { RentalInformation } from './RentalInformation';

interface CompartmentPageProps {}

export const CompartmentPage: React.FC<CompartmentPageProps> = () => {
  const { compartmentId } = useParams<{ compartmentId: string }>();
  const { data: compartment, error, loading } = useCompartmentById({
    variables: { id: compartmentId },
  });

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (!compartment) {
    return <div>Could not find compartment information.</div>;
  }

  return (
    <>
      <Box mt={2} mb={2}>
        <Paper>
          {compartment.product && (
            <CompartmentAlert
              locked={compartment.locked ?? false}
              productStatus={compartment.product.productStatus}
            />
          )}
          <Box
            p={2}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <Box>
              <Box>
                <Typography variant={'h4'}>{compartment.name}</Typography>
              </Box>
              <Box mt={2}>
                <Typography>
                  {compartment.private
                    ? `This compartment is private`
                    : 'This compartment is public'}
                </Typography>
              </Box>
              <Box mt={1}>
                <Typography>
                  Current product: {compartment.product?.name}
                </Typography>
              </Box>
              <Box mt={1}>
                <RentalInformation rentals={compartment.rentals} />
              </Box>
              <Box mt={1} alignItems={'center'} display={'flex'}>
                <Typography>{compartment.product?.productStatus}</Typography>
              </Box>
            </Box>
            <Box>
              <Button onClick={onOpen}>Edit</Button>
            </Box>
          </Box>
        </Paper>
      </Box>
      <EditCompartmentDrawer
        compartment={compartment}
        open={open}
        onClose={onClose}
      />
      <RentalsTable rentals={compartment.rentals} />
    </>
  );
};
