import React from 'react';
import { Box } from '../../webui/Box';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { useBoxById } from './gql/useBoxById';
import ProductChangeTable from './ProductChangeTable';

interface Props {
  boxId: string;
}

export const ProductChange: React.FC<Props> = ({ boxId }) => {
  const {
    data: box,
    loading,
    error,
  } = useBoxById({
    variables: { id: boxId },
  });

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Box
      alignItems={'center'}
      justifyContent={'space-between'}
      display={'flex'}
      p={2}
    >
      <ProductChangeTable
        rentals={
          box?.compartments.flatMap((compartment) => compartment.rentals) ?? []
        }
      />
    </Box>
  );
};
