import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { PaymentCard } from '../../common/types/paymentInformation';
import { UserLite } from '../../common/types/user';
import { compareDate, toDisplayDateTime } from '../../common/utils/dateUtils';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';

interface Props {
  hasReachedPenultimatePage?: () => void;
  users: UserLite[];
}

export interface UsersData {
  id: string;
  accountCreated?: string;
  externalId: string;
  email: string;
  numberOfRentals: number;
  paymentCard?: PaymentCard;
  priceWeight: {
    id: string;
    name: string;
  };
  roles: string;
  name: string;
}

const userHeadCells: TableHeadCell<UsersData>[] = [
  {
    id: 'id',
    label: 'Id',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.USER, {
          id: row['id'],
        })}
      >
        {row['id']}
      </Link>
    ),
    maxWidth: 50,
  },
  {
    id: 'externalId',
    label: 'External id',
    render: (value) => value['externalId'],
  },
  {
    id: 'email',
    label: 'Email',
    render: (value) => value['email'] || 'Anonymized user',
  },
  {
    id: 'name',
    label: 'Name',
    render: (value) => value['name'],
  },
  {
    id: 'roles',
    label: 'Roles',
    render: (value) => value['roles'],
  },
  {
    id: 'paymentCard',
    label: 'Card',
    render: (value) => {
      const card = value['paymentCard'];
      return card ? `${card.brand}, ...${card.last4}` : '';
    },
  },
  {
    id: 'numberOfRentals',
    label: 'Rentals',
    render: (value) => value['numberOfRentals'],
  },
  {
    id: 'priceWeight',
    label: 'Price weight',
    render: (row) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.PRICE_WEIGHT, {
          id: row['priceWeight'].id,
        })}
      >
        {row['priceWeight'].name}
      </Link>
    ),
  },
  {
    id: 'accountCreated',
    label: 'Account created',
    render: (value) => toDisplayDateTime(value.accountCreated) || '',
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (users: UserLite[]): UsersData[] => {
  return users.map((user) => {
    return {
      id: user.id,
      accountCreated: user.accountCreated,
      externalId: user.externalId ?? '',
      email: user.email ?? '',
      paymentCard: user.paymentInformation?.card,
      priceWeight: user.priceWeight,
      numberOfRentals: user.numberOfRentals,
      roles: user.roles.map((role) => role.name).join(', '),
      name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
    };
  });
};

const userComparator: Comparator<UsersData> = (order, orderBy) => {
  if (orderBy === 'accountCreated') {
    return (userA, userB) => compareDate(userA[orderBy], userB[orderBy], order);
  }
  return getComparator(order, orderBy);
};

export const AllUsersTable: React.FC<Props> = ({
  hasReachedPenultimatePage,
  users,
}) => {
  return (
    <SortablePaginatedTable
      getComparator={userComparator}
      hasReachedPenultimatePage={hasReachedPenultimatePage}
      title={'Users'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'accountCreated'}
      initialOrder={'desc'}
      rows={createRows(users)}
      searchEnabled={false}
      tableHeadCells={userHeadCells}
    />
  );
};
