import MUILink, { LinkTypeMap } from '@mui/material/Link';
import { LinkOwnProps } from '@mui/material/Link/Link';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

export const Link: OverridableComponent<LinkTypeMap> = (
  props: LinkOwnProps
) => {
  return <MUILink underline={'none'} {...props} />;
};
