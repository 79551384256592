import { Box } from '../../../common/types/box';
import { sortWith } from '../../../common/utils/sortWith';

const nameComparator = <T extends Pick<Box, 'name'>>(a: T, b: T): number => {
  if (a.name === b.name) {
    return 0;
  }
  return a.name > b.name ? 1 : -1;
};

export const sortBoxes = <T extends Pick<Box, 'name'>>(boxes: T[]): T[] => {
  return sortWith(nameComparator)(boxes);
};
