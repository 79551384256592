export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CreateCreditCardResponse: [
      'CreateCreditCardFailResponse',
      'CreateCreditCardSuccessResponse',
    ],
    CreateCreditCardResponseInterface: [
      'CreateCreditCardFailResponse',
      'CreateCreditCardSuccessResponse',
    ],
    PaymentChargeInformation: [
      'PaymentChargeInformationFail',
      'PaymentChargeInformationFreeRent',
      'PaymentChargeInformationSuccess',
    ],
    PaymentChargeInformationInterface: [
      'PaymentChargeInformationFail',
      'PaymentChargeInformationFreeRent',
      'PaymentChargeInformationSuccess',
    ],
  },
};
export default result;
