import * as Apollo from '@apollo/client';
import {
  GQL_AllBoxesQuery,
  GQL_AllBoxesQueryVariables,
  useAllBoxesQuery,
} from '../../../generated/graphql';
import { transformBox } from '../../../gql/transformBox';

export const useAllBoxes = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBoxesQuery,
    GQL_AllBoxesQueryVariables
  >
) => {
  const { data, ...rest } = useAllBoxesQuery(baseOptions);
  return {
    ...rest,
    data: data?.box?.allBoxes.map(transformBox) ?? [],
  };
};
