import { PriceStructure } from '../features/price/PriceStructure';
import { GQL_PriceStructureFragment } from '../generated/graphql';

export const transformPriceStructure = (
  priceStructure: GQL_PriceStructureFragment
): PriceStructure => ({
  id: priceStructure.id,
  start: priceStructure.start,
  firstHour: priceStructure.firstHour,
  secondHour: priceStructure.secondHour,
  thirdHour: priceStructure.thirdHour,
  fourthHour: priceStructure.fourthHour,
  fifthHour: priceStructure.fifthHour,
  sixthHour: priceStructure.sixthHour,
  penalty: priceStructure.penalty,
});
