import { BatteryReading } from '../../../common/types/batteryReading';
import { filterUndefinedCoordinates } from '../../../common/utils/filterUndefinedCoordinates';
import { BatteryStatusMarker } from '../types/batteryStatusMarker';

export const resolveBatteryStatuses = (
  batteryReadings: BatteryReading[]
): BatteryStatusMarker[] => {
  return filterUndefinedCoordinates(
    batteryReadings.map((batteryReading) => {
      return {
        coordinates: batteryReading.box.location.coordinates,
        id: batteryReading.box.id,
        title: `Box ${batteryReading.box.name}, voltage: ${
          batteryReading.voltage / 1000
        }`,
        voltage: batteryReading.voltage,
      };
    })
  );
};
