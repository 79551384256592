import * as Apollo from '@apollo/client';
import {
  GQL_RoleByIdQuery,
  GQL_RoleByIdQueryVariables,
  useRoleByIdQuery,
} from '../../../generated/graphql';
import { transformRole } from '../../../gql/transformRole';

export const useRoleById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_RoleByIdQuery,
    GQL_RoleByIdQueryVariables
  > &
    (
      | { variables: GQL_RoleByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useRoleByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.role?.roleById
      ? transformRole(data?.role?.roleById)
      : undefined,
  };
};
