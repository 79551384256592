import * as Apollo from '@apollo/client';
import {
  GQL_AllUsersQuery,
  GQL_AllUsersQueryVariables,
  useAllUsersQuery,
} from '../../../generated/graphql';
import { transformUserLite } from '../../../gql/transformUser';

export const useAllUsers = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllUsersQuery,
    GQL_AllUsersQueryVariables
  >
) => {
  const { data, ...rest } = useAllUsersQuery({
    ...baseOptions,
  });
  return {
    ...rest,
    data: {
      totalCount: data?.user?.allUsers.totalCount ?? 0,
      users: data?.user?.allUsers.nodes.map(transformUserLite) ?? [],
    },
  };
};
