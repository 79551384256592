import React, { useState } from 'react';
import { UserLite } from '../../common/types/user';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { CheckboxWithLabel } from '../../webui/inputs/CheckboxWithLabel';
import { Paper } from '../../webui/Paper';
import { ToggleOpen } from '../../webui/ToggleOpen';
import { Typography } from '../../webui/Typography';
import { allKeys, generateUsersCsv, UserKey } from './util/generateUsersCsv';

interface Props {
  users: UserLite[];
}

export const UserExport: React.FC<Props> = ({ users }) => {
  const [open, setOpen] = useState(false);
  const [keysToUse, setKeysToUse] = useState<UserKey[]>([]);

  const onChange = (key: UserKey, addKey: boolean) => {
    if (addKey) {
      setKeysToUse([...keysToUse, key]);
    } else {
      const indexOfKey = keysToUse.indexOf(key);
      setKeysToUse([
        ...keysToUse.slice(0, indexOfKey),
        ...keysToUse.slice(indexOfKey + 1),
      ]);
    }
  };

  const downloadCsv = () => {
    const csv = generateUsersCsv(users, keysToUse);
    const a = document.createElement('a');
    a.href = `data:text/csv;base64,${btoa(csv)}`;
    a.download = 'user_export.csv';
    a.click();
    a.remove();
  };

  const onToggle = () => setOpen((o) => !o);
  return (
    <Paper>
      <Box p={3}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Typography variant="h5" component="div">
            Columns to export
          </Typography>
          <ToggleOpen open={open} onToggle={onToggle} />
        </Box>
        {open && (
          <>
            <Box display={'flex'} flexDirection={'column'}>
              {allKeys.map((key) => (
                <Box flex={1} key={key}>
                  <CheckboxWithLabel
                    onChange={(value) => onChange(key, value)}
                    value={keysToUse.includes(key)}
                    label={key}
                  />
                </Box>
              ))}
            </Box>
            <Button onClick={downloadCsv}>Export as csv</Button>
          </>
        )}
      </Box>
    </Paper>
  );
};
