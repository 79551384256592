import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { Permission } from '../../common/types/permission';
import { Role } from '../../common/types/role';
import { User as UserType } from '../../common/types/user';
import { AllUsersDocument, useEditUserMutation } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { SelectInputProps } from '../../webui/inputs/Select';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllPermissions } from '../permission/gql/useAllPermissions';
import { PriceWeightSelect } from '../price/PriceWeightSelect';
import { useAllRoles } from '../role/gql/useAllRoles';

interface EditUserDrawerProps {
  user: UserType;
  open: boolean;
  onClose: () => void;
}

interface EditUserForm {
  priceWeight: string;
  permissions: Option[];
  roles: Option[];
}

const transformPermission = (permission: Permission): Option => ({
  label: permission.type,
  value: permission.id,
});
const transformRole = (role: Role): Option => ({
  label: role.name,
  value: role.id,
});

const createInitialEditUserForm = (user: UserType): EditUserForm => ({
  priceWeight: user.priceWeight.id,
  permissions: user.permissions.map(transformPermission),
  roles: user.roles.map(transformRole),
});

// TODO: Form handling, validation, disable save etc
export const EditUserDrawer: React.FC<EditUserDrawerProps> = ({
  user,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditUserForm(user), [user])
  );

  const [editUser, { loading }] = useEditUserMutation();

  const {
    data: allPermissions,
    loading: loadingAllPermissions,
  } = useAllPermissions();

  const { data: allRoles, loading: loadingAllRoles } = useAllRoles();

  const { showNotification } = useNotification();

  const onPriceWeightChange: SelectInputProps<string>['onChange'] = (e) => {
    updateField('priceWeight', e.target.value);
  };
  const onPermissionsChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('permissions', value.slice());
  };
  const onRolesChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('roles', value.slice());
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editUser({
        variables: {
          userId: user.id,
          editUser: {
            priceWeight: form.priceWeight || undefined,
            permissions: form.permissions.map((p) => p.value),
            roles: form.roles.map((r) => r.value),
          },
        },
        refetchQueries: [{ query: AllUsersDocument }],
      });
      showNotification({
        message: 'User edited.',
        severity: 'success',
      });
      console.log('User edited!', result?.data?.editUser?.user);
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit user.',
        severity: 'error',
      });
      console.log('User edit error', e, '--- e');
    }
  };

  if (loadingAllPermissions || loadingAllRoles) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit user "{user.email}"</Typography>
        <form noValidate autoComplete="off">
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <Box mt={2} />
            <PriceWeightSelect
              id={'edit-user-priceWeight'}
              value={form.priceWeight}
              onChange={onPriceWeightChange}
            />
          </Box>
          <Box mt={2}>
            <MultiSearchSelect
              closeMenuOnSelect={false}
              isMulti
              label={'Roles'}
              onChange={onRolesChange}
              options={allRoles.map(transformRole)}
              value={form.roles}
            />
            <MultiSearchSelect
              closeMenuOnSelect={false}
              isMulti
              label={'Permissions'}
              onChange={onPermissionsChange}
              options={allPermissions.map(transformPermission)}
              value={form.permissions}
            />
          </Box>
        </form>
        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={loading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
