import { useCreateRentalImageGetUrlMutation } from '../../../generated/graphql';
import { useEffect } from 'react';

export const useImageUrl = (id: string) => {
  const [
    createImageGetUrl,
    { data: imageUrlData, error, loading },
  ] = useCreateRentalImageGetUrlMutation({ variables: { id } });

  useEffect(() => {
    createImageGetUrl();
  }, [createImageGetUrl, id]);

  return {
    createImageGetUrl,
    loading,
    imageUrl: imageUrlData?.createRentalImageGetUrl?.imageUrl ?? undefined,
    error,
  };
};
