import { Product } from '../../../common/types/product';
import { compareDate } from '../../../common/utils/dateUtils';
import { exhaustSwitchCase } from '../../../common/utils/exhaustSwitchCase';
import {
  Comparator,
  descendingComparator,
  getComparator,
} from '../../../webui/table/sortable-paginated-table/utils';
import { AllRentalData } from '../types';

const productComparator = (order: 'asc' | 'desc') =>
  order === 'desc'
    ? (a: { product: Product }, b: { product: Product }) =>
        descendingComparator(a.product.name, b.product.name)
    : (a: { product: Product }, b: { product: Product }) =>
        -descendingComparator(a.product.name, b.product.name);

export const rentalComparator: Comparator<AllRentalData> = (order, orderBy) => {
  switch (orderBy) {
    case 'id':
      break;
    case 'aborted':
      break;
    case 'box':
      return order === 'desc'
        ? (a, b) => descendingComparator(a.box.name, b.box.name)
        : (a, b) => -descendingComparator(a.box.name, b.box.name);
    case 'compartment':
      return order === 'desc'
        ? (a, b) => descendingComparator(a.compartment.name, b.compartment.name)
        : (a, b) =>
            -descendingComparator(a.compartment.name, b.compartment.name);
    case 'maxRentTimeInMinutes':
      break;
    case 'paymentChargeInformation':
      break;
    case 'product':
      return productComparator(order);
    case 'productStatus':
      break;
    case 'productStatusText':
      break;
    case 'numberOfFailedOpenings':
      break;
    case 'numberOfSuccessfulOpenings':
      break;
    case 'rentBy':
      return order === 'desc'
        ? (a, b) => descendingComparator(a.rentBy.email, b.rentBy.email)
        : (a, b) => -descendingComparator(a.rentBy.email, b.rentBy.email);
    case 'rentStartBy':
      return order === 'desc'
        ? (a, b) =>
            descendingComparator(a.rentStartBy.email, b.rentStartBy.email)
        : (a, b) =>
            -descendingComparator(a.rentStartBy.email, b.rentStartBy.email);
    case 'rentEndBy':
      break;
    case 'rentStart':
      return (a, b) => compareDate(a.rentStart, b.rentStart, order);
    case 'rentEnd':
      return (a, b) => compareDate(a.rentEnd, b.rentEnd, order);
    case 'rentTime':
      break;
    case 'reviewRating':
      break;
    case 'reviewFreetext':
      break;
    case 'review':
      return order === 'desc'
        ? (a, b) =>
            descendingComparator(a.review?.rating ?? 0, b.review?.rating ?? 0)
        : (a, b) =>
            -descendingComparator(a.review?.rating ?? 0, b.review?.rating ?? 0);
    case 'copyRental':
    case 'postBoxAction':
      break;
    default:
      return exhaustSwitchCase(orderBy);
  }
  return getComparator(order, orderBy);
};
