import React from 'react';
import { Form } from '../../common/hooks/useForm';
import { Box } from '../../webui/Box';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';
import { OrganisationForm } from './AddOrganisation';

interface Props {
  form: OrganisationForm;
  updateField: Form<OrganisationForm>['updateField'];
}

export const EditOrganisationContact: React.FC<Props> = ({
  form,
  updateField,
}) => {
  const onContactEmailChange: TextFieldProps['onChange'] = (value) => {
    updateField('contactEmail', value);
  };
  const onContactNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('contactName', value);
  };
  const onPhoneNumberChange: TextFieldProps['onChange'] = (value) => {
    updateField('phoneNumber', value);
  };

  return (
    <Box>
      <Typography variant={'h5'}>Contact</Typography>
      <Box display={'inline-flex'} flexDirection={'row'}>
        <Box>
          <TextField
            label={'Contact email'}
            onChange={onContactEmailChange}
            value={form.contactEmail}
          />
        </Box>
        <Box ml={2}>
          <TextField
            label={'Contact name'}
            onChange={onContactNameChange}
            value={form.contactName}
          />
        </Box>
        <Box ml={2}>
          <TextField
            label={'Phone number'}
            onChange={onPhoneNumberChange}
            value={form.phoneNumber}
          />
        </Box>
      </Box>
    </Box>
  );
};
