import { useTheme } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllSponsors } from './gql/useAllSponsors';
import { Sponsor } from './Sponsor';
import { sortSponsors } from './utils/sortSponsors';

interface Props {}

export const AllSponsors: React.FC<Props> = () => {
  const theme = useTheme();
  const { data: sponsors, loading, error } = useAllSponsors();

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <Container>
      <Box
        p={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Box>
          <Typography>
            There are now currently {sponsors.length} sponsors for people to
            fool around with!
          </Typography>
        </Box>
        <Box>
          <Link component={RouterLink} to={Routes.ADD_SPONSOR}>
            Add new sponsor
          </Link>
        </Box>
      </Box>
      <Box flexGrow={1}>
        {sortSponsors(sponsors).map((sponsor) => (
          <Paper
            sx={{
              marginBottom: theme.spacing(2),
              padding: theme.spacing(2),
              textAlign: 'center',
              color: theme.palette.text.secondary,
            }}
            key={sponsor.id}
          >
            <Sponsor sponsor={sponsor} />
          </Paper>
        ))}
      </Box>
    </Container>
  );
};
