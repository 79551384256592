import { Order } from './types';

export const descendingComparator = <T>(a: T, b: T): number => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined || a === null) {
    return 1;
  }
  if (b === undefined || b === null) {
    return -1;
  }
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
};

export type Comparator<T> = (
  order: Order,
  orderBy: keyof T
) => (a: T, b: T) => number;

export const getComparator = <T>(
  order: Order,
  orderBy: keyof T
): ((a: T, b: T) => number) =>
  order === 'desc'
    ? (a: T, b: T) => descendingComparator(a[orderBy], b[orderBy])
    : (a: T, b: T) => -descendingComparator(a[orderBy], b[orderBy]);

export const getNumericalComparator = <T extends { name?: string }>(
  order: Order,
  orderBy: keyof T
) => {
  return (a: T, b: T) => {
    const numA = Number(a.name);
    const numB = Number(b.name);

    if (!isNaN(numA) && !isNaN(numB)) {
      return order === 'asc' ? numA - numB : numB - numA;
    }

    return getComparator<T>(order, orderBy)(a, b);
  };
};

export const stableSort = <T>(
  array: T[],
  comparator: (a: T, b: T) => number
): T[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getRowsPerPageOptions = (
  initialRowsPerPage: number,
  numberOfRows: number
): number[] => {
  if (numberOfRows <= initialRowsPerPage) {
    return [];
  }
  const options = [];

  if (numberOfRows > 0) {
    options.push(initialRowsPerPage);
  }
  if (numberOfRows > initialRowsPerPage) {
    if (numberOfRows < initialRowsPerPage * 2) {
      options.push(numberOfRows);
    } else {
      options.push(initialRowsPerPage * 2);
    }
  }
  if (numberOfRows > initialRowsPerPage * 2) {
    if (numberOfRows < initialRowsPerPage * 3) {
      options.push(numberOfRows);
    } else {
      options.push(initialRowsPerPage * 3);
    }
  }

  if (options[options.length - 1] < numberOfRows) {
    options.push(numberOfRows);
  }

  return options;
};

export const getSearchFilter =
  <T>(searchTerm: string) =>
  (row: T) =>
    // @ts-ignore
    Object.values(row).some((v) =>
      String(v).toLowerCase().includes(searchTerm.toLowerCase())
    );
