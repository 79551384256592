import {
  Compartment,
  CompartmentLite,
  CompartmentWithoutRentals,
} from '../common/types/compartment';
import {
  GQL_CompartmentFragment,
  GQL_CompartmentLiteFragment,
  GQL_CompartmentWithRentalsFragment,
} from '../generated/graphql';
import { transformCompartmentProduct } from './transformCompartmentProduct';
import { transformRental } from './transformRental';

export const transformCompartment = (
  gqlCompartment: GQL_CompartmentWithRentalsFragment
): Compartment => ({
  ...transformCompartmentWithoutRentals(gqlCompartment),
  rentals: gqlCompartment.rentals.map(transformRental),
});

export const transformCompartmentLite = (
  gqlCompartment: GQL_CompartmentLiteFragment
): CompartmentLite => ({
  ...transformCompartmentWithoutRentals(gqlCompartment),
  rentals: gqlCompartment.rentals.map((rental) => ({
    id: rental.id,
    rentEnd: rental.rentEnd ?? undefined,
  })),
});

export const transformCompartmentWithoutRentals = (
  gqlCompartment: GQL_CompartmentFragment
): CompartmentWithoutRentals => ({
  id: gqlCompartment.id,
  circuitBoardPort: gqlCompartment.circuitBoardPort,
  locked: gqlCompartment.locked ?? false,
  private: gqlCompartment.private ?? false,
  product: transformCompartmentProduct(gqlCompartment.product),
  name: gqlCompartment.name ?? undefined,
});
