import React from 'react';
import { BoxLink } from '../../common/router_links/BoxLink';
import { BoxLite } from '../../common/types/box';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import { getLatestRental } from '../rentals/utils/getLatestRental';

interface BoxesTableProps {
  boxes: BoxLite[];
}

export interface BoxData {
  id: string;
  name: string;
  latestRental: string;
  compartments: number;
  circuitBoardId: string;
  active: boolean;
  deprecated: boolean;
  sponsor: string;
  initialActivationTime: string;
}

const boxHeadCells: TableHeadCell<BoxData>[] = [
  {
    id: 'name',
    label: 'Name',
    render: (row) => <BoxLink boxId={row.id} linkText={row.name} />,
  },
  {
    id: 'compartments',
    label: 'Compartments',
    render: (row) => row['compartments'],
    maxWidth: 50,
  },
  {
    id: 'circuitBoardId',
    label: 'Circuit board id',
    render: (row) => row['circuitBoardId'],
  },
  {
    id: 'active',
    label: 'Active',
    render: (row) => (row['active'] ? 'yes' : 'no'),
  },
  {
    id: 'deprecated',
    label: 'Deprecated',
    render: (row) => (row['deprecated'] ? 'yes' : ''),
  },
  {
    id: 'latestRental',
    label: 'Latest rental',
    render: (row) => toDisplayDateTime(row.latestRental),
  },
  {
    id: 'initialActivationTime',
    label: 'Initial activation time',
    render: (row) => toDisplayDateTime(row['initialActivationTime']),
  },
  {
    id: 'sponsor',
    label: 'Sponsor',
    render: (row) => row['sponsor'],
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (boxes: BoxLite[]): BoxData[] =>
  boxes.map((box) => {
    const latestRental = getLatestRental(
      box.compartments.flatMap((compartment) => compartment.rentals)
    );

    return {
      id: box.id,
      name: resolveBoxDisplayName(box),
      priceWeight: box.priceWeight,
      compartments: box.compartments.length,
      circuitBoardId: box.circuitBoardId,
      active: box.active,
      deprecated: box.deprecated,
      sponsor: box.sponsor?.name ?? '',
      latestRental: latestRental?.rentEnd ?? '',
      initialActivationTime: box.initialActivationTime ?? '',
    };
  });

export const BoxesTable: React.FC<BoxesTableProps> = ({ boxes }) => (
  <Box my={2}>
    <Paper>
      <SortablePaginatedTable
        title={'Boxes'}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
        initialOrderByKey={'name'}
        tableHeadCells={boxHeadCells}
        rows={createRows(boxes)}
      />
    </Paper>
  </Box>
);
