import { LatLngExpression } from 'leaflet';
import React from 'react';
import { DraggableMarker } from './DraggableMarker';
import { GenericMap } from './GenericMap';

interface Props {
  onPositionChange: (lat: number, long: number) => void;
  initialMarkerPosition: LatLngExpression;
}

export const EditableMap: React.FC<Props> = ({
  initialMarkerPosition,
  onPositionChange,
}) => {
  return (
    <GenericMap initialPosition={initialMarkerPosition}>
      <DraggableMarker
        initialPosition={initialMarkerPosition}
        onPositionChange={onPositionChange}
      />
    </GenericMap>
  );
};
