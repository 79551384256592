import React, { useState } from 'react';
import { SearchFilter } from '../../common/filter/SearchFilter';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { RentalActionsTable } from './RentalActionsTable';

interface Props {}

export const RentalActionsTableWithSearch: React.FC<Props> = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const onSearchChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  return (
    <Paper>
      <Box pl={2} pt={1}>
        <SearchFilter
          label={'Box, product or rented by contains'}
          onSearchChange={onSearchChange}
        />
      </Box>
      <RentalActionsTable searchTerm={searchTerm} />
    </Paper>
  );
};
