import * as React from 'react';
import { useAllRentals } from '../rentals/gql/useAllRentals';
import { RentalsTable } from '../rentals/RentalsTable';

interface Props {}

export const RentalFeedbacksTable: React.FC<Props> = () => {
  const {
    data: { rentals },
    loading,
    error,
  } = useAllRentals({
    variables: {
      filter: {
        hasTextFeedback: true,
      },
    },
  });
  return (
    <RentalsTable
      error={error}
      loading={loading}
      rentals={rentals}
      title={'Rentals with feedback'}
    />
  );
};
