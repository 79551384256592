import { BatteryReading } from '../../../common/types/batteryReading';

export type SortBy =
  | 'usage'
  | 'voltageHighToLow'
  | 'voltageLowToHigh'
  | 'boxName';

export const boxNameComparator = (
  box1: BatteryReading[],
  box2: BatteryReading[]
) => {
  if (box1[0].box.name > box2[0].box.name) {
    return 1;
  }
  if (box2[0].box.name > box1[0].box.name) {
    return -1;
  }
  return 0;
};

export const voltageComparator = (orderBy: 'asc' | 'desc') => (
  box1: BatteryReading[],
  box2: BatteryReading[]
) => {
  if (box1[box1.length - 1].voltage > box2[box2.length - 1].voltage) {
    return orderBy === 'asc' ? 1 : -1;
  }
  if (box2[box2.length - 1].voltage > box1[box1.length - 1].voltage) {
    return orderBy === 'asc' ? -1 : 1;
  }
  return 0;
};

export const usageComparator = (
  box1: BatteryReading[],
  box2: BatteryReading[]
) => {
  if (box1[box1.length - 1].createdAt > box2[box2.length - 1].createdAt) {
    return -1;
  }
  if (box2[box2.length - 1].createdAt > box1[box1.length - 1].createdAt) {
    return 1;
  }
  return 0;
};

export const getBatteryComparator = (sortBy: SortBy) => {
  switch (sortBy) {
    case 'usage':
      return usageComparator;
    case 'boxName':
      return boxNameComparator;
    case 'voltageHighToLow':
      return voltageComparator('desc');
    case 'voltageLowToHigh':
      return voltageComparator('asc');
  }
};
