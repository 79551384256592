import React, { useReducer } from 'react';
import { useNotification } from '../../../common/notifications/useNotification';
import { Box as BoxType } from '../../../common/types/box';
import { Compartment } from '../../../common/types/compartment';
import { ProductStatus } from '../../../common/types/productStatus';
import { resolveCircuitBoardPort } from '../../../common/utils/resolveCircuitBoardPort';
import { nameComparator } from '../../../common/utils/sort/comparators/nameComparator';
import { useEditCompartmentsMutation } from '../../../generated/graphql';
import { transformProductStatusToGQL } from '../../../gql/transformProductStatus';
import { Box } from '../../../webui/Box';
import { Button } from '../../../webui/Button';
import { SaveButton } from '../../../webui/buttons/SaveButton';
import { Checkbox, CheckboxProps } from '../../../webui/Checkbox';
import { Drawer } from '../../../webui/Drawer';
import { TextField, TextFieldProps } from '../../../webui/inputs/TextField';
import { Table } from '../../../webui/table/Table';
import { TableBody } from '../../../webui/table/TableBody';
import { TableCell } from '../../../webui/table/TableCell';
import { TableContainer } from '../../../webui/table/TableContainer';
import { TableHead } from '../../../webui/table/TableHead';
import { TableRow } from '../../../webui/table/TableRow';
import { ProductSelect, ProductSelectProps } from '../../product/ProductSelect';
import {
  ProductStatusSelect,
  ProductStatusSelectProps,
} from '../../product/ProductStatusSelect';

interface EditCompartmentsDrawerProps {
  open: boolean;
  onClose: () => void;
  box: BoxType;
}

export interface EditCompartment {
  id: string;
  circuitBoardPort: string;
  name: string;
  productId?: string;
  private: boolean;
  locked: boolean;
  productStatus?: ProductStatus;
}

export interface EditCompartmentsForm {
  [compartmentId: string]: EditCompartment;
}

const createCompartmentsInitialForm = (
  compartments: Compartment[]
): EditCompartmentsForm =>
  compartments.reduce(
    (acc, c) => ({
      ...acc,
      [c.id]: {
        id: c.id,
        circuitBoardPort: String(c.circuitBoardPort),
        name: c.name,
        productId: c.product?.id,
        private: c.private,
        locked: c.locked,
        productStatus: c.product?.productStatus,
      },
    }),
    {}
  );

interface EditNameAction {
  type: 'EDIT_NAME';
  value: string;
}

interface EditPortAction {
  type: 'EDIT_PORT';
  value: string;
}

interface EditProductAction {
  type: 'EDIT_PRODUCT';
  value: string;
}

interface EditProductStatusAction {
  type: 'EDIT_PRODUCT_STATUS';
  value: ProductStatus;
}

interface EditPrivateAction {
  type: 'EDIT_PRIVATE';
  value: boolean;
}

interface EditLockedAction {
  type: 'EDIT_LOCKED';
  value: boolean;
}

type EditAction = {
  compartmentId: string;
} & (
  | EditNameAction
  | EditPortAction
  | EditProductAction
  | EditProductStatusAction
  | EditPrivateAction
  | EditLockedAction
);

const reducer = (
  state: EditCompartmentsForm,
  action: EditAction
): EditCompartmentsForm => {
  const compartment = state[action.compartmentId];

  switch (action.type) {
    case 'EDIT_NAME':
      return {
        ...state,
        [action.compartmentId]: {
          ...compartment,
          name: action.value,
        },
      };
    case 'EDIT_PORT':
      return {
        ...state,
        [action.compartmentId]: {
          ...compartment,
          circuitBoardPort: action.value,
        },
      };
    case 'EDIT_PRODUCT':
      return {
        ...state,
        [action.compartmentId]: {
          ...compartment,
          productId: action.value,
        },
      };
    case 'EDIT_PRODUCT_STATUS':
      return {
        ...state,
        [action.compartmentId]: {
          ...compartment,
          productStatus: action.value,
        },
      };
    case 'EDIT_PRIVATE':
      return {
        ...state,
        [action.compartmentId]: {
          ...compartment,
          private: action.value,
        },
      };
    case 'EDIT_LOCKED':
      return {
        ...state,
        [action.compartmentId]: {
          ...compartment,
          locked: action.value,
        },
      };
  }
};

export const EditCompartmentsDrawer: React.FC<EditCompartmentsDrawerProps> = ({
  open,
  onClose,
  box,
}) => {
  const { showNotification } = useNotification();
  const [editCompartmentsMutation, { loading }] = useEditCompartmentsMutation();

  const [state, dispatch] = useReducer(
    reducer,
    createCompartmentsInitialForm(box.compartments)
  );

  const onNameChange =
    (compartmentId: string): TextFieldProps['onChange'] =>
    (value) => {
      dispatch({
        compartmentId,
        type: 'EDIT_NAME',
        value: value,
      });
    };

  const onPortChange =
    (compartmentId: string): TextFieldProps['onChange'] =>
    (value) => {
      dispatch({
        compartmentId,
        type: 'EDIT_PORT',
        value: value,
      });
    };

  const onProductChange =
    (compartmentId: string): ProductSelectProps['onChange'] =>
    (value: string) => {
      dispatch({
        compartmentId,
        type: 'EDIT_PRODUCT',
        value,
      });
    };

  const onProductStatusChange =
    (compartmentId: string): ProductStatusSelectProps['onChange'] =>
    (value) => {
      dispatch({
        compartmentId,
        type: 'EDIT_PRODUCT_STATUS',
        value,
      });
    };

  const onPrivateChange =
    (compartmentId: string): CheckboxProps['onChange'] =>
    (e, value) => {
      dispatch({
        compartmentId,
        type: 'EDIT_PRIVATE',
        value,
      });
    };

  const onLockedChange =
    (compartmentId: string): CheckboxProps['onChange'] =>
    (e, value) => {
      dispatch({
        compartmentId,
        type: 'EDIT_LOCKED',
        value,
      });
    };

  const onLockAll = () => {
    for (const c of box.compartments) {
      dispatch({
        compartmentId: c.id,
        type: 'EDIT_LOCKED',
        value: true,
      });
    }
  };

  const onUnlockAll = () => {
    for (const c of box.compartments) {
      dispatch({
        compartmentId: c.id,
        type: 'EDIT_LOCKED',
        value: false,
      });
    }
  };

  const onSave = async () => {
    try {
      const result = await editCompartmentsMutation({
        variables: {
          compartments: Object.values(state).map((c) => ({
            id: c.id,
            circuitBoardPort: resolveCircuitBoardPort(c.circuitBoardPort),
            name: c.name,
            locked: c.locked,
            private: c.private,
            product: c.productId
              ? {
                  productId: c.productId,
                  productStatus: c.productStatus
                    ? transformProductStatusToGQL(c.productStatus)
                    : undefined,
                }
              : undefined,
          })),
        },
      });
      showNotification({
        message: 'Compartments edited.',
        severity: 'success',
      });
      console.log(
        'Compartments edited!',
        result?.data?.editCompartments.compartments
      );
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit compartments.',
        severity: 'error',
      });
      console.log('Compartments edit error', e, '--- e');
    }
  };

  const sortedCompartments = box.compartments
    .map((c) => ({ ...c, name: c.name ?? '' }))
    .sort(nameComparator);

  const allLocked = Object.values(state).every((c) => c.locked);

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Port</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>Product Status</TableCell>
              <TableCell>Private</TableCell>
              <TableCell>Locked</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCompartments.map((c) => {
              const comp = state[c.id];

              return (
                <TableRow key={c.id}>
                  <TableCell>
                    <TextField
                      value={comp.name}
                      onChange={onNameChange(comp.id)}
                      variant={'outlined'}
                      width={60}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={comp.circuitBoardPort}
                      onChange={onPortChange(comp.id)}
                      variant={'outlined'}
                      width={60}
                    />
                  </TableCell>
                  <TableCell>
                    <ProductSelect
                      id={'product-select-compartment-' + comp.id}
                      value={comp.productId ?? ''}
                      onChange={onProductChange(comp.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <ProductStatusSelect
                      id={'product-status-select-compartment-' + comp.id}
                      value={comp.productStatus}
                      onChange={onProductStatusChange(comp.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={comp.private}
                      color="primary"
                      onChange={onPrivateChange(comp.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={comp.locked}
                      color="primary"
                      onChange={onLockedChange(comp.id)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
        <Box pr={2}>
          {allLocked ? (
            <Button onClick={onUnlockAll}>Unlock all</Button>
          ) : (
            <Button onClick={onLockAll}>Lock all</Button>
          )}
        </Box>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
        <Box m={2}>
          <Button onClick={onClose}>Cancel</Button>
        </Box>
        <Box m={2}>
          <SaveButton loading={loading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
