import React, { useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { BoxLink } from '../../common/router_links/BoxLink';
import { slottsskogenPosition } from '../../common/utils/slottsskogenPosition';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Typography } from '../../webui/Typography';
import { GenericMap, ZOOM_MANY_MARKERS } from '../location/map/GenericMap';
import { resolveBatteryStatusIcon } from './resolveBatteryStatusIcon';
import { BatteryStatusMarker } from './types/batteryStatusMarker';
import {
  HIGH_VOLTAGE_LIMIT,
  MEDIUM_VOLTAGE_LIMIT,
  resolveBatteryStatus,
} from './utils/batteryStatus';

interface Props {
  batteryStatuses: BatteryStatusMarker[];
}

export const BatteryMap: React.FC<Props> = ({ batteryStatuses }) => {
  const [hideHighBatteryStatus, setHideHighBatteryStatus] = useState(true);

  const onToggleHideHighBatteryStatus = () => {
    setHideHighBatteryStatus((prevState) => !prevState);
  };

  const batteryStatusMarkers = batteryStatuses.filter((batteryStatus) =>
    hideHighBatteryStatus ? batteryStatus.voltage < HIGH_VOLTAGE_LIMIT : true
  );
  return (
    <div>
      <Box display={'flex'} p={2} alignItems={'flex-end'}>
        <Button onClick={onToggleHideHighBatteryStatus}>
          {hideHighBatteryStatus
            ? 'Show boxes with high battery status'
            : 'Hide boxes high battery status'}
        </Button>
        <Box mr={2} />
        <Typography>
          High voltage limit: {HIGH_VOLTAGE_LIMIT / 1000}, Medium voltage limit:{' '}
          {MEDIUM_VOLTAGE_LIMIT / 1000}
        </Typography>
      </Box>
      <GenericMap
        initialPosition={
          batteryStatusMarkers.length > 0
            ? [
                batteryStatusMarkers[0].coordinates.lat,
                batteryStatusMarkers[0].coordinates.long,
              ]
            : slottsskogenPosition
        }
        zoom={ZOOM_MANY_MARKERS}
      >
        {batteryStatusMarkers.map((marker) => (
          <Marker
            icon={resolveBatteryStatusIcon(resolveBatteryStatus(marker))}
            key={marker.id}
            position={[marker.coordinates.lat, marker.coordinates.long]}
          >
            <Popup>
              <BoxLink boxId={marker.id} linkText={marker.title} />
            </Popup>
          </Marker>
        ))}
      </GenericMap>
    </div>
  );
};
