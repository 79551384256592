import { useEffect, useState } from 'react';

export interface Form<Fields> {
  form: Fields;
  touched: boolean;
  valid: boolean;
  reset: () => void;
  updateField: <T extends keyof Fields>(field: T, value: Fields[T]) => void;
  updateFields: <T extends keyof Fields>(fields: Record<T, Fields[T]>) => void;
}

export const useForm = <Fields>(initialForm: Fields): Form<Fields> => {
  const [editedForm, setEditedForm] = useState<Fields>(initialForm);
  const [valid, setValid] = useState(true);
  const [touched, setTouched] = useState(false);

  const updateField: Form<Fields>['updateField'] = (field, value) => {
    setEditedForm((state) => ({
      ...state,
      [field]: value,
    }));
  };

  const updateFields: Form<Fields>['updateFields'] = (fields) => {
    setEditedForm((state) => ({
      ...state,
      ...fields,
    }));
  };

  const reset = () => {
    setEditedForm(initialForm);
  };

  useEffect(() => {
    setEditedForm(initialForm);
    setValid(true);
    setTouched(false);
  }, [initialForm]);

  return {
    form: editedForm,
    updateField,
    updateFields,
    valid,
    touched,
    reset,
  };
};
