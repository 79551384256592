import React from 'react';
import { Avatar } from '../../webui/Avatar';
import { ListItem } from '../../webui/ListItem';
import { ListItemText } from '../../webui/ListItemText';

interface Props {
  id: string;
  label: string;
  imageName: string;
}

export const ProductItem: React.FC<Props> = ({ id, label, imageName }) => {
  return (
    <ListItem key={id} disablePadding>
      <Avatar
        alt={label}
        src={`/products/${imageName}.svg`}
        style={{ marginRight: 8 }}
        sx={{ height: '16px', width: '16px' }}
      />
      <ListItemText primary={label} />
    </ListItem>
  );
};
