import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton } from '../../webui/IconButton';
import { MenuIcon } from '../../webui/icons/MenuIcon';
import { Link } from '../../webui/Link';
import { Menu } from '../../webui/Menu';
import { MenuItem } from '../../webui/MenuItem';
import { HeaderLink } from './headerLinks';

interface Props {
  headerLinks: HeaderLink[];
}

export const ResponsivePageLinksMenu: React.FC<Props> = ({ headerLinks }) => {
  const [
    pagesMenuAnchorEl,
    setPagesMenuAnchorEl,
  ] = React.useState<null | HTMLElement>(null);
  const open = Boolean(pagesMenuAnchorEl);

  const handleOpenLoggedInMenu = (event: React.MouseEvent<HTMLElement>) => {
    setPagesMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPagesMenuAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="pages-menu"
        aria-controls="pages-menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenLoggedInMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="pages-menu-appbar"
        anchorEl={pagesMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {headerLinks.map((link) => {
          return (
            <MenuItem key={link.label}>
              <Link
                noWrap={true}
                color={'inherit'}
                component={RouterLink}
                to={link.path}
                onClick={handleClose}
              >
                {link.label}
              </Link>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
