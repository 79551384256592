import { Rental } from '../../../../common/types/rental';

export interface RentalTimeData {
  label: string;
  count: number;
}

const getRentalTime = (rental: Rental): ADataKey | undefined => {
  if (rental.rentTime) {
    if (rental.rentTime < 5) {
      return undefined;
    }
    if (rental.rentTime < 11) {
      return '0-10';
    }
    if (rental.rentTime < 21) {
      return '11-20';
    }
    if (rental.rentTime < 31) {
      return '21-30';
    }
    if (rental.rentTime < 41) {
      return '31-40';
    }
    if (rental.rentTime < 51) {
      return '41-50';
    }
    if (rental.rentTime < 61) {
      return '51-60';
    }
    if (rental.rentTime < 71) {
      return '61-70';
    }
    if (rental.rentTime < 81) {
      return '71-80';
    }
    if (rental.rentTime < 91) {
      return '81-90';
    }
    if (rental.rentTime < 101) {
      return '91-100';
    }
    if (rental.rentTime < 111) {
      return '101-110';
    }
    if (rental.rentTime < 121) {
      return '111-120';
    }
    if (rental.rentTime > 120) {
      return '121+';
    }
  }
  return undefined;
};

type ADataKey =
  | '0-10'
  | '11-20'
  | '21-30'
  | '31-40'
  | '41-50'
  | '51-60'
  | '61-70'
  | '71-80'
  | '81-90'
  | '91-100'
  | '101-110'
  | '111-120'
  | '121+';

type AData = Record<ADataKey, number>;

export const generateRentalTimeData = (rentals: Rental[]): RentalTimeData[] => {
  const data: AData = {
    '0-10': 0,
    '11-20': 0,
    '21-30': 0,
    '31-40': 0,
    '41-50': 0,
    '51-60': 0,
    '61-70': 0,
    '71-80': 0,
    '81-90': 0,
    '91-100': 0,
    '101-110': 0,
    '111-120': 0,
    '121+': 0,
  };

  for (const rental of rentals) {
    const a = getRentalTime(rental);
    if (a) {
      data[a] = data[a] + 1;
    }
  }

  return Object.entries(data).map(([label, count]) => ({
    label,
    count,
  }));
};
