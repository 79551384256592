import { Grid } from '@mui/material';
import React from 'react';
import { Rental } from '../../common/types/rental';
import { Box as MUIBox } from '../../webui/Box';
import { CardContent } from '../../webui/CardContent';
import { Typography } from '../../webui/Typography';
import { BoxCard } from './BoxCard';
import { DASHBOARD_DAYS_BACK } from './dashboardUtils';

const NUMBER_OF_Boxes = 9;

interface Props {
  boxUsage: { [key: string]: { box: Rental['box']; count: number } };
}

export const MostCommonBoxes: React.FC<Props> = ({ boxUsage }) => {
  const mostCommonProducts = Object.values(boxUsage)
    .sort((a, b) => b.count - a.count)
    .slice(0, NUMBER_OF_Boxes);

  return (
    <Grid item xs={12}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {`Top ${NUMBER_OF_Boxes} Most Used Boxes Last ${DASHBOARD_DAYS_BACK} Days`}
        </Typography>
        <MUIBox mb={2} />
        <Grid container spacing={2}>
          {mostCommonProducts.map((box, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <BoxCard box={box.box} count={box.count} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Grid>
  );
};
