import React from 'react';
import { TextField } from '../../webui/inputs/TextField';
import { Table } from '../../webui/table/Table';
import { TableBody } from '../../webui/table/TableBody';
import { TableCell } from '../../webui/table/TableCell';
import { TableContainer } from '../../webui/table/TableContainer';
import { TableHead } from '../../webui/table/TableHead';
import { TableRow } from '../../webui/table/TableRow';
import { EditPriceStructureForm } from './EditPriceStructureDrawer';

export type OnPriceStructureFieldChange = (
  key: keyof EditPriceStructureForm,
  value: string
) => void;

interface Props {
  priceStructure: EditPriceStructureForm;
  onFieldChange: OnPriceStructureFieldChange;
}

type PriceStructureTableData = {
  hour: string;
  price: number;
  key: keyof EditPriceStructureForm;
};

const createData = (
  hour: string,
  price: number,
  key: keyof EditPriceStructureForm
): PriceStructureTableData => ({
  hour,
  price,
  key,
});

const createRows = (
  priceStructure: EditPriceStructureForm
): PriceStructureTableData[] =>
  priceStructure
    ? [
        createData('Start price', parseInt(priceStructure.start, 10), 'start'),
        createData('1', parseInt(priceStructure.firstHour, 10), 'firstHour'),
        createData('2', parseInt(priceStructure.secondHour, 10), 'secondHour'),
        createData('3', parseInt(priceStructure.thirdHour, 10), 'thirdHour'),
        createData('4', parseInt(priceStructure.fourthHour, 10), 'fourthHour'),
        createData('5', parseInt(priceStructure.fifthHour, 10), 'fifthHour'),
        createData('6', parseInt(priceStructure.sixthHour, 10), 'sixthHour'),
        createData('6+', parseInt(priceStructure.penalty, 10), 'penalty'),
      ]
    : [];

export const EditPriceStructureTable: React.FC<Props> = ({
  onFieldChange,
  priceStructure,
}) => {
  const rows = createRows(priceStructure);

  return (
    <TableContainer>
      <Table sx={{ width: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Hour</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.hour}>
              <TableCell component="th" scope="row">
                {row.hour}
              </TableCell>
              <TableCell align="right">
                <TextField
                  id={`edit-price-structure${row.hour}`}
                  value={priceStructure[row.key]}
                  onChange={(value) => onFieldChange(row.key, value)}
                  variant={'outlined'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
