import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { Rental } from '../../common/types/rental';
import { filterUndefined } from '../../common/utils/filterArrays';
import { Accordion } from '../../webui/Accordion';
import { AccordionDetails } from '../../webui/AccordionDetails';
import { AccordionSummary } from '../../webui/AccordionSummary';
import { Box } from '../../webui/Box';
import { Typography } from '../../webui/Typography';
import { createPostalCodeHeatmap } from './heatmap/createPostalCodeHeatmap';
import { HeatmapLayer } from './heatmap/HeatmapLayer';
import postalCodeData from './heatmap/postalCodeData.json';
import { generateMostUsedBoxList } from './rentals/utils/generateMostUsedBoxList';
import { getSharesInPercentage } from './utils/getSharesInPercentage';

interface Props {
  rentals: Rental[];
}

const getPostalCodesFromRentals = (rentals: Rental[]): string[] => {
  return filterUndefined(rentals.map((rental) => rental.rentBy.zipCode));
};

export const PostalCodeHeatMap: React.FC<Props> = ({ rentals }) => {
  const zipCodes = getPostalCodesFromRentals(rentals);
  const generateMostUsedBoxListElement = generateMostUsedBoxList(rentals)[0];
  const centerCoordinates =
    generateMostUsedBoxListElement && generateMostUsedBoxListElement.length > 0
      ? generateMostUsedBoxListElement[1].box.location.coordinates
      : undefined;
  const heatmapData = createPostalCodeHeatmap(zipCodes, postalCodeData);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="postalCodeMap-header"
      >
        <Typography variant="h6">Heat map</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <MapContainer
            center={
              centerCoordinates
                ? [centerCoordinates.lat, centerCoordinates.long]
                : [57.6897, 11.9769]
            }
            zoom={10}
            style={{ height: '800px', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
            <HeatmapLayer heatmapData={heatmapData} />
          </MapContainer>
          <Box p={2} display={'flex'} justifyContent={'flex-end'}>
            <Typography>
              {`* ${getSharesInPercentage(
                zipCodes.length,
                rentals.length
              )}% av alla hyror är gjorda av användare som har angivit postnummer i sin profil. ${getSharesInPercentage(
                heatmapData.length / 10,
                zipCodes.length
              )}% av dessa har vi lyckats koppla till faktiskt postnummer på kartan.`}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
