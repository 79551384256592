import { Routes } from '../Routes';

export interface HeaderLink {
  path: Routes;
  label: string;
}

export const headerLinks: HeaderLink[] = [
  {
    path: Routes.ACTIONS,
    label: 'Actions',
  },
  {
    path: Routes.ALL_ACTIVITY_AREAS,
    label: 'Activity areas',
  },
  {
    path: Routes.ALL_BATTERY_VOLTAGES,
    label: 'Battery status',
  },
  {
    path: Routes.ALL_BOXES,
    label: 'Boxes',
  },
  {
    path: Routes.ALL_FEEDBACKS,
    label: 'Feedbacks',
  },
  {
    path: Routes.ALL_ORGANISATIONS,
    label: 'Organisations',
  },
  {
    path: Routes.ALL_PERMISSIONS,
    label: 'Permissions',
  },
  {
    path: Routes.PRICE,
    label: 'Price',
  },
  {
    path: Routes.ALL_PRODUCTS,
    label: 'Products',
  },
  {
    path: Routes.ALL_RENTALS,
    label: 'Rentals',
  },
  {
    path: Routes.ALL_ROLES,
    label: 'Roles',
  },
  {
    path: Routes.ALL_SPONSORS,
    label: 'Sponsors',
  },
  {
    path: Routes.STATISTICS,
    label: 'Statistics',
  },
  {
    path: Routes.ALL_USERS,
    label: 'Users',
  },
  {
    path: Routes.ALL_PENDING_USERS,
    label: 'Pending users',
  },
  {
    path: Routes.ALL_PRICE_WEIGHTS,
    label: 'Price weights',
  },
  {
    path: Routes.TRANSLATIONS,
    label: 'Translations',
  },
  {
    path: Routes.UTILS,
    label: 'Utils',
  },
];
