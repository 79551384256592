import React from 'react';
import { Box } from '../../Box';
import { TableCell } from '../TableCell';

interface Props {
  dense: boolean;
  first: boolean;
  maxWidth?: number;
}

export const SortablePaginatedTableCell: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, dense, first, maxWidth }) => {
  if (first) {
    return (
      <TableCell
        component="th"
        scope="row"
        padding={dense ? 'checkbox' : 'normal'}
      >
        <Box
          whiteSpace={'nowrap'}
          overflow={maxWidth ? 'hidden' : 'auto'}
          textOverflow={maxWidth ? 'ellipsis' : 'clip'}
          maxWidth={maxWidth}
        >
          {children}
        </Box>
      </TableCell>
    );
  } else {
    return (
      <TableCell padding={dense ? 'checkbox' : 'normal'}>
        <Box
          whiteSpace={'nowrap'}
          overflow={maxWidth ? 'hidden' : 'auto'}
          textOverflow={maxWidth ? 'ellipsis' : 'clip'}
          maxWidth={maxWidth}
        >
          {children}
        </Box>
      </TableCell>
    );
  }
};
