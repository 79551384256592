import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { EditRoleDrawer } from './EditRoleDrawer';
import { useRoleById } from './gql/useRoleById';
import { Role } from './Role';

interface Props {}

export const RolePage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: role, loading, error } = useRoleById({
    variables: { id },
  });
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!role) {
    return <div>Could not find role information.</div>;
  }

  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <Role role={role} />
          </Box>
          <Box>
            <Button onClick={onOpen}>Edit</Button>
          </Box>
        </Box>
      </Paper>
      <EditRoleDrawer role={role} open={open} onClose={onClose} />
    </Container>
  );
};
