import React from 'react';
import { PaymentChargeInformation as PaymentChargeInformationType } from '../../common/types/rental';
import { resolveDisplayPrice } from '../../common/utils/resolveDisplayPrice';
import { DataList, DataListItem } from '../../webui/DataList';

interface Props {
  paymentChargeInformation: PaymentChargeInformationType;
}

export const PaymentChargeInformation: React.FC<Props> = ({
  paymentChargeInformation,
}) => {
  let header;
  const items: DataListItem[] = [
    {
      label: 'Amount',
      value: String(
        resolveDisplayPrice(
          paymentChargeInformation.amount,
          paymentChargeInformation.currency
        )
      ),
    },
    {
      label: 'Currency',
      value: paymentChargeInformation.currency,
    },
  ];
  if (paymentChargeInformation.type === 'free_rent') {
    header = 'Free rent';
  } else if (paymentChargeInformation.type === 'success') {
    items.push(
      {
        label: 'Status',
        value: paymentChargeInformation.status,
      },
      {
        label: 'Client secret',
        value: paymentChargeInformation.clientSecret,
      }
    );
    header = 'Success';
  } else if (paymentChargeInformation.type === 'fail') {
    items.push(
      {
        label: 'Status',
        value: paymentChargeInformation.status,
      },
      {
        label: 'Client secret',
        value: paymentChargeInformation.clientSecret,
      },
      {
        label: 'Code',
        value: paymentChargeInformation.code,
      }
    );
    header = 'Fail';
  }
  return <DataList headerVariant={'h5'} header={header} items={items} />;
};
