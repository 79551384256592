import * as Apollo from '@apollo/client';
import {
  GQL_AllRentalsQuery,
  GQL_AllRentalsQueryVariables,
  useAllRentalsQuery,
} from '../../../generated/graphql';
import { transformRental } from '../../../gql/transformRental';

export const useAllRentals = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllRentalsQuery,
    GQL_AllRentalsQueryVariables
  >
) => {
  const { data, ...rest } = useAllRentalsQuery(baseOptions);
  return {
    ...rest,
    data: {
      rentals: data?.rental?.allRentals.nodes.map(transformRental) ?? [],
      totalCount: data?.rental?.allRentals.totalCount,
    },
  };
};
