import { getTimeDifferenceInMinutes } from '../../common/utils/dateUtils';
import { PriceStructure } from './PriceStructure';
import { PriceWeight } from './PriceWeight';

export const defaultPriceStructure: PriceStructure = {
  id: 'default_price_structure_id',
  start: 2500,
  firstHour: 2500,
  secondHour: 5000,
  thirdHour: 5000,
  fourthHour: 5000,
  fifthHour: 5000,
  sixthHour: 5000,
  penalty: 80000,
};

const resolveHourWeight = (
  hour: number | undefined,
  priceWeightLadder: PriceWeight
): number => {
  if (hour !== undefined && hour >= 0) {
    switch (hour) {
      case 0:
        return priceWeightLadder.firstHour;
      case 1:
        return priceWeightLadder.secondHour;
      case 2:
        return priceWeightLadder.thirdHour;
      case 3:
        return priceWeightLadder.fourthHour;
      case 4:
        return priceWeightLadder.fifthHour;
      case 5:
        return priceWeightLadder.sixthHour;
    }
  }
  return 0;
};

const resolveHourPrice = (
  hour: number | undefined,
  priceLadder: PriceStructure
): number => {
  if (hour !== undefined && hour >= 0) {
    switch (hour) {
      case 0:
        return priceLadder.firstHour;
      case 1:
        return priceLadder.secondHour;
      case 2:
        return priceLadder.thirdHour;
      case 3:
        return priceLadder.fourthHour;
      case 4:
        return priceLadder.fifthHour;
      case 5:
        return priceLadder.sixthHour;
    }
  }
  return 0;
};

export const resolvePrice = (
  priceStructure: PriceStructure,
  rentStart: string,
  rentEnd: string,
  userWeight: PriceWeight,
  boxWeight: PriceWeight,
  productWeight: PriceWeight
): number => {
  const minutes = getTimeDifferenceInMinutes(rentStart, rentEnd);
  if (minutes === undefined || minutes < 0) {
    return 0;
  }

  const startWeight =
    (userWeight.start / 100) *
    (boxWeight.start / 100) *
    (productWeight.start / 100);
  let totalPrice = priceStructure.start * startWeight;
  for (let hour = 0; hour <= Math.floor(minutes / 60); hour++) {
    const hourWeight =
      (resolveHourWeight(hour, userWeight) / 100) *
      (resolveHourWeight(hour, boxWeight) / 100) *
      (resolveHourWeight(hour, productWeight) / 100);

    const timeWeight = Math.min((minutes - hour * 60) / 60, 1);
    totalPrice +=
      resolveHourPrice(hour, priceStructure) * hourWeight * timeWeight;
  }

  if (minutes > 360) {
    const penaltyWeight =
      (userWeight.penalty / 100) *
      (boxWeight.penalty / 100) *
      (productWeight.penalty / 100);
    totalPrice += priceStructure.penalty * penaltyWeight;
  }

  return Math.round(totalPrice / 100) * 100;
};

export const resolveProductLostPrice = (
  priceStructure: PriceStructure
): number =>
  priceStructure.start +
  priceStructure.firstHour +
  priceStructure.secondHour +
  priceStructure.thirdHour +
  priceStructure.fourthHour +
  priceStructure.fifthHour +
  priceStructure.sixthHour +
  priceStructure.penalty;
