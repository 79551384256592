import React from 'react';
import { BoxColor as BoxColorType } from '../../common/types/box';
import { MenuItem } from '../../webui/inputs/MenuItem';
import {
  Select,
  SelectChangeEvent,
  SelectInputProps,
} from '../../webui/inputs/Select';
import { BoxColor } from './BoxColor';

export interface BoxColorSelectProps {
  id: string;
  value?: BoxColorType;
  onChange: (
    value: BoxColorType,
    event: SelectChangeEvent<BoxColorType | undefined>
  ) => void;
}

export const boxColorItems: { label: any; value: BoxColorType }[] = [
  {
    label: <BoxColor color={'#B40A7A'} />,
    value: '#B40A7A',
  },
  {
    label: <BoxColor color={'#002231'} />,
    value: '#002231',
  },
];

export const BoxColorSelect: React.FC<BoxColorSelectProps> = ({
  id,
  onChange,
  value,
}) => {
  const onColorChange: SelectInputProps<
    BoxColorType | undefined
  >['onChange'] = (event) => {
    onChange(event.target.value as BoxColorType, event);
  };

  return (
    <Select label={'Color'} id={id} value={value} onChange={onColorChange}>
      {boxColorItems.map((color) => (
        <MenuItem key={color.value} value={color.value}>
          {color.label}
        </MenuItem>
      ))}
    </Select>
  );
};
