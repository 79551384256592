import React from 'react';
import { Form } from '../../common/hooks/useForm';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Box } from '../../webui/Box';
import { Typography } from '../../webui/Typography';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../webui/inputs/CheckboxWithLabel';
import { ProductSelect } from '../product/ProductSelect';

interface AddCompartmentProps {
  updateField: Form<AddCompartmentForm>['updateField'];
  form: AddCompartmentForm;
  idPrefix: string;
}

export interface AddCompartmentForm {
  locked: boolean;
  circuitBoardPort: string;
  name: string;
  productId: string;
}

export const AddCompartment: React.FC<AddCompartmentProps> = ({
  updateField,
  form,
  idPrefix,
}) => {
  const onLockedChange: CheckboxWithLabelProps['onChange'] = (value) => {
    updateField('locked', value);
  };

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };
  const onCircuitBoardPortChange: TextFieldProps['onChange'] = (value) => {
    updateField('circuitBoardPort', value);
  };

  const onProductChange = (value: string) => {
    updateField('productId', value);
  };

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h6'}>Compartment {idPrefix}</Typography>
      <Box mt={2}>
        <TextField label={'Name'} onChange={onNameChange} value={form.name} />
      </Box>
      <Box mt={2}>
        <TextField
          label={'Circuit board port'}
          onChange={onCircuitBoardPortChange}
          value={form.circuitBoardPort}
        />
      </Box>
      <Box mt={2}>
        <CheckboxWithLabel
          label={'Locked'}
          labelPlacement={'start'}
          onChange={onLockedChange}
          value={form.locked}
        />
      </Box>

      <ProductSelect
        id={`${idPrefix}-add-compartment-product`}
        value={form.productId}
        onChange={onProductChange}
      />
    </Box>
  );
};
