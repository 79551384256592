import { Feedback } from '../common/types/feedback';
import { GQL_FeedbackFragment } from '../generated/graphql';
import { transformUser } from './transformUser';

export const transformFeedback = (
  feedback: GQL_FeedbackFragment
): Feedback => ({
  id: feedback.id,
  freeText: feedback.freeText,
  date: feedback.date,
  user: feedback.user ? transformUser(feedback.user) : undefined,
});
