import React from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { resolveCoordinates } from '../../common/utils/resolveCoordinates';
import {
  AllActivitiesDocument,
  GQL_AddActivityAreaResponseType,
  Maybe,
  useAddActivityAreaMutation,
} from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { MultiSelectProps } from '../../webui/inputs/MultiSelect';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { AddLocation, LocationForm } from '../location/add/AddLocation';
import { ActivitiesSelect } from './ActivitiesSelect';
import { AreaInfosForm, EditAreaInfos } from './EditAreaInfos';
import { useActivities } from './gql/useActivities';

interface AddActivityAreaProps {}

interface AddActivityAreaForm {
  type: string;
  activities: string[];
}

const initialAddActivityAreaForm: AddActivityAreaForm = {
  type: '',
  activities: [],
};

const initialLocationForm: LocationForm = {
  name: '',
  description: '',
  lat: '',
  long: '',
};

const initialAreaInfosForm: AreaInfosForm = {
  areaInfos: [],
};

const resolveErrorMessage = (
  type: Maybe<GQL_AddActivityAreaResponseType> | undefined
): string => {
  return 'Could not add activity area';
};

export const AddActivityArea: React.FC<AddActivityAreaProps> = () => {
  const { form, reset, updateField } = useForm<AddActivityAreaForm>(
    initialAddActivityAreaForm
  );
  const locationForm = useForm(initialLocationForm);
  const areaInfosForm = useForm(initialAreaInfosForm);

  // TODO: error handling
  const [addActivityArea, { loading }] = useAddActivityAreaMutation();
  const { data: activities, loading: activitiesLoading } = useActivities();

  const { showNotification } = useNotification();

  // TODO: Make select? Autocomplete text input?
  const onTypeChange: TextFieldProps['onChange'] = (value) => {
    updateField('type', value);
  };

  const onActivitiesChange: MultiSelectProps['onChange'] = (value) => {
    updateField('activities', value);
  };
  // TODO: active, verified, areaInfos, type

  const onSave = async () => {
    try {
      const result = await addActivityArea({
        variables: {
          activityAreaInput: {
            activities: form.activities,
            areaInfos: areaInfosForm.form.areaInfos,
            type: form.type,
            location: {
              description: locationForm.form.description,
              name: locationForm.form.name,
              coordinates: resolveCoordinates(
                locationForm.form.lat,
                locationForm.form.long
              ),
            },
          },
        },
        refetchQueries: [{ query: AllActivitiesDocument }],
      });
      if (result?.data?.addActivityArea?.type === 'Success') {
        reset();
        locationForm.reset();
        showNotification({
          message: 'Activity area added.',
          severity: 'success',
        });
      } else {
        showNotification({
          message: resolveErrorMessage(result?.data?.addActivityArea?.type),
          severity: 'error',
        });
      }
    } catch (e) {
      showNotification({
        message: 'Could not add activity area.',
        severity: 'error',
      });
      console.log('activity area add error', e, '--- e');
    }
  };

  return (
    <Box display={'inline-flex'} flexDirection={'column'}>
      <Typography variant={'h4'}>Add activity area</Typography>
      <Box display={'inline-flex'} flexDirection={'row'}>
        <Box>
          <TextField label={'Type'} onChange={onTypeChange} value={form.type} />
        </Box>
      </Box>
      <Box display={'inline-flex'} flexDirection={'row'}>
        {!activitiesLoading ? (
          <ActivitiesSelect
            activities={activities}
            onChange={onActivitiesChange}
          />
        ) : (
          <LoadingSpinnerContainer />
        )}
      </Box>
      <Box mt={2}>
        <EditAreaInfos
          form={areaInfosForm.form}
          updateField={areaInfosForm.updateField}
        />
      </Box>
      <Box mt={2}>
        <AddLocation
          form={locationForm.form}
          updateField={locationForm.updateField}
        />
      </Box>
      <Box mt={2} display={'flex'} alignSelf={'flex-end'}>
        <SaveButton loading={loading} onClick={onSave} />
      </Box>
    </Box>
  );
};
