import * as Apollo from '@apollo/client';
import {
  GQL_SponsorByIdQuery,
  GQL_SponsorByIdQueryVariables,
  useSponsorByIdQuery,
} from '../../../generated/graphql';
import { transformSponsor } from '../../../gql/transformSponsor';

export const useSponsorById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_SponsorByIdQuery,
    GQL_SponsorByIdQueryVariables
  > &
    (
      | { variables: GQL_SponsorByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useSponsorByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.sponsor?.sponsorById
      ? transformSponsor(data?.sponsor?.sponsorById)
      : undefined,
  };
};
