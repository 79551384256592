import React from 'react';
import { SearchFilter } from '../../common/filter/SearchFilter';
import { Box } from '../../webui/Box';
import { AllUsers } from './AllUsers';

interface Props {}

export const AllUsersWithSearch: React.FC<Props> = () => {
  const [searchTerm, setSearchTerm] = React.useState<string | undefined>();

  const onSearchChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  return (
    <Box>
      <SearchFilter label={'Email contains'} onSearchChange={onSearchChange} />
      <AllUsers searchTerm={searchTerm} />
    </Box>
  );
};
