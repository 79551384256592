import React, { useMemo } from 'react';
import { useForm } from '../../common/hooks/useForm';
import { useNotification } from '../../common/notifications/useNotification';
import { Role as RoleType } from '../../common/types/role';
import { AllRolesDocument, useEditRoleMutation } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { SaveButton } from '../../webui/buttons/SaveButton';
import { Drawer } from '../../webui/Drawer';
import {
  MultiSearchSelect,
  MultiSearchSelectProps,
  Option,
} from '../../webui/inputs/SearchSelect';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllPermissions } from '../permission/gql/useAllPermissions';

interface EditRoleDrawerProps {
  role: RoleType;
  open: boolean;
  onClose: () => void;
}

interface EditRoleForm {
  name: string;
  permissions: Option[];
}

const createInitialEditRoleForm = (role: RoleType): EditRoleForm => ({
  name: role.name,
  permissions: role.permissions.map((permission) => ({
    label: permission.type,
    value: permission.id,
  })),
});

// TODO: Form handling, validation, disable save etc
export const EditRoleDrawer: React.FC<EditRoleDrawerProps> = ({
  role,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditRoleForm(role), [role])
  );

  const [editRole, { loading: editRoleLoading }] = useEditRoleMutation();
  const {
    data: allPermissions,
    loading: allPermissionsLoading,
  } = useAllPermissions();

  const { showNotification } = useNotification();

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onPermissionsChange: MultiSearchSelectProps['onChange'] = (value) => {
    updateField('permissions', value.slice());
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editRole({
        variables: {
          roleId: role.id,
          editRole: {
            name: form.name,
            permissionIds: form.permissions.map((p) => p.value),
          },
        },
        refetchQueries: [{ query: AllRolesDocument }],
      });
      showNotification({
        message: 'Role edited.',
        severity: 'success',
      });
      console.log('Role edited!', result?.data?.editRole?.role);
      onClose();
    } catch (e) {
      showNotification({
        message: 'Could not edit role.',
        severity: 'error',
      });
      console.log('Role edit error', e, '--- e');
    }
  };

  if (allPermissionsLoading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit role "{role.name}"</Typography>
        <form noValidate autoComplete="off">
          <Box mt={2} display={'flex'} flexDirection={'column'}>
            <TextField
              id="edit-role-name"
              label="Name"
              value={form.name}
              onChange={onNameChange}
            />
            <Box mt={2} />
            <MultiSearchSelect
              closeMenuOnSelect={false}
              onChange={onPermissionsChange}
              options={allPermissions.map((permission) => ({
                value: permission.id,
                label: permission.type,
              }))}
              value={form.permissions}
            />
          </Box>
        </form>
        <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
          <Button onClick={onCancel}>Cancel</Button>
          <Box ml={2} />
          <SaveButton loading={editRoleLoading} onClick={onSave} />
        </Box>
      </Box>
    </Drawer>
  );
};
