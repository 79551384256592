import { Product } from '../../../common/types/product';
import { sortWith } from '../../../common/utils/sortWith';

const nameComparator = (a: Product, b: Product): number => {
  if (a.name === b.name) {
    return 0;
  }
  return a.name > b.name ? 1 : -1;
};

export const sortProducts = (products: Product[]): Product[] => {
  return sortWith(nameComparator)(products);
};
