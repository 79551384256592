import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { DataList } from '../../webui/DataList';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllOrganisations } from '../organisation/gql/useAllOrganisations';
import { RentalsTable } from '../rentals/RentalsTable';
import { CalculatedPermissionsList } from './CalculatedPermissionsList';
import { EditUserDrawer } from './EditUserDrawer';
import { useUserById } from './gql/useUserById';
import { OrganisationsList } from './OrganisationsList';
import { RolesList } from './RolesList';
import { UserPaymentInformation } from './UserPaymentInformation';

interface Props {}

export const UserPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: user, loading, error } = useUserById({
    variables: { id },
  });
  const {
    data: allOrganisations,
    loading: loadingAllOrganisations,
  } = useAllOrganisations();

  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  if (loading || loadingAllOrganisations) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (!user) {
    return <div>Could not find user information.</div>;
  }

  const items: { value?: string; label: string }[] = [
    {
      label: 'Id',
      value: user.id,
    },
    {
      label: 'External id',
      value: user.externalId,
    },
    {
      label: 'Email',
      value: user.email,
    },
    {
      label: 'First name',
      value: user.firstName,
    },
    {
      label: 'Last name',
      value: user.lastName,
    },
    {
      label: 'Gender',
      value: user.gender,
    },
    {
      label: 'Phone number',
      value: user.phoneNumber,
    },
    {
      label: 'Birth year',
      value: user.birthYear,
    },
    {
      label: 'Postal code',
      value: user.zipCode,
    },
    {
      label: 'Account created',
      value: toDisplayDateTime(user.accountCreated),
    },
    {
      label: 'Provider',
      value: user.provider,
    },
    {
      label: 'Role',
      value: user.roles.map((role) => role.name).join(', '),
    },
    {
      label: 'PriceWeight',
      value: user.priceWeight.name,
    },
  ];

  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <DataList
              header={'User information'}
              headerVariant={'h3'}
              items={items}
            />
            <Box>
              <Typography variant="h5">Payment information</Typography>
              {user.paymentInformation ? (
                <UserPaymentInformation
                  paymentInformation={user.paymentInformation}
                />
              ) : (
                <Typography>No payment information</Typography>
              )}
            </Box>
            <Box mt={2}>
              <Typography variant="h5">Roles</Typography>
              <RolesList user={user} />
            </Box>
            <Box mt={2}>
              <Typography variant="h5">Organisations</Typography>
              <OrganisationsList organisations={allOrganisations} user={user} />
            </Box>
            <Box mt={2}>
              <Typography variant="h5">Permissions</Typography>
              <CalculatedPermissionsList user={user} />
            </Box>
          </Box>
          <Box>
            <Button onClick={onOpen}>Edit</Button>
          </Box>
        </Box>
      </Paper>
      <Box mt={2}>
        <RentalsTable rentals={user.rentals} />
      </Box>
      <EditUserDrawer user={user} open={open} onClose={onClose} />
    </Container>
  );
};
