import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Permission } from '../../common/types/permission';
import { Role } from '../../common/types/role';
import { Link } from '../../webui/Link';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import {
  Comparator,
  getComparator,
} from '../../webui/table/sortable-paginated-table/utils';

interface Props {
  allRoles: Role[];
}

export interface RoleData {
  id: string;
  name: string;
  permissions: Permission[];
}

const roleHeadCells: TableHeadCell<RoleData>[] = [
  {
    id: 'name',
    label: 'Name',
    render: (value) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.ROLE, {
          id: value['id'],
        })}
      >
        {value['name']}
      </Link>
    ),
  },
  {
    id: 'permissions',
    label: 'Permission',
    render: (value) =>
      value['permissions'].map((permission) => permission.id).join(', '),
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (roles: Role[]): RoleData[] => {
  return roles.map((role) => {
    return {
      id: role.id,
      name: role.name,
      permissions: role.permissions,
    };
  });
};

const roleComparator: Comparator<RoleData> = (order, orderBy) => {
  return getComparator(order, orderBy);
};

export const AllRolesTable: React.FC<Props> = ({ allRoles }) => {
  return (
    <SortablePaginatedTable
      getComparator={roleComparator}
      title={'Role'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrderByKey={'name'}
      rows={createRows(allRoles)}
      tableHeadCells={roleHeadCells}
    />
  );
};
