import React from 'react';
import { Pie, PieChart, Tooltip } from 'recharts';
import { Rental } from '../../../common/types/rental';
import { generateProductsPieData, ProductsPieData } from './utils';

interface Props {
  rentals: Rental[];
}

export const ProductsPie: React.FC<Props> = ({ rentals }) => {
  return (
    <PieChart width={600} height={600}>
      <Pie
        data={generateProductsPieData(rentals)}
        dataKey="count"
        label={(data: ProductsPieData) =>
          data.count > 1 ? data.product : undefined
        }
        nameKey="product"
        cx="50%"
        cy="50%"
        outerRadius={100}
        fill="#B40A7A"
      />
      <Tooltip />
    </PieChart>
  );
};
