import * as Apollo from '@apollo/client';
import {
  GQL_AllReviewsQuery,
  GQL_AllReviewsQueryVariables,
  useAllPermissionsQuery,
} from '../../../generated/graphql';
import { transformPermission } from '../../../gql/transformPermission';

export const useAllPermissions = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllReviewsQuery,
    GQL_AllReviewsQueryVariables
  >
) => {
  const { data, ...rest } = useAllPermissionsQuery(baseOptions);
  return {
    ...rest,
    data: data?.permission?.allPermissions.map(transformPermission) ?? [],
  };
};
