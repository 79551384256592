import * as Apollo from '@apollo/client';
import { filterUndefined } from '../../../common/utils/filterArrays';
import {
  GQL_AllActivityAreasQuery,
  GQL_AllActivityAreasQueryVariables,
  useAllActivityAreasQuery,
} from '../../../generated/graphql';
import { transformActivityArea } from '../../../gql/transformActivityArea';

export const useAllActivityAreas = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllActivityAreasQuery,
    GQL_AllActivityAreasQueryVariables
  >
) => {
  const { data, ...rest } = useAllActivityAreasQuery(baseOptions);
  return {
    ...rest,
    data: filterUndefined(
      data?.activityArea?.allActivityAreas.map(transformActivityArea) ?? []
    ),
  };
};
