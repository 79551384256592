import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { BoxLink } from '../../common/router_links/BoxLink';
import { Routes } from '../../common/Routes';
import { compareDate, toDisplayDate } from '../../common/utils/dateUtils';
import { exhaustSwitchCase } from '../../common/utils/exhaustSwitchCase';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { resolveProductStatusDisplayName } from '../../common/utils/resolveProductStatusDisplayName';
import { sortWithName } from '../../common/utils/sort/comparators/nameComparator';
import { Box } from '../../webui/Box';
import { Link } from '../../webui/Link';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import { Order } from '../../webui/table/sortable-paginated-table/types';
import {
  Comparator,
  descendingComparator,
  getComparator,
  getNumericalComparator,
} from '../../webui/table/sortable-paginated-table/utils';
import { Typography } from '../../webui/Typography';
import { useAllBoxesLite } from '../box/gql/useAllBoxesLite';
import { ActionsMap } from './ActionsMap';
import {
  CompartmentsThatNeedsActionData,
  createCompartmentsThatNeedsActionData,
} from './utils/createCompartmentsThatNeedsActionData';
import { resolveActionMarkers } from './utils/resolveActionMarkers';

interface Props {}

const getCompartmentComparator = (
  order: Order,
  a: CompartmentsThatNeedsActionData,
  b: CompartmentsThatNeedsActionData
) =>
  getNumericalComparator<CompartmentsThatNeedsActionData['compartment']>(
    order,
    'name'
  )(a.compartment, b.compartment);

export const compartmentsThatNeedsActionComparator: Comparator<CompartmentsThatNeedsActionData> = (
  order,
  orderBy
) => {
  switch (orderBy) {
    case 'box':
      return (a, b) => {
        const number = getNumericalComparator<
          CompartmentsThatNeedsActionData['box']
        >(order, 'name')(a.box, b.box);
        if (number === 0) {
          return getCompartmentComparator('asc', a, b);
        }
        return number;
      };
    case 'compartment':
      return (a, b) => {
        return getCompartmentComparator(order, a, b);
      };
    case 'product':
      return (a, b) =>
        order === 'desc'
          ? descendingComparator(a.product?.name, b.product?.name)
          : -descendingComparator(a.product?.name, b.product?.name);
    case 'productStatus':
      break;
    case 'id':
      break;
    case 'date':
      return (a, b) => compareDate(a.date, b.date, order);
    default:
      return exhaustSwitchCase(orderBy);
  }
  return getComparator(order, orderBy);
};

const createTableHeadCells: TableHeadCell<CompartmentsThatNeedsActionData>[] = [
  {
    id: 'box',
    label: 'Box',
    render: (row) => (
      <BoxLink boxId={row.box.id} linkText={resolveBoxDisplayName(row.box)} />
    ),
    search: (row, id, searchTerm) => {
      return String(resolveBoxDisplayName(row.box))
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    },
  },
  {
    id: 'compartment',
    label: 'Compartment',
    render: (value) => (
      <Link
        component={RouterLink}
        to={generatePath(Routes.COMPARTMENT, {
          boxId: value.box.id,
          compartmentId: value.compartment.id,
        })}
      >
        {value.compartment.name}
      </Link>
    ),
    maxWidth: 50,
  },
  {
    id: 'date',
    label: 'Date',
    render: (value) => (value.date ? toDisplayDate(value.date) : ''),
  },
  {
    id: 'product',
    label: 'Product',
    render: (value) =>
      value.product ? (
        <Link
          component={RouterLink}
          to={generatePath(Routes.PRODUCT, {
            id: value.product.id,
          })}
        >
          {value.product.name}
        </Link>
      ) : (
        '__no_product__'
      ),
  },
  {
    id: 'productStatus',
    label: 'Status',
    render: (value) =>
      value.productStatus
        ? resolveProductStatusDisplayName(value.productStatus)
        : '',
  },
];

const INITIAL_ROWS_PER_PAGE = 40;

export const CompartmentsThatNeedsAction: React.FC<Props> = () => {
  const { data: boxes, loading, error } = useAllBoxesLite();
  const rows = createCompartmentsThatNeedsActionData(
    sortWithName(boxes).filter((box) => !box.deprecated)
  );

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      <Box mb={2}>
        <ActionsMap actions={resolveActionMarkers(rows)} />
      </Box>
      {rows.length > 0 ? (
        <SortablePaginatedTable
          getComparator={compartmentsThatNeedsActionComparator}
          title={'Compartments that needs action'}
          initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
          initialOrderByKey={'box'}
          initialOrder={'asc'}
          rows={rows}
          tableHeadCells={createTableHeadCells}
        />
      ) : (
        <Box mt={1}>
          <Typography>No actions needed!</Typography>
        </Box>
      )}
    </>
  );
};
