import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { format } from '../../common/utils/dateUtils';
import { BatteryPerBoxData } from './utils/generateBatteryPerBoxData';

interface Props {
  readings: BatteryPerBoxData[];
}

export const BoxBatteryChart: React.FC<Props> = ({ readings }) => {
  return (
    <LineChart
      width={1000}
      height={400}
      data={readings}
      margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
    >
      <XAxis
        dataKey={'date'}
        domain={[readings[0].date, readings[readings.length - 1].date]}
        scale="time"
        type="number"
        tickFormatter={(date) => format(new Date(date), 'd-MMM')}
      />
      <YAxis dataKey={'voltage'} />
      <Tooltip
        labelFormatter={(date) => format(new Date(date), 'yyyy-MM-dd HH:mm:ss')}
      />
      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
      <Line
        dot={readings.length < 100}
        name="Voltage"
        type="monotone"
        dataKey="voltage"
        stroke="#B40A7A"
      />
    </LineChart>
  );
};
