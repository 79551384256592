import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Rental } from '../../../common/types/rental';
import { formatDate } from '../../../common/utils/dateUtils';
import {
  getEarliestRentalDate,
  getLatestRentalDate,
} from '../../../common/utils/rentalUtils';
import { generateRentalsPerDayData } from './utils/generateRentalsPerDayData';

interface Props {
  endDate: string | undefined;
  fromDate: string | undefined;
  isPublic?: boolean;
  rentals: Rental[];
  rollingAverage?: number;
}

export const RentalsPerDay: React.FC<Props> = ({
  endDate,
  fromDate,
  isPublic = false,
  rentals,
  rollingAverage = 14,
}) => {
  const data = generateRentalsPerDayData(
    rentals,
    fromDate || getEarliestRentalDate(rentals) || formatDate(new Date()),
    endDate || getLatestRentalDate(rentals) || formatDate(new Date()),
    rollingAverage
  );

  return (
    <LineChart
      width={1000}
      height={400}
      data={data}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <XAxis dataKey={(key) => key.date.slice(5)} />
      <YAxis />
      <Tooltip />
      <CartesianGrid stroke="#f5f5f5" />
      {!isPublic && (
        <Line type="monotone" dataKey="numberOfRentals" stroke="#044A5A" />
      )}
      <Line
        name="Pifflare"
        type="monotone"
        dataKey="numberOfRentalsRollingAverage"
        stroke="#B40A7A"
      />
    </LineChart>
  );
};
