import { useTranslation } from 'react-i18next';
import { Box } from '../../../common/types/box';

export const useTranslatedLocation = () => {
  const { t } = useTranslation();
  return {
    tLocationDescription: (box: Box, lng: string = 'en') =>
      t('location.description.' + box.name, box.location.description ?? '', {
        lng,
      }),
    tLocationName: (box: Box, lng: string = 'en') =>
      t('location.name.' + box.name, box.location.name ?? '', {
        lng,
      }),
  };
};
