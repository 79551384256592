import React, { useState } from 'react';
import { Button } from '../../webui/Button';
import { Dialog } from '../../webui/Dialog';
import { DialogActions } from '../../webui/DialogActions';
import { DialogContent } from '../../webui/DialogContent';
import { DialogTitle } from '../../webui/DialogTitle';
import { TextField, TextFieldProps } from '../../webui/inputs/TextField';
import { Typography } from '../../webui/Typography';

interface Props {
  open: boolean;
  onClose: () => void;
  onChargeRental: (chargeAmount: number | null) => void;
}

export const ChargeAmountDialog: React.FC<Props> = ({
  open,
  onClose,
  onChargeRental,
}) => {
  const [chargeAmount, setChargeAmount] = useState<number | null>(null);

  const onChangeAmount: TextFieldProps['onChange'] = (val) => {
    const amount = Number(val);
    setChargeAmount(val.length > 0 && !isNaN(amount) ? amount : null);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        How much do you want to charge?
      </DialogTitle>
      <DialogContent>
        <Typography>
          Leave empty to charge the price of the rental or enter custom amount
          below
        </Typography>
        <TextField
          onChange={onChangeAmount}
          placeholder={'Enter custom amount...'}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await onChargeRental(chargeAmount);
            onClose();
          }}
          color="primary"
          autoFocus
        >
          Charge
        </Button>
      </DialogActions>
    </Dialog>
  );
};
