import { BatteryReading } from '../../../common/types/batteryReading';
import { createdAtComparator } from '../../../common/utils/sort/comparators/createdAtComparator';

export type LatestBatteryReadingPerBox = Record<string, BatteryReading>;

export const getLatestBatteryReadingPerBox = (
  batteryReadings: BatteryReading[]
): LatestBatteryReadingPerBox => {
  const latestBatteryReadingPerBox: LatestBatteryReadingPerBox = {};
  for (const reading of batteryReadings) {
    if (!latestBatteryReadingPerBox[reading.box.id]) {
      latestBatteryReadingPerBox[reading.box.id] = reading;
    } else {
      if (
        createdAtComparator(
          latestBatteryReadingPerBox[reading.box.id],
          reading
        ) === -1
      ) {
        latestBatteryReadingPerBox[reading.box.id] = reading;
      }
    }
  }

  return latestBatteryReadingPerBox;
};
