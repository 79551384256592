export const resolveDisplayPrice = (
  amount: number,
  currency: string
): number => {
  if (amount === 0) {
    return 0;
  }
  if (currency === 'sek') {
    return amount / 100;
  }
  return amount;
};
