import React from 'react';
import { BoxLink } from '../../common/router_links/BoxLink';
import { OrganisationBox } from '../../common/types/organisation';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';

interface Props {
  boxes: OrganisationBox[];
}

const INITIAL_ROWS_PER_PAGE = 20;

const boxTableHeadCells: TableHeadCell<BoxData>[] = [
  {
    id: 'id',
    label: 'Id',
    maxWidth: 100,
    render: (row) => <BoxLink boxId={row.id} linkText={row.id} />,
  },
  {
    id: 'name',
    label: 'Name',
    render: (value) => value.name,
  },
];

export interface BoxData {
  id: string;
  name: string;
}

const createBoxData = (box: OrganisationBox): BoxData => ({
  id: box.id,
  name: resolveBoxDisplayName(box),
});

export const OrganisationBoxesTable: React.FC<Props> = ({ boxes }) => {
  return (
    <SortablePaginatedTable
      initialOrderByKey={'id'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      rows={boxes.map(createBoxData)}
      tableHeadCells={boxTableHeadCells}
      title={'Boxes in organisation'}
    />
  );
};
