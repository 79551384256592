import { initializeApp } from 'firebase/app';

const firebaseConfig =
  process.env.REACT_APP_ENV === 'production'
    ? {
        apiKey: 'AIzaSyB6HT7UTNCBrDPUqYRouew33iGT7OmsmZg',
        authDomain: 'piffl-5ec7e.firebaseapp.com',
        databaseURL:
          'https://piffl-5ec7e-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'piffl-5ec7e',
        storageBucket: 'piffl-5ec7e.appspot.com',
        messagingSenderId: '1015463403859',
        appId: '1:1015463403859:web:498959bdbfa0c8df8341a6',
        measurementId: 'G-5W04QH8G4F',
      }
    : {
        apiKey: 'AIzaSyBopQyP5UeQxLtBduTbP0qdWFiS7cfKn2U',
        authDomain: 'piffl-88e43.firebaseapp.com',
        projectId: 'piffl-88e43',
        storageBucket: 'piffl-88e43.appspot.com',
        messagingSenderId: '429410606136',
        appId: '1:429410606136:web:d6e20fb6bf6a6f61521acd',
      };

export const initializeFirebase = () => {
  return initializeApp(firebaseConfig);
};
