import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { comparator } from '../../../common/utils/sort/comparators/comparator';
import { sortWith } from '../../../common/utils/sortWith';
import { ActionsMarker } from '../types/actionsMarker';
import { CompartmentsThatNeedsActionData } from './createCompartmentsThatNeedsActionData';

const compartmentNameComparator = <T extends { compartmentName: string }>(
  a: T,
  b: T
): number => comparator(a, b, 'compartmentName');

const sortWithCompartmentName = sortWith(compartmentNameComparator);

export const resolveActionMarkers = (
  compartmentsThatNeedsActionData: CompartmentsThatNeedsActionData[]
): ActionsMarker[] => {
  const boxes: Record<string, ActionsMarker> = {};
  compartmentsThatNeedsActionData.forEach((compartmentThatNeedsActionData) => {
    if (boxes[compartmentThatNeedsActionData.box.id]) {
      boxes[compartmentThatNeedsActionData.box.id].actions.push({
        compartmentId: compartmentThatNeedsActionData.compartment.id,
        date: compartmentThatNeedsActionData.date!,
        productStatus: compartmentThatNeedsActionData.productStatus,
        compartmentName: compartmentThatNeedsActionData.compartment.name ?? '',
        productName: compartmentThatNeedsActionData.product?.name,
      });
    } else {
      boxes[compartmentThatNeedsActionData.box.id] = {
        id: compartmentThatNeedsActionData.id,
        boxId: compartmentThatNeedsActionData.box.id,
        actions: [
          {
            compartmentId: compartmentThatNeedsActionData.compartment.id,
            date: compartmentThatNeedsActionData.date!,
            productStatus: compartmentThatNeedsActionData.productStatus,
            compartmentName:
              compartmentThatNeedsActionData.compartment.name ?? '',
            productName: compartmentThatNeedsActionData.product?.name,
          },
        ],
        boxDisplayName: resolveBoxDisplayName(
          compartmentThatNeedsActionData.box
        ),
        coordinates: compartmentThatNeedsActionData.box.location.coordinates!,
        numberOfCompartments:
          compartmentThatNeedsActionData.box.compartments.length,
      };
    }
  });
  return Object.values(boxes).map((actionsMarker) => {
    return {
      ...actionsMarker,
      actions: sortWithCompartmentName(actionsMarker.actions),
    };
  });
};
