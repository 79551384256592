import React from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { RevenueCalculator } from '../revenue_calculator/RevenueCalculator';
import { CompartmentSelector } from './CompartmentSelector';

interface Props {}

export const UtilsPage: React.FC<Props> = ({}) => {
  return (
    <Container>
      <Box m={2} display={'flex'}>
        <Link component={RouterLink} to={Routes.UTILS_REVENUE_CALCULATOR}>
          Revenue calculator
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.UTILS_COMPARTMENT_SELECTOR}>
          Compartment selector
        </Link>
      </Box>
      <Switch>
        <Route path={Routes.UTILS_REVENUE_CALCULATOR}>
          <RevenueCalculator />
        </Route>
        <Route path={Routes.UTILS_COMPARTMENT_SELECTOR}>
          <CompartmentSelector />
        </Route>
      </Switch>
    </Container>
  );
};
