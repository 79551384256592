import { ActionLevelType } from '../types/ActionLevelType';
import { ActionsMarker } from '../types/actionsMarker';

export const resolveActionLevel = (
  actionsMarker: ActionsMarker
): ActionLevelType => {
  if (actionsMarker.numberOfCompartments <= 3) {
    if (actionsMarker.actions.length === 3) {
      return 'HIGH';
    }
    if (actionsMarker.actions.length === 2) {
      return 'MIDDLE';
    }
    if (actionsMarker.actions.length === 1) {
      return 'LOW';
    }
  }
  if (actionsMarker.numberOfCompartments <= 6) {
    if (actionsMarker.actions.length > 3) {
      return 'HIGH';
    }
    if (actionsMarker.actions.length > 1) {
      return 'MIDDLE';
    }
    if (actionsMarker.actions.length > 0) {
      return 'LOW';
    }
  }
  if (actionsMarker.numberOfCompartments <= 14) {
    if (actionsMarker.actions.length > 5) {
      return 'HIGH';
    }
    if (actionsMarker.actions.length > 3) {
      return 'MIDDLE';
    }
    if (actionsMarker.actions.length > 0) {
      return 'LOW';
    }
  }

  return 'NONE';
};
