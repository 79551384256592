import * as Apollo from '@apollo/client';
import {
  GQL_UserByIdQuery,
  GQL_UserByIdQueryVariables,
  useUserByIdQuery,
} from '../../../generated/graphql';
import { transformUser } from '../../../gql/transformUser';

export const useUserById = (
  baseOptions: Apollo.QueryHookOptions<
    GQL_UserByIdQuery,
    GQL_UserByIdQueryVariables
  > &
    (
      | { variables: GQL_UserByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) => {
  const { data, ...rest } = useUserByIdQuery(baseOptions);
  return {
    ...rest,
    data: data?.user?.userById
      ? transformUser(data?.user?.userById)
      : undefined,
  };
};
