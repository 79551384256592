import * as Apollo from '@apollo/client';
import {
  GQL_AllBatteryReadingsQuery,
  GQL_AllBatteryReadingsQueryVariables,
  useAllBatteryReadingsQuery,
} from '../../../generated/graphql';
import { transformBatteryReading } from '../../../gql/transformBatteryReading';

export const useAllBatteryReadings = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBatteryReadingsQuery,
    GQL_AllBatteryReadingsQueryVariables
  >
) => {
  const { data, ...rest } = useAllBatteryReadingsQuery(baseOptions);
  return {
    ...rest,
    data: data?.battery?.allBatteryReadings.map(transformBatteryReading) ?? [],
  };
};
