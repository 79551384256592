import { BoxWithoutRentals } from '../../../../common/types/box';
import { Rental } from '../../../../common/types/rental';
import { getSharesInPercentage } from '../../utils/getSharesInPercentage';

export interface ProductsInRentalsStats {
  id: string;
  productName: string;
  boxesCount: number;
  rentalsCount: number;
  rentalsPerBox: number;
  rentalShare: number;
  meanValueRatings: number;
  ratingsSum: number;
  validRatingsCount: number;
  meanValueRentTime: number;
  rentTimeSum: number;
  rentTimeCount: number;
}

export const getProductsInRentalsStats = (
  boxes: BoxWithoutRentals[],
  rentals: Rental[]
): ProductsInRentalsStats[] => {
  const productStatsList: ProductsInRentalsStats[] = [];

  // Helper function to find or create a product stats object
  const findOrCreateProductStats = (
    productId: string,
    productName: string
  ): ProductsInRentalsStats => {
    let stats = productStatsList.find((item) => item.id === productId);
    if (!stats) {
      stats = {
        id: productId,
        productName,
        boxesCount: 0,
        rentalsCount: 0,
        rentalsPerBox: 0,
        rentalShare: 0,
        meanValueRatings: 0,
        ratingsSum: 0,
        validRatingsCount: 0,
        meanValueRentTime: 0,
        rentTimeSum: 0,
        rentTimeCount: 0,
      };
      productStatsList.push(stats);
    }
    return stats;
  };

  // Count how many boxes each product is in (only count once per box)
  boxes.forEach((box) => {
    const seenProductsInBox: Set<string> = new Set(); // Track products already counted in this box

    box.compartments.forEach((compartment) => {
      const product = compartment.product;
      if (!product || seenProductsInBox.has(product.id)) return; // Skip if already counted in this box

      seenProductsInBox.add(product.id);

      const stats = findOrCreateProductStats(product.id, product.name);
      stats.boxesCount++;
    });
  });

  // Count how many rentals each product is in and the rentals per box
  rentals.forEach((rental) => {
    const product = rental.product;

    const stats = findOrCreateProductStats(product.id, product.name);
    stats.rentalsCount++;

    // If the rental has a review with a rating, include it in the mean rating calculation
    if (rental.review?.rating !== undefined) {
      stats.ratingsSum += rental.review.rating;
      stats.validRatingsCount++;
    }
    if (rental.rentTime) {
      stats.rentTimeSum += rental.rentTime;
      stats.rentTimeCount++;
    }
  });

  // Calculate the total rentals to compute the share
  const totalRentals = rentals.length;

  // Update each product stats, calculate the proportion of rentals per box and mean ratings
  productStatsList.forEach((stats) => {
    stats.rentalShare = getSharesInPercentage(stats.rentalsCount, totalRentals);
    stats.rentalsPerBox = stats.rentalsCount / stats.boxesCount;

    // Calculate mean rating
    if (stats.validRatingsCount) {
      stats.meanValueRatings = stats.ratingsSum / stats.validRatingsCount;
    }
    // Calculate mean rent time
    if (stats.rentTimeCount) {
      stats.meanValueRentTime = stats.rentTimeSum / stats.rentTimeCount;
    }
  });

  return productStatsList;
};
