import React from 'react';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { Routes } from '../../common/Routes';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { PublicStatisticsPage } from './PublicStatisticsPage';
import { StatisticsProductsPage } from './StatisticsProductsPage';
import { StatisticsRentalsPage } from './StatisticsRentalsPage';
import { StatisticsUsersPage } from './StatisticsUsersPage';

interface Props {}

export const StatisticsPage: React.FC<Props> = () => {
  return (
    <Container>
      <Box m={2} display={'flex'}>
        <Link component={RouterLink} to={Routes.STATISTICS_RENTALS}>
          Rentals
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.STATISTICS_PRODUCTS}>
          Products
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.STATISTICS_USERS}>
          Users
        </Link>
        <Box ml={2} />
        <Link component={RouterLink} to={Routes.STATISTICS_PUBLIC}>
          For customers
        </Link>
      </Box>
      <Switch>
        <Route path={Routes.STATISTICS_RENTALS}>
          <StatisticsRentalsPage />
        </Route>
        <Route path={Routes.STATISTICS_PRODUCTS}>
          <StatisticsProductsPage />
        </Route>
        <Route path={Routes.STATISTICS_USERS}>
          <StatisticsUsersPage />
        </Route>
        <Route path={Routes.STATISTICS_PUBLIC}>
          <PublicStatisticsPage />
        </Route>
      </Switch>
    </Container>
  );
};
