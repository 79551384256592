import { Rental } from '../../../../common/types/rental';

interface NumberOfRentalsPerBoxMap {
  [name: string]: number;
}

export const generateNumberOfRentalsPerBoxMap = (
  rentals: Rental[]
): NumberOfRentalsPerBoxMap =>
  rentals.reduce<NumberOfRentalsPerBoxMap>((acc, rental) => {
    const box = rental.box.id;
    if (acc[box]) {
      acc[box] = acc[box] + 1;
    } else {
      acc[box] = 1;
    }
    return acc;
  }, {});
