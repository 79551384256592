import * as Apollo from '@apollo/client';
import {
  GQL_AllBoxesLiteQuery,
  GQL_AllBoxesLiteQueryVariables,
  useAllBoxesLiteQuery,
} from '../../../generated/graphql';
import { transformBoxLite } from '../../../gql/transformBox';

export const useAllBoxesLite = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllBoxesLiteQuery,
    GQL_AllBoxesLiteQueryVariables
  >
) => {
  const { data, ...rest } = useAllBoxesLiteQuery(baseOptions);
  return {
    ...rest,
    data: data?.box?.allBoxes.map(transformBoxLite) ?? [],
  };
};
