import { useAppSelector } from '../../../store';
import { AuthUser } from '../AuthUser';

interface UseAuthentication {
  userLoggedIn: boolean;
  currentUser: AuthUser | null;
}

export const useAuthentication = (): UseAuthentication => {
  const user = useAppSelector((store) => store.authentication.currentUser);

  return {
    userLoggedIn: Boolean(user),
    currentUser: user || null,
  };
};
