import * as Apollo from '@apollo/client';
import {
  GQL_AllActivitiesQuery,
  GQL_AllActivitiesQueryVariables,
  useAllActivitiesQuery,
} from '../../../generated/graphql';
import { filterUndefined } from '../../../common/utils/filterArrays';
import { transformActivity } from '../../../gql/transformActivity';

export const useActivities = (
  baseOptions?: Apollo.QueryHookOptions<
    GQL_AllActivitiesQuery,
    GQL_AllActivitiesQueryVariables
  >
) => {
  const { data, ...rest } = useAllActivitiesQuery(baseOptions);
  return {
    ...rest,
    data: filterUndefined(
      data?.activityArea?.allActivities?.map(transformActivity) ?? []
    ),
  };
};
