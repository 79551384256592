import { sortWith } from '../../../common/utils/sortWith';
import { GroupedPermission } from './getCalculatedPermissions';

const nameComparator = (a: GroupedPermission, b: GroupedPermission): number => {
  if (a.permission.type === b.permission.type) {
    return 0;
  }
  return a.permission.type > b.permission.type ? 1 : -1;
};

export const sortGroupedPermissions = (
  groupedPermissions: GroupedPermission[]
): GroupedPermission[] => {
  return sortWith(nameComparator)(groupedPermissions);
};
