import { ProductStatus } from '../types/productStatus';
import { exhaustSwitchCase } from './exhaustSwitchCase';

export const resolveProductStatusDisplayName = (
  productStatus: ProductStatus
): string => {
  switch (productStatus) {
    case 'Ok':
      return 'ok';
    case 'MissingParts':
      return 'missing parts';
    case 'Dirty':
      return 'dirty';
    case 'Broken':
      return 'broken';
    case 'Lost':
      return 'lost';
    case 'Other':
      return 'other';
    default:
      return exhaustSwitchCase(productStatus);
  }
};
