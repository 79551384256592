import React, { useMemo } from 'react';
import { useForm } from '../../../common/hooks/useForm';
import { useNotification } from '../../../common/notifications/useNotification';
import { Box as BoxType, BoxColor } from '../../../common/types/box';
import { Location } from '../../../common/types/location';
import { toDisplayTime, transformTime } from '../../../common/utils/dateUtils';
import { resolveCoordinates } from '../../../common/utils/resolveCoordinates';
import {
  AllBoxesDocument,
  GQL_EditBoxResponseType,
  Maybe,
  useEditBoxMutation,
} from '../../../generated/graphql';
import { transformBoxColorToGQL } from '../../../gql/transformBox';
import { Box } from '../../../webui/Box';
import { Button } from '../../../webui/Button';
import { SaveButton } from '../../../webui/buttons/SaveButton';
import { Drawer } from '../../../webui/Drawer';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../../webui/inputs/CheckboxWithLabel';
import { SelectInputProps } from '../../../webui/inputs/Select';
import { TextField, TextFieldProps } from '../../../webui/inputs/TextField';
import { Typography } from '../../../webui/Typography';
import {
  EditLocation,
  EditLocationForm,
} from '../../location/edit/EditLocation';
import { PriceWeightSelect } from '../../price/PriceWeightSelect';
import { SponsorSelect } from '../../sponsor/SponsorSelect';
import { BoxColorSelect } from '../BoxColorSelect';
import { DeleteBoxButton } from '../delete/DeleteBoxButton';

interface EditBoxDrawerProps {
  box: BoxType;
  open: boolean;
  onClose: () => void;
}

interface EditBoxForm {
  active: boolean;
  circuitBoardId: string;
  color: BoxColor;
  deprecated: boolean;
  name: string;
  openStartTime: string;
  openEndTime: string;
  priceWeight: string;
  sponsorId: string;
}

const createInitialEditBoxForm = (box: BoxType): EditBoxForm => ({
  active: box.active,
  circuitBoardId: box.circuitBoardId,
  color: box.color,
  deprecated: box.deprecated,
  name: box.name,
  openStartTime: toDisplayTime(box.openStartTime) ?? '',
  openEndTime: toDisplayTime(box.openEndTime) ?? '',
  priceWeight: box.priceWeight.id,
  sponsorId: box.sponsor?.id ?? '',
});

const createInitialEditLocationForm = (
  location: Location | undefined
): EditLocationForm => {
  if (location) {
    return {
      name: location.name,
      description: location.description,
      lat: location.coordinates?.lat
        ? String(location.coordinates.lat)
        : undefined,
      long: location.coordinates?.long
        ? String(location.coordinates.long)
        : undefined,
    };
  }
  return {};
};

const resolveErrorMessage = (
  type: Maybe<GQL_EditBoxResponseType> | undefined
): string => {
  switch (type) {
    case GQL_EditBoxResponseType.NoPermissionToEditBox:
      return 'No permission to edit box';
    case GQL_EditBoxResponseType.BoxDoesNotExist:
      return 'Box does not exist';
  }
  return 'Could not edit box';
};

// TODO: Form handling, validation, disable save etc
export const EditBoxDrawer: React.FC<EditBoxDrawerProps> = ({
  box,
  open,
  onClose,
}) => {
  const { form, updateField, reset } = useForm(
    useMemo(() => createInitialEditBoxForm(box), [box])
  );
  const locationForm = useForm(
    useMemo(() => createInitialEditLocationForm(box.location), [box])
  );

  const { showNotification } = useNotification();

  const [editBox, { loading }] = useEditBoxMutation();

  const onNameChange: TextFieldProps['onChange'] = (value) => {
    updateField('name', value);
  };

  const onActiveChange: CheckboxWithLabelProps['onChange'] = (value) => {
    updateField('active', value);
  };

  const onDeprecatedChange: CheckboxWithLabelProps['onChange'] = (value) => {
    updateField('deprecated', value);
  };

  const onOpenStartTimeChange: TextFieldProps['onChange'] = (value) => {
    updateField('openStartTime', value);
  };

  const onOpenEndTimeChange: TextFieldProps['onChange'] = (value) => {
    updateField('openEndTime', value);
  };

  const onCircuitBoardIdChange: TextFieldProps['onChange'] = (value) => {
    updateField('circuitBoardId', value);
  };

  const onColorChange = (value: BoxColor) => {
    updateField('color', value);
  };

  const onSponsorChange = (value: string) => {
    updateField('sponsorId', value);
  };

  const onPriceWeightChange: SelectInputProps<string>['onChange'] = (e) => {
    updateField('priceWeight', e.target.value as string);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  const onSave = async () => {
    try {
      const result = await editBox({
        variables: {
          boxId: box.id,
          boxInput: {
            location: {
              description: locationForm.form.description,
              name: locationForm.form.name,
              coordinates: resolveCoordinates(
                locationForm.form.lat,
                locationForm.form.long
              ),
            },
            active: form.active,
            circuitBoardId: form.circuitBoardId,
            color: transformBoxColorToGQL(form.color),
            deprecated: form.deprecated,
            name: form.name,
            openStartTime: transformTime(form.openStartTime),
            openEndTime: transformTime(form.openEndTime),
            priceWeight: form.priceWeight || undefined,
            sponsorId: form.sponsorId,
          },
        },
        refetchQueries: [{ query: AllBoxesDocument }],
      });
      if (result?.data?.editBox?.type === 'Success') {
        showNotification({
          message: 'Box edited.',
          severity: 'success',
        });
        onClose();
      } else {
        showNotification({
          message: resolveErrorMessage(result?.data?.editBox?.type),
          severity: 'error',
        });
      }
    } catch (e) {
      showNotification({
        message: 'Could not edit box.',
        severity: 'error',
      });
      console.log('Box edit error', e, '--- e');
    }
  };

  return (
    <Drawer anchor={'right'} onClose={onClose} open={open}>
      <Box m={2}>
        <Typography variant="h4">Edit box "{box.name}"</Typography>
        <Box mt={2}>
          <form noValidate autoComplete="off">
            <Box mt={2}>
              <CheckboxWithLabel
                label={'Active'}
                labelPlacement={'start'}
                onChange={onActiveChange}
                value={form.active}
              />
            </Box>
            <Box mt={2}>
              <CheckboxWithLabel
                label={'Deprecated'}
                labelPlacement={'start'}
                onChange={onDeprecatedChange}
                value={form.deprecated}
              />
            </Box>
            <Box display={'flex'} flexWrap={'wrap'}>
              <Box mr={1} mt={1}>
                <TextField
                  id="edit-box-name"
                  label="Name"
                  value={form.name}
                  onChange={onNameChange}
                />
              </Box>
              <Box mr={1} mt={1}>
                <TextField
                  id="edit-box-circuit-board-id"
                  label="Circuit Board Id"
                  value={form.circuitBoardId}
                  onChange={onCircuitBoardIdChange}
                />
              </Box>
              <Box mr={1} mt={1}>
                <BoxColorSelect
                  id={'edit-box-color'}
                  value={form.color}
                  onChange={onColorChange}
                />
              </Box>
              <Box mr={1} mt={1}>
                <TextField
                  id="edit-box-open-start-time"
                  label="Open start time"
                  value={form.openStartTime}
                  onChange={onOpenStartTimeChange}
                />
              </Box>
              <Box mr={1} mt={1}>
                <TextField
                  id="edit-box-open-end-time"
                  label="Open end time"
                  value={form.openEndTime}
                  onChange={onOpenEndTimeChange}
                />
              </Box>
            </Box>
            <Box display={'flex'} flexWrap={'wrap'} mt={2}>
              <Box mr={1}>
                <SponsorSelect
                  id={'edit-box-sponsor'}
                  value={form.sponsorId}
                  onChange={onSponsorChange}
                />
              </Box>
              <Box mr={1}>
                <PriceWeightSelect
                  id={'edit-box-priceWeight'}
                  value={form.priceWeight}
                  onChange={onPriceWeightChange}
                />
              </Box>
            </Box>
            <Box mt={2}>
              <EditLocation
                form={locationForm.form}
                updateField={locationForm.updateField}
              />
            </Box>
          </form>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'}>
          <Box mt={4}>
            <DeleteBoxButton id={box.id} />
          </Box>

          <Box display={'flex'} justifyContent={'flex-end'} mt={4}>
            <Button onClick={onCancel}>Cancel</Button>
            <Box ml={2} />
            <SaveButton loading={loading} onClick={onSave} />
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
