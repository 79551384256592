import * as React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '../../webui/Link';
import { Routes } from '../Routes';

interface Props {
  boxId: string;
  linkText: string;
}

export const BoxLink: React.FC<Props> = ({ boxId, linkText }) => {
  return (
    <Link
      component={RouterLink}
      to={generatePath(Routes.BOX, {
        id: boxId,
      })}
    >
      {linkText}
    </Link>
  );
};
