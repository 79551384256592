import { Notification, showNotificationAction } from './notificationsSlice';
import { useAppDispatch } from '../../store';

export const useNotification = () => {
  const dispatch = useAppDispatch();

  const showNotification = (notification: Notification) => {
    dispatch(showNotificationAction(notification));
  };

  return {
    showNotification,
  };
};
