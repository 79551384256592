import { UserLite } from '../../../common/types/user';

export type UserKey = keyof Exclude<UserLite, 'paymentInformation' | 'rental'>;

export const allKeys: UserKey[] = [
  'accountCreated',
  'birthYear',
  'email',
  'externalId',
  'firstName',
  'gender',
  'id',
  'lastName',
  'phoneNumber',
  'priceWeight',
  'provider',
  'zipCode',
];

export const generateUsersCsv = (
  users: UserLite[],
  requestedKeys: UserKey[] = allKeys
) => {
  let csvString = requestedKeys.join(',');

  users.forEach((user) => {
    csvString += `\n${requestedKeys
      .map((key) => {
        if (key === 'priceWeight') {
          return user[key].name;
        }
        return user[key];
      })
      .join(',')}`;
  });

  return csvString;
};
