import React from 'react';
import { User } from '../../common/types/user';
import { Typography } from '../../webui/Typography';
import { getCalculatedPermissions } from './util/getCalculatedPermissions';
import { sortGroupedPermissions } from './util/sortGroupedPermissions';

interface Props {
  user: User;
}

export const CalculatedPermissionsList: React.FC<Props> = ({ user }) => {
  const groupedPermissions = getCalculatedPermissions(user);

  if (groupedPermissions.length === 0) {
    return <Typography>This user has no permissions</Typography>;
  }

  const sortedPermissions = sortGroupedPermissions(groupedPermissions);

  return (
    <>
      {sortedPermissions.map((gp) => {
        if (gp.roles.length === 0) {
          return (
            <Typography
              key={gp.permission.id}
            >{`${gp.permission.type}`}</Typography>
          );
        }

        const roleNames = gp.roles.map((r) => r.name).join(', ');
        return (
          <Typography
            key={gp.permission.id}
          >{`${gp.permission.type} (${roleNames})`}</Typography>
        );
      })}
    </>
  );
};
