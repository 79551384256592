import 'leaflet.heat';
import L from 'leaflet';
import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface Props {
  heatmapData?: [number, number][];
}

export const HeatmapLayer: React.FC<Props> = ({ heatmapData }) => {
  const map = useMap();

  useEffect(() => {
    // @ts-ignore
    const heat = L.heatLayer(heatmapData, {
      radius: 25,
    }).addTo(map);
    return () => {
      map.removeLayer(heat);
    };
  }, [heatmapData, map]);

  return null;
};
