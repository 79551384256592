export enum Routes {
  HOME = '/',
  ACTIONS = '/actions',
  ACTIVITY_AREA = '/activity_areas/activity_area/:id',
  ALL_ACTIVITY_AREAS = '/activity_areas',
  ALL_BATTERY_VOLTAGES = '/battery',
  ALL_BOXES = '/boxes',
  ALL_PENDING_USERS = '/pending_users',
  ALL_PRODUCTS = '/products',
  ALL_RENTALS = '/rentals',
  ALL_ROLES = '/roles',
  ALL_SPONSORS = '/sponsors',
  ALL_USERS = '/users',
  ALL_FEEDBACKS = '/feedbacks',
  ALL_ORGANISATIONS = '/organisations',
  ALL_PERMISSIONS = '/permissions',
  ALL_PRICE_WEIGHTS = '/price_weights',
  ADD = '/add',
  ADD_ACTIVITY_AREA = '/add/activity_area',
  ADD_BOX = '/add/box',
  ADD_ORGANISATION = '/add/organisation',
  ADD_PENDING_USER = '/add/pendingUser',
  ADD_PRICE_WEIGHT = '/add/price_weight',
  ADD_PRODUCT = '/add/product',
  ADD_ROLE = '/add/role',
  ADD_SPONSOR = '/add/sponsor',
  BOX = '/box/:id',
  COMPARTMENT = '/box/:boxId/compartment/:compartmentId',
  ORGANISATION = '/organisation/:id',
  PENDING_USER = '/pending-user/:id',
  PRICE = '/price',
  PRICE_WEIGHT = '/priceWeight/:id',
  PRODUCT = '/product/:id',
  RENTAL = '/rental/:id',
  ROLE = '/role/:id',
  SPONSOR = '/sponsor/:id',
  STATISTICS = '/statistics/',
  STATISTICS_PUBLIC = '/statistics/overview',
  STATISTICS_PRODUCTS = '/statistics/products',
  STATISTICS_RENTALS = '/statistics/rentals',
  STATISTICS_USERS = '/statistics/users',
  TRANSLATIONS = '/translations',
  USER = '/user/:id',
  UTILS = '/utils',
  UTILS_COMPARTMENT_SELECTOR = '/utils/compartment-selector',
  UTILS_REVENUE_CALCULATOR = '/utils/revenue-calculator',
}
