import { ActivityArea, AreaInfo } from '../common/types/activityArea';
import { GQL_ActivityAreaFragment, GQL_AreaInfo } from '../generated/graphql';
import { transformLocation } from './transformLocation';

const transformInfoArea = (areaInfo: GQL_AreaInfo): AreaInfo => {
  return {
    id: areaInfo.id,
    count: areaInfo.count,
    type: areaInfo.type,
  };
};

export const transformActivityArea = (
  activityArea: GQL_ActivityAreaFragment
): ActivityArea | undefined => {
  return {
    active: activityArea.active,
    activities: activityArea.activities,
    areaInfos: activityArea.areaInfos.map(transformInfoArea),
    id: activityArea.id,
    location: activityArea.location
      ? transformLocation(activityArea.location)
      : undefined,
    type: activityArea.type,
    verified: activityArea.verified ?? false,
  };
};
