import { Permission } from '../common/types/permission';
import { GQL_PermissionFragment } from '../generated/graphql';

export const transformPermission = (
  permission: GQL_PermissionFragment
): Permission => ({
  id: permission.id,
  description: permission.description ?? undefined,
  type: permission.type,
});
