import { ProductRentalType } from '../types/product';
import { exhaustSwitchCaseWithFallback } from './exhaustSwitchCase';

export const resolveRentalTypeDisplayName = (
  rentalType: ProductRentalType
): 'Rent' | 'Buy' => {
  switch (rentalType) {
    case 'rent':
      return 'Rent';
    case 'buy':
      return 'Buy';
    default:
      return exhaustSwitchCaseWithFallback(rentalType, 'Rent');
  }
};
